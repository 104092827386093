import CheckinchEditGrid from "./CheckinchEditGrid";

import styles from "./CheckinchEdit.module.scss";

const CheckinchEdit = () => {

    return (
        <div className={styles.data_grid}>
            <CheckinchEditGrid/>
        </div>
    );
}

export default CheckinchEdit;
