import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';

import { airlineManagerService, handlingManagerService } from '../../api/apiManager';
import Loader from '../Loader/Loader';
import { dataGridService } from "../../services/dataGridService";
import { schemaSubject } from "../../common/schemaValidation";

import styles from './SupportPage.module.scss'

const SupportPage = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false);

    const role = useSelector((state: any) => state.user.user.role);

    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaSubject),
    });

    const sendSupport = async (data: any) => {
        let request;

        dataGridService.removeEmptyFields(data)
        role === 2
            ? request = handlingManagerService.sendSupport(data)
            : request = airlineManagerService.sendSupport(data)

        setLoading(true)
        await request.then((res: any) => {
            if (res.status === 200) {
                toast.success("Request was send successfully");
                navigate("/")
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })

    }

    if (loading) return <Loader/>

    return (
        <div className={styles.app}>
            <form className={styles.form_box} onSubmit={handleSubmit(sendSupport)}>
                <h2>Contact us</h2>
                <p>Questions or comments about the application? Feel free to send our support team a message via the
                    form <br/> and we'll be happy to help you.</p>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {marginBottom: '20px', width: '100%'},
                    }}
                >

                    <Controller
                        render={({field}) =>
                            <div style={{width: "100%", padding: "10px 0px"}} className={styles.wrap_witherror}>
                                <TextField fullWidth label="Subject *" {...field} className="materialUIInput"/>
                                <small className={styles.error}>{errors.subject?.message}</small>
                            </div>}
                        name="subject"
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        render={({field}) =>
                            <div style={{width: "100%", padding: "0px 0px 10px 0px"}} className={styles.wrap_witherror}>
                                <TextField
                                    label="Message *"
                                    {...field}
                                    multiline
                                    rows={20}
                                    placeholder="Message.."
                                    fullWidth
                                />
                                <small className={styles.error}>{errors.message?.message}</small>
                            </div>}
                        name="message"
                        control={control}
                        defaultValue=""
                    />

                </Box>
                <div className={styles.button}>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                    >
                        Send
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default SupportPage;
