import {createSlice} from "@reduxjs/toolkit"

export const airlineManagerSlice = createSlice({
    name: "airlineManagerPage",
    initialState: {

        airlineCompanyManager: {},
        company: {},
        flight: {},
        filter: {
            fromDate: "",
            toDate: "",
            airport: "",
            fromDateDisabled: false,
            toDateDisabled: false,
            alignment: ""
        },
    },
    reducers: {
        setAirlineCompanyManager: (state, action) => {
            state.airlineCompanyManager = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setFlight: (state, action) => {
            state.flight = action.payload;
        },
        setFromDate: (state, action) => {
            state.filter.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.filter.toDate = action.payload
        },
        setAirportName: (state, action) => {
            state.filter.airport = action.payload
        },
        setFromDateDisabled: (state, action) => {
            state.filter.fromDateDisabled = action.payload
        },
        setToDateDisabled: (state, action) => {
            state.filter.toDateDisabled = action.payload
        },
        setAlignment: (state, action) => {
            state.filter.alignment = action.payload
        },
        clearFilters: (state) => {
            state.filter = {}
        },
    }
})

export const {
    setCompany,
    setFlight,
    setAirlineCompanyManager,
    setAlignment,
    setToDateDisabled,
    setFromDateDisabled,
    setAirportName,
    setToDate,
    setFromDate,
    clearFilters
} = airlineManagerSlice.actions;


export default airlineManagerSlice.reducer;
