import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { addDays, endOfMonth, endOfYear, lastDayOfWeek, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import { setAlignment } from "../../../../redux/features/handlingManagerSlice";
import { IDashboardFilter } from "../../../../models/dashboard-filter.model";
import { useDispatch, useSelector } from "react-redux";
import { enAU } from "date-fns/locale";

export default function ColorToggleButton({setFromDate, setToDate}: IDashboardFilter) {
    let alignment = useSelector((state: any) => state.handlingManagerPage.filter.alignment)

    const dispatch = useDispatch()

    const buttonStyle = {
        fontSize: "12px"
    }


    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            console.log(newAlignment)
            dispatch(setAlignment(newAlignment));
        }
    };

    const handleClick = (today: string) => {
        let date = new Date()
        switch (today) {
            case "today":
                dispatch(setFromDate(date.toLocaleDateString('fr-CA')))
                dispatch(setToDate(addDays(date, 1).toLocaleDateString('fr-CA')))
                break;
            case "week":
                const weekMonday = startOfWeek(date, {locale: enAU})
                const weekSunday = lastDayOfWeek(date, {locale: enAU})
                dispatch(setFromDate(weekMonday.toLocaleDateString('fr-CA')))
                dispatch(setToDate(addDays(weekSunday, 1).toLocaleDateString('fr-CA')))
                break;
            case "month":
                const monthStart = startOfMonth(date)
                const monthEnd = endOfMonth(date)
                dispatch(setFromDate(monthStart.toLocaleDateString('fr-CA')))
                dispatch(setToDate(addDays(monthEnd, 1).toLocaleDateString('fr-CA')))
                break;
            case "year":
                const yearStart = startOfYear(date)
                const yearEnd = endOfYear(date)
                dispatch(setFromDate(yearStart.toLocaleDateString('fr-CA')))
                dispatch(setToDate(addDays(yearEnd, 1).toLocaleDateString('fr-CA')))
                break;
        }

    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            size="small"
        >
            <ToggleButton onClick={() => handleClick("today")} style={buttonStyle} value="Today">Today</ToggleButton>
            <ToggleButton onClick={() => {
                handleClick("week")
            }} style={buttonStyle} value="This Week">This Week</ToggleButton>
            <ToggleButton onClick={() => {
                handleClick("month")
            }} style={buttonStyle} value="This month">This month</ToggleButton>
            <ToggleButton
                onClick={() => {
                    handleClick("year")
                }} style={buttonStyle} value="This Year">This Year</ToggleButton>
        </ToggleButtonGroup>
    );
}
