import AirlineInfoGrid from "../../components/AirlineInfoGrid/AirlineInfoGrid";

import styles from "./AirlineCompany.module.scss";

const AirlineCompanySingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <AirlineInfoGrid/>
            </div>
        </div>
    );
}

export default AirlineCompanySingleView;
