import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import Loader from '../../../components/Loader/Loader';
import { handlingManagerService } from "../../../api/apiManager";
import { schemaManagerEdit } from '../../../common/schemaValidation';
import { dataGridService } from "../../../services/dataGridService";
import PromptCancel from '../../../components/PromptCancel/PromptCancel';
import { setView } from '../../../redux/features/isDeleteSlice';

import TEXT from '../../../assets/text.json'
import styles from './HandlingEditSelfGrid.module.scss'

const HandlingEditSelfGrid = () => {
    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaManagerEdit),
    });
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const handling = useSelector((store: any) => store?.handlingManagerPage?.handlingManager)
    const [loading, setLoading] = useState<boolean>(false);
    const [airlineCompanies, setAirlineCompanies] = useState<any[]>([]);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const openPrompt = () => {
        dispatch(setView(true))
    }


    const getCompaniesId = () => {
        let companiesId: any[] = []
        handling.companies?.forEach((company: any) => {
            companiesId.push(company.id)
        })
        return companiesId
    }

    const onSubmit = async (data: any) => {

        const parsedData = {...data, ['id']: handling.id}
        dataGridService.removeEmptyFields(parsedData)
        
        setLoading(true)
        await handlingManagerService.updateHandlingSelf(parsedData).then((res) => {
            if (res.status === 200) {
                toast.success(TEXT.NOTIFY_ACCOUNT_EDIT);
                navigate(-1)
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })

    };

    useEffect(() => {

        const getAirlineCompanies = async () => {
            setLoading(true)
            await handlingManagerService.getLinkedCompanies()
                .then((res) => {
                    setAirlineCompanies(res?.data);
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        getAirlineCompanies()

        return () => {
            setAirlineCompanies([]);
        };

    }, []);

    if (!Object.keys(handling).length) {
        navigate("/handling-manager")
    }

    if (loading) return <Loader/>

    return (
        <>
            <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                <h3>Edit Handling</h3>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Main info</Divider>
                        <Controller
                            render={({field}) => <TextField
                                label="Worker ID" {...field}
                                className="materialUIInput"/>}
                            name="workerId"
                            control={control}
                            defaultValue={handling?.workerId || ""}
                        />
                        <Controller
                            render={({field}) => <TextField label="First name *" {...field}
                                                            className="materialUIInput"/>}
                            name="firstname"
                            control={control}
                            defaultValue={handling?.firstname}
                        />
                        <Controller
                            render={({field}) => <TextField label="Last name *" {...field}
                                                            className="materialUIInput"/>}
                            name="lastname"
                            control={control}
                            defaultValue={handling?.lastname}
                        />
                        <Controller
                            render={({field}) => <TextField rows={4}
                                                            multiline label="Comments" {...field}
                                                            className="materialUIInput"/>}
                            name="comments"
                            control={control}
                            defaultValue={handling?.comments}
                        />

                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Companies info</Divider>
                        <Controller
                            defaultValue={getCompaniesId()}
                            render={({field}) => (
                                <FormControl sx={{m: 1, width: 300}}>
                                    <InputLabel id="selectLabel">Airline companies *</InputLabel>
                                    <Select
                                        labelId="selectLabel"
                                        multiple
                                        required
                                        label="Airline companies *"
                                        defaultValue={getCompaniesId()}
                                        {...field}
                                    >
                                        {airlineCompanies.map((company) => (
                                            <MenuItem key={company.id} value={company.id}>
                                                {company.companyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            name="companies"
                            control={control}
                        />
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Contact details</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField
                                        onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                                        label="Phone number *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.phoneNumber?.message}</small>
                                </div>
                                }
                                name="phoneNumber"
                                control={control}
                                defaultValue={handling?.phoneNumber}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField label="Email *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.username?.message}</small>
                                </div>
                                }
                                name="username"
                                control={control}
                                defaultValue={handling?.username}
                            />
                            <Controller
                                render={({field}) => <TextField label="Manager role" {...field}
                                                                className="materialUIInput"/>}
                                name="managerRoleName"
                                control={control}
                                defaultValue={handling?.managerRoleName}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Password *"
                                    placeholder="******" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.password?.message}</small></div>}
                                name="password"
                                control={control}
                                defaultValue={undefined}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Confirm password *" placeholder="******" {...field}
                                    className="materialUIInput"/><small
                                    className={styles.error}>{errors.passwordConfirmation?.message}</small></div>}
                                name="passwordConfirmation"
                                control={control}
                                defaultValue={undefined}
                            />
                        </div>
                    </div>
                </Box>
                <Button
                    className={styles.button}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit">
                    Submit
                </Button>

                <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => openPrompt()}>
                    Cancel
                </Button>
            </form>
            {isView ? <PromptCancel/> : ''}
        </>
    );
}

export default HandlingEditSelfGrid;
