import List from '@mui/material/List';

import NestedItem from "./NestedItem";
import NestedCollapseItem from "./NestedCollapseItem";

import TEXT from '../../assets/text.json'
import styles from './NestedList.module.scss'

function CheckinchList() {

    return (
        <List
            sx={{width: '100%', maxWidth: 340, flex: 8}}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <div className={styles.list}>
                <div className={styles.item}>
                    <NestedItem text={TEXT.MENU_DASHBOARD} link="/dashboard"/>
                </div>
                <div className={styles.item}>
                    <NestedCollapseItem
                        text={TEXT.MENU_AIRLINES}
                        subText1={TEXT.MENU_ALL_AIRLINES}
                        subText2={TEXT.MENU_CREATE_NEW}
                        linkMain="/aircompany"
                        linkCreate="/airline-create"
                    />
                </div>
                <div className={styles.item}>
                    <NestedCollapseItem
                        text={TEXT.MENU_AIRLINE_MANAGER}
                        subText1={TEXT.MENU_ALL_AIRLINE_MANAGER}
                        subText2={TEXT.MENU_CREATE_NEW}
                        linkMain="/airlinemanagers"
                        linkCreate="/airlinemanager-create"
                    />
                </div>

                <div className={styles.item}>
                    <NestedCollapseItem
                        text={TEXT.MENU_HANDLING}
                        subText1={TEXT.MENU_ALL_HANDLING}
                        subText2={TEXT.MENU_CREATE_NEW}
                        linkMain="/handlingmanagers"
                        linkCreate="/manager-create"
                    />
                </div>

                <div className={styles.item}>
                    <NestedItem text={TEXT.MENU_REPORTS} link="/reports"/>
                </div>

            </div>


        </List>
    );
}

export default CheckinchList;
