import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel } from "@mui/material";

import { authAPI, handlingManagerService } from "../../api/apiManager";
import { login } from "../../redux/features/userSlice";

import styles from './AirManagerDropdown.module.scss'
import { setLoading } from "../../redux/features/isLoadingSlice";

export default function AirManagerDropdown() {
    const [manager, setManager] = React.useState("");
    const [managers, setManagers] = React.useState<any[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleChange = (event: SelectChangeEvent) => {
        setManager(event.target.value);
        console.log(manager, "manager")
        loginAsHandlingManager(Number(event.target.value))
    };

    const loginAsHandlingManager = async (id: number) => {
        dispatch(setLoading(true))
        await authAPI.loginAsHandlingManager(id)
        let user = localStorage.getItem('user')
        if (user) {
            dispatch(login(JSON.parse(user)))
        }
        localStorage.setItem("isCheckinch", "true")
        dispatch(setLoading(false))
        navigate('/dashboard')

    }

    const handleClick = async () => {
        await authAPI.rollBack()
        let user = localStorage.getItem('user')
        if (user) {
            dispatch(login(JSON.parse(user)))
        }

    }

    const getActiveHandlingManagers = async () => {
        const result = await handlingManagerService.getActiveHandlingManagers()
        setManagers(result?.data)
    }

    useEffect(() => {
        getActiveHandlingManagers()
        console.log(managers)
    }, [])

    return (
        <div>
            <FormControl size="small" sx={{m: 1, minWidth: 270, fontSize: "14px"}}>
                <InputLabel id="demo-simple-select-helper-label">Handling company manager</InputLabel>

                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className={styles.select}
                    value={manager}
                    label="Select manager"
                    onChange={handleChange}
                    displayEmpty
                >

                    {managers?.map((elem) => {
                        return <MenuItem key={elem.id} value={elem.id}>{elem.firstname} {elem.lastname} ( {elem.handlingCompanyName} )</MenuItem>
                    })}

                </Select>
            </FormControl>

        </div>
    );
}
