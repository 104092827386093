import StewardEditGrid from "../../components/StewardEditGrid/StewardEditGrid";

import styles from "./StewardActions.module.scss";

const StewardEdit = () => {

    return (
        <div className={styles.data_grid}>
            <StewardEditGrid/>
        </div>
    );
}

export default StewardEdit;
