import {createSlice} from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {},
        isAuth: false,
        isCheckinch: localStorage.getItem('isCheckinch')
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: state => {
            state.user = {};
            state.isAuth = false;
        },
        auth: (state) => {
            state.isAuth = true;
        },
    }
})

export const {login, logout, auth} = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
