import { useEffect, useState } from "react";

import FlightComponent from "../../../components/FlightComponent/FlightComponent";
import { handlingManagerService } from "../../../api/apiManager";

import TEXT from "../../../assets/text.json"
import styles from "./HandlingActions.module.scss";

const HandlingDashboard = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [info, setInfo] = useState<any>();

    const getDashboard = async () => {
        setLoading(true)
        let result = await handlingManagerService.dashboard()
        setInfo(result?.data)
        setLoading(false)
        console.log(info?.length)
    }

    useEffect(() => {
        getDashboard()
    }, []);

    return (
        <>
            <div className={styles.flights_box}>
                <h3>{TEXT.DASHBOARD_FLIGHTS} </h3>
                <div className={styles.flights_cards}>
                    {(info?.length)
                        ? info.map((flight: any, index: number) => {
                            return <FlightComponent key={index} flight={flight}/>
                        })
                        : <p>No flights to today</p>}
                </div>
            </div>
        </>

    );
}

export default HandlingDashboard;
