import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@mui/material/Button";

import Loader from "../Loader/Loader";
import { airlineManagerService, checkinchService } from "../../api/apiManager";
import { setCompany } from "../../redux/features/airlineManagerSlice";
import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";
import ProductListView from "../ProductListView/ProductListView";

import styles from "./CompanyInfoGrid.module.scss";
import WeightSettingsListView from "../WeightSettingsListView/WeightSettingsListView";
import { Divider, ListItem, ListItemButton } from "@mui/material";

const CompanyInfoGrid = () => {
  const dispatch = useDispatch();

  const [info, setInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [baggageRanges, setBaggageRanges] = useState([]);
  const [carryOnRanges, setCarryOnRanges] = useState([]);
  const [luggageRanges, setLuggageRanges] = useState([]);

  const getAirlineLuggageRanges = async (companyId: any) => {
    setLoading(true);
    await checkinchService
      .getAirlineLuggageRanges(companyId)
      .then((res) => {
        setBaggageRanges(
          res?.data?.baggageRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setCarryOnRanges(
          res?.data?.carryOnBagRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setLuggageRanges(
          res?.data?.luggageRange?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // //navigate('/404')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   useEffect(() => {
  //     if (info.id) {
  //       await getAirlineLuggageRanges(info.id);
  //     }
  //   }, [info]);

  const getCompany = async () => {
    setLoading(true);
    await airlineManagerService
      .getCompany()
      .then((res) => {
        setInfo(res?.data);
        getAirlineLuggageRanges(res?.data.id);
        dispatch(setCompany(res?.data));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div className={styles.app}>
        <Table>
          <tbody>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">ID</TableCell>
              <TableCell>{info?.id}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Company name</TableCell>
              <TableCell>{info?.companyName}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Short company name</TableCell>
              <TableCell>{info?.shortCompanyName}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">BRN</TableCell>
              <TableCell>{info?.legalEntityId}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Country</TableCell>
              <TableCell>{info?.country}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Address</TableCell>
              <TableCell>
                {info?.address.city}, {info?.address.street},{" "}
                {info?.address.building}, <br /> Floor: {info?.address.floor},
                Office: {info?.address.officeNumber}, Zip Code:{" "}
                {info?.address.zipCode}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Contact details section</TableCell>
              <TableCell>
                {info?.phone}, {info?.email}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Contact person details</TableCell>
              <TableCell>
                {info?.contact.personFirstname} {info?.contact.personLastname},{" "}
                {info?.contact.personPhone}, {info?.contact.personEmail},{" "}
                {info?.contact.personRole}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Time zone</TableCell>
              <TableCell>{info?.timeZone}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Status</TableCell>
              <TableCell>{info?.isActive ? "Active" : "Inactive"}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Max hand luggage height</TableCell>
              <TableCell>{info?.settings.maxLength}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Max hand luggage width</TableCell>
              <TableCell>{info?.settings.maxWidth}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Max hand luggage depth</TableCell>
              <TableCell>{info?.settings.maxDepth}</TableCell>
            </TableRow>
            {info?.companyType ? (
              <>
                <TableRow className={styles.row_style}>
                  <TableCell variant="head">Max hand luggage weight</TableCell>
                  <TableCell>{info?.settings.maxHandbagWeight}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                  <TableCell variant="head">Max carry on bag weight</TableCell>
                  <TableCell>{info?.settings.maxCarryOnBagWeight}</TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow className={styles.row_style}>
                  <TableCell variant="head">Max carry on weight</TableCell>
                  <TableCell>{info?.settings.maxCarryOnWeight}</TableCell>
                </TableRow>
              </>
            )}

            <TableRow className={styles.row_style}>
              <TableCell variant="head">
                Max hand luggage weight for ranges
              </TableCell>
              <TableCell>
                {info?.settings.maxHandLuggageWeightForRanges}
              </TableCell>
            </TableRow>

            <TableRow className={styles.row_style}>
              <TableCell variant="head">
                Max carry on bag weight for ranges
              </TableCell>
              <TableCell>
                {info?.settings.maxCarryOnBagWeightForRanges}
              </TableCell>
            </TableRow>

            <TableRow className={styles.row_style}>
              <TableCell variant="head">Check in time</TableCell>
              <TableCell>{info?.settings.checkinTime}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Notify steward</TableCell>
              <TableCell>{info?.settings.notifySteward}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">
                Hand luggage payment for oversize
              </TableCell>
              <TableCell>{info?.settings.paymentForOversize}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Payment for overweight</TableCell>
              <TableCell>
                {info?.settings.paymentForBaggageOverweight}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Calculating method</TableCell>
              <TableCell>
                {info?.settings.calculatingMethodOfOverweight}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Currency</TableCell>
              <TableCell>{info?.settings.currency}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Business ID</TableCell>
              <TableCell>{info?.settings.business_id}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Created at</TableCell>
              <TableCell>
                {dateParseToDate(info?.created_at)} /{" "}
                {dateParseToTime(info?.created_at)}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Updated at</TableCell>
              <TableCell>
                {dateParseToDate(info?.updated_at)} /{" "}
                {dateParseToTime(info?.updated_at)}
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
        <div className={styles.button}>
          <NavLink to={`/company-edit`}>
            <Button variant="contained">Edit</Button>
          </NavLink>
        </div>
      </div>
      <div>
        <div className={styles.user_card}>
          <div className={styles.user_image}>
            <img src={info?.logo} alt="company" />
          </div>
          <div className={styles.user_info}>
            <h4 className={styles.user_name}>{info?.companyName}</h4>
          </div>
        </div>
        <div className={styles.product_card}>
          <ProductListView
            currency={info?.settings.currency}
            companyId={info?.id}
          />
        </div>
        <div className={styles.product_card}>
          <WeightSettingsListView
            currency={info?.settings.currency}
            companyId={info?.id}
          />
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Baggages ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {baggageRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Luggage ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {luggageRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Carry on ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {carryOnRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInfoGrid;
