import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";

import { baggageSettingsService, productsService } from "../../../../../api/apiManager";
import { setUpdateFlag } from "../../../../../redux/features/isUpdateFlag";

import styles from "../../../BaggageSettingsCard.module.scss";

const ModalForm = ({service, companyIdFromProps, close}: any) => {
    const {control, getValues} = useForm<any>({
        mode: 'onSubmit'
    });
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(service.isActive);
    const search = useLocation().search;
    const companyId = new URLSearchParams(search).get('id') || companyIdFromProps;
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleClick = async (e: any) => {
        let dataArray: any = {
            id: parseInt("", 10),
            companyId: parseInt("", 10),
            name: '',
            price: parseInt("", 10),
            isActive: Boolean()
        }

        dataArray['companyId'] = +companyId! || companyIdFromProps;
        dataArray['id'] = +service.id!;
        dataArray['baggageName'] = getValues("baggageName");
        dataArray['maxBaggageWeight'] = +getValues("maxBaggageWeight");
        dataArray['isActive'] = checked;

        const dataJson = JSON.stringify(dataArray)

        try {
            let response = await baggageSettingsService.updateBaggage(dataJson)
            if (response?.status === 200) {
                toast.success("Baggage saved");
            } else {
                toast.error(response);

            }
        } catch (err) {
            console.error(err)
        } finally {
            dispatch(setUpdateFlag())
            close()
        }
    }


    return (

        <form className={styles.form_box}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& .MuiTextField-root': {m: 1, width: '275px'},
                }}
            >

                <div className={styles.product}>
                    <Controller
                        render={({field}) => <FormControlLabel control={<Checkbox checked={checked}
                                                                                  inputProps={{'aria-label': 'controlled'}} {...field}
                                                                                  onChange={handleCheckbox}/>}
                                                               label="Active"/>}
                        name="isActive"
                        control={control}
                    />
                    <Controller
                        render={({field}) => <TextField required label="Name" {...field}
                                                        className="materialUIInput"/>}
                        name="baggageName"
                        control={control}
                        defaultValue={service?.baggageName}
                    />
                    <Controller
                        render={({field}) => <TextField required label="Weight" {...field}
                                                        type="number"
                                                        className="materialUIInput"/>}
                        name="maxBaggageWeight"
                        control={control}
                        defaultValue={service?.maxBaggageWeight}
                    />

                    <div className={styles.buttonWrapper}>
                        <Button
                            className={styles.button}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                handleClick(e)
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>

            </Box>
        </form>

    );
};

export default ModalForm;
