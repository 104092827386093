import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

import { Button } from "@mui/material";

import { airlineManagerService } from "../../api/apiManager";
import { setLoading } from "../../redux/features/isLoadingSlice";
import { IImportError } from "../../models/import-error";

import styles from './ImportFlight.module.scss'

const ImportFlight = ({setImportFlightResponse}: any) => {
    const [showPopupError, setShowPopupError] = useState(false)
    const [errorData, setErrorData] = useState<IImportError[]>([])
    const dispatch = useDispatch()

    const importFlight = async (file: any) => {
        dispatch(setLoading(true))
        let result = await airlineManagerService.importFlight(file)
        setImportFlightResponse(result?.data)
        if (result?.status === 201) {
            toast.success(`Successfully imported flights: ${result?.data.numberOfSuccessfullyImported}
            With error: ${result?.data.numberOfUnsuccessfullyImported}`)
            if (result.data.numberOfUnsuccessfullyImported > 0) {
                setShowPopupError(true)
                setErrorData(result?.data.errors)
            } 
        } else {
            toast.error(result)
        }
        dispatch(setLoading(false))
    }

    const uploadFlight = (event: any) => {
        const formData = new FormData();
        if (event.target.files[0]) {
            let file = event.target.files[0]
            formData.append('file', file);
            importFlight(formData)
        }
        event.target.value = ""
    };

    return (
        <>
            <input
                accept=".csv"
                className={""}
                style={{display: 'none'}}
                id="raised-button-file"
                multiple
                type="file"
                onChange={uploadFlight}
                value={""}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" className={""}>
                    Import
                </Button>
            </label>
            {
                showPopupError ? <div className={styles.overlay}>
                <div className={styles.modal}>
                    <div className={styles.actions}>
                        <Button variant="outlined" onClick={() => {
                            setShowPopupError(false)
                        }}>Close</Button>
                    </div>
                    {
                        errorData?.map((error: IImportError) => 
                            <div key={error.flight.flightUniqueId} className={styles.errorRow}>
                                <div>
                                    <h5>Error</h5>
                                    <p>{error.errorMessage}</p>
                                </div>
                                <div>
                                    <h5>Flight Data</h5>
                                    <p>{`Number: ${error.flight.flightNumber}, 
                                        Unique ID: ${error.flight.flightUniqueId}, 
                                        Departure: ${error.flight.departureCountry} (${error.flight.departureCity} / ${error.flight.departureIata}) - ${error.flight.departureDateTime}},
                                        Arrival: ${error.flight.arrivalCountry} (${error.flight.arrivalCity} / ${error.flight.arrivalIata}) - ${error.flight.arrivalDateTime}`}</p>
                                </div>
                                <div>
                                    <h5>Possible Solution</h5>
                                    <p>{error.possibleSolution}</p>
                                </div>
                            </div>
                        )
                    }
                    
                </div>
            </div> : ""
            }
        </>
    );
};

export default ImportFlight;
