import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { GridColDef } from "@mui/x-data-grid";

import Grid from "../../components/Grid/Grid";
import { checkinchService } from "../../api/apiManager";
import { ICompanyList } from "../../models/company-list.model";
import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";
import { setAirlineCompanyManagers } from "../../redux/features/checkinchSlice";

import styles from "./AirManagerActions.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        width: 65
    },
    {
        field: 'workerId',
        headerName: 'Worker ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'firstname',
        headerName: 'First name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'lastname',
        headerName: 'Last name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'phoneNumber',
        headerName: 'Mobile phone number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
    },
    {
        field: 'username',
        headerName: 'Email address',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: (params) => {
            return params.value ? params.value = "Active" : params.value = "Inactive"
        }
    },
    {
        field: 'companies',
        headerName: 'Airline company',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: true,
        valueGetter: (params) => {
            return params.value[0].companyName
        }
    },
    {
        field: 'companiesBRN',
        headerName: 'BRN',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: true,
        valueGetter: (params) => {
            return params.row.companies[0].legalEntityId
        }
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))

        },
    },

];

const AirManagerList = () => {

    const [info, setInfo] = useState<ICompanyList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch()
    const getHandlings = async () => {
        setLoading(true)
        await checkinchService.getAirlineManagerList().then((res) => {
            setInfo(res?.data);
            dispatch(setAirlineCompanyManagers(res?.data))
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getHandlings()
    }, []);

    return (
        <div className={styles.data_grid}>
            <h3>Data</h3>
            <Grid
                columns={columns}
                rows={info}
                loading={loading}
                singleview="/airmanager-view"
            />
        </div>
    );
}

export default AirManagerList;
