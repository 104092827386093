import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";

import styles from "./NestedList.module.scss";

const NestedCollapseItem = ({text, subText1, subText2, linkMain, linkCreate, popup = false}: any) => {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()

    const handleClick = () => {
        setOpen(!open)
    };

    return <>
        <ListItemButton onClick={handleClick}>
            <ListItemText
                primaryTypographyProps={{
                    fontWeight: 600,
                }}
                primary={text}
            />
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <div className={styles.item}>
                    <NavLink className={(navData) => (navData.isActive ? styles.activeLink : '')}  to={linkMain}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemText primary={subText1}/>
                        </ListItemButton>
                    </NavLink>
                </div>

                <div className={styles.item}>
                    <NavLink className={(navData) => (navData.isActive ? styles.activeLink : '')} to={linkCreate}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemText primary={subText2}/>
                        </ListItemButton>
                    </NavLink>
                </div>
            </List>
        </Collapse>
    </>
}

export default NestedCollapseItem;
