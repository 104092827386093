import { useNavigate } from "react-router-dom";
import { dateParseToTime } from "../../common/dateFormatter";

import arrowLogo from "../../assets/arrow-to-right.svg"
import styles from "./FlightComponent.module.scss";

const FlightComponent = ({flight}: any) => {

    let navigate = useNavigate();

    const openFlight = () => {
        navigate(`../flight-view/${flight.id}`);
    }

    return (
        <>
            <div className={styles.flight_card} onClick={openFlight}>
                <div className={styles.left_info}>
                    <p className={styles.flight_number}>{flight?.flightNumber}</p>
                    <p className={styles.origin}>{flight?.departureIata} <span>{flight?.origin}</span></p>
                    <p className={styles.departure_time}>{dateParseToTime(flight?.departureDateTime)}</p>
                </div>
                <div><img src={arrowLogo} alt="-"/></div>
                <div className={styles.right_info}>
                    <p className={styles.timer}>{flight?.creationOrigin}</p>

                    <p className={styles.destination}><span>{}</span> {flight?.arrivalIata}
                    </p>
                    <p className={styles.arrival_time}>{dateParseToTime(flight?.arrivalDateTime)}</p>
                </div>
            </div>
        </>
    );
}

export default FlightComponent;
