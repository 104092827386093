import {createSlice} from "@reduxjs/toolkit"

export const handlingManagerSlice = createSlice({
    name: "handlingManagerPage",
    initialState: {
        handlingManager: {},
        shiftManager: {},
        steward: {},
        flight: {},
        filter: {
            fromDate: "",
            toDate: "",
            airport: "",
            fromDateDisabled: false,
            toDateDisabled: false,
            alignment: ""
        },
    },
    reducers: {
        setSteward: (state, action) => {
            state.steward = action.payload;
        },
        setHandling: (state, action) => {
            state.handlingManager = action.payload;
        },
        setFlight: (state, action) => {
            state.flight = action.payload;
        },
        setShiftManager: (state, action) => {
            state.shiftManager = action.payload;
        },
        setFromDate: (state, action) => {
            state.filter.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.filter.toDate = action.payload
        },
        setAirportName: (state, action) => {
            state.filter.airport = action.payload
        },
        setFromDateDisabled: (state, action) => {
            state.filter.fromDateDisabled = action.payload
        },
        setToDateDisabled: (state, action) => {
            state.filter.toDateDisabled = action.payload
        },
        setAlignment: (state, action) => {
            state.filter.alignment = action.payload
        },
        clearFilters: (state) => {
            state.filter = {}
        },
    }
})

export const {
    setSteward,
    setHandling,
    setFlight,
    setShiftManager,
    clearFilters,
    setAlignment,
    setToDateDisabled,
    setFromDateDisabled,
    setAirportName,
    setToDate,
    setFromDate
} = handlingManagerSlice.actions;


export default handlingManagerSlice.reducer;
