import ResetForm from "../../components/ResetForm/ResetForm";
import logo from '../../assets/checkinch_logo.png'
import styles from './ResetPassword.module.sass'

const ResetPassword = () => {
    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.main_left}>
                    <ResetForm/>
                </div>
                <div className={styles.main_right}>
                    <img src={logo} alt="logo"/>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
