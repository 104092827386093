import React from 'react';
import styles from './ModalDone.module.scss';
import errorLogo from '../../../../assets/Icon.png'


const ModalDone = (props: any) => {


    return (

        <div className={styles.wrapper}>

            {
                (props.isWrong)
                    ? <>
                        <img src={errorLogo} alt="error"/>
                        <h1 className={styles.modalH1}>{props.description}</h1>
                    </> : <><h1 className={styles.modalH1}>{props.description}</h1>
                        <p className={styles.modalParagraph}>Your Password has been sent to your email.</p>
                    </>}


        </div>


    );
};

export default ModalDone;
