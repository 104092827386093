const REGEX_PHONE_VALIDATION = /^[0-9]+$/;
const REGEX_ONLY_NUMBER_VALIDATION = /^[0-9]/;
const REGEX_OPERATIONAL_SETTINGS_VALIDATION = /^[0-9.]+$/;

export const dataGridService = {
    onlyNumberRegExp(event: any) {
        if (!REGEX_ONLY_NUMBER_VALIDATION.test(event.key) && (event.keyCode !== (8) && event.keyCode !== (37) && event.keyCode !== (39))) {
            event.preventDefault();
        }
    },
    phoneRegExp(event: any) {
        if (!REGEX_PHONE_VALIDATION.test(event.key) && (event.keyCode !== (8) && event.keyCode !== (37) && event.keyCode !== (39))) {
            event.preventDefault();
        }
    },
    operationalSettingsRegExp(event: any) {
        if (!REGEX_OPERATIONAL_SETTINGS_VALIDATION.test(event.key) && (event.keyCode !== (8) && event.keyCode !== (37) && event.keyCode !== (39))) {
            event.preventDefault();
        }
    },
    removeEmptyFields(data: any) {
        return Object.keys(data).map(key => {
            if (data[key] === "") {
                data[key] = null
            }
            if (data.password === null) {
                delete data.password;
            } else if (data.passwordConfirmation === null) {
                delete data.passwordConfirmation;
            }
        })
    }

}
