import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';

import Loader from '../Loader/Loader';
import { checkinchService } from "../../api/apiManager";
import { schemaManagerEdit } from '../../common/schemaValidation';
import { dataGridService } from "../../services/dataGridService";
import { setView } from '../../redux/features/isDeleteSlice';
import PromptCancel from '../PromptCancel/PromptCancel';

import TEXT from '../../assets/text.json'
import styles from './AirManagerEditGrid.module.scss'

const UserEditGrid = () => {
    const dispatch = useDispatch()
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const airlineManager = useSelector((store: any) => store?.checkinchPage?.airlineCompanyManager)

    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaManagerEdit),
    });
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate()

    const openPrompt = () => {
        dispatch(setView(true))
    }

    const onSubmit = async (data: any) => {
        const parsedData = {...data, ['id']: parseInt(id!, 10)}
        dataGridService.removeEmptyFields(parsedData)

        setLoading(true)
        await checkinchService.updateAirlineManager(parsedData, id).then((res) => {
            if (res.status === 200) {
                toast.success(TEXT.NOTIFY_AIRLINEMANAGER_EDIT);
                navigate(-1)
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    if (!Object.keys(airlineManager).length) {
        navigate(`/airmanager-view/${id}`)
    }


    if (loading) return <Loader/>

    return (
        <>
            <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                <h3>Edit Airline Company Manager</h3>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Main info</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Worker ID" {...field} className="materialUIInput"/>
                                    </div>}
                                name="workerId"
                                control={control}
                                defaultValue={airlineManager?.workerId}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="First name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.firstname?.message}</small>
                                    </div>}
                                name="firstname"
                                control={control}
                                defaultValue={airlineManager?.firstname}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Last name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.lastname?.message}</small>
                                    </div>}
                                name="lastname"
                                control={control}
                                defaultValue={airlineManager?.lastname}
                            />
                            <Controller
                                render={({field}) => <TextField rows={4}
                                                                multiline label="Comments" {...field}
                                                                className="materialUIInput"/>}
                                name="comments"
                                control={control}
                                defaultValue={airlineManager?.comments}
                            />
                        </div>
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Contact details</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField
                                        onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                                        label="Phone number *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.phoneNumber?.message}</small>
                                </div>
                                }
                                name="phoneNumber"
                                control={control}
                                defaultValue={airlineManager?.phoneNumber}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField label="Email *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.username?.message}</small>
                                </div>
                                }
                                name="username"
                                control={control}
                                defaultValue={airlineManager?.username}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Manager role" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.managerRoleName?.message}</small></div>}
                                name="managerRoleName"
                                control={control}
                                defaultValue={airlineManager?.managerRoleName}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Password *"
                                    placeholder="******" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.password?.message}</small></div>}
                                name="password"
                                control={control}
                                defaultValue={null}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Confirm password *" placeholder="******" {...field}
                                    className="materialUIInput"/>
                                    <small className={styles.error}>{errors.passwordConfirmation?.message}</small>
                                </div>}
                                name="passwordConfirmation"
                                control={control}
                                defaultValue={null}
                            />
                        </div>
                    </div>
                </Box>
                <Button
                    className={styles.button}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit">
                    Submit
                </Button>

                <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => openPrompt()}>
                    Cancel
                </Button>
            </form>
            {isView ? <PromptCancel/> : ''}
        </>
    );
}

export default UserEditGrid;
