import axios from "axios";
import env from "../environment";
import store from "../redux/store/store";
import { userService } from "../services/userService";
import { logout } from "../redux/features/userSlice";

const instance = axios.create({
  baseURL: `${env.baseUrl}`,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "",
  },
});

//token interceptor
instance.interceptors.request.use((config) => {
  let token = JSON.parse(localStorage.getItem("token"));
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// logout interceptor
instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      store.dispatch(logout());
      window.location.href = `${window.location.origin}/login`;
      userService.logout();
    }
    return error.response.data?.description;
  }
);

export const authAPI = {
  login(username, password) {
    try {
      return instance
        .post("admin/auth", { username, password })
        .then((response) => {
          if (response.data.access_token) {
            if (
              response.data.user.role !== 0 &&
              response.data.user.role !== 1
            ) {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.access_token)
              );
              localStorage.setItem("user", JSON.stringify(response.data.user));
            }
          }
          return response.data;
        });
    } catch (error) {
      return error.response;
    }
  },
  loginAsAirlineManager(id) {
    try {
      return instance
        .get(`admin/auth/login-as/airline/${id}`)
        .then((response) => {
          if (response.data.access_token) {
            if (
              response.data.user.role !== 0 &&
              response.data.user.role !== 1
            ) {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.access_token)
              );
              localStorage.setItem("user", JSON.stringify(response.data.user));
            }
          }
          return response.data;
        });
    } catch (error) {
      return error.response;
    }
  },
  loginAsHandlingManager(id) {
    try {
      return instance
        .get(`admin/auth/login-as/handling/${id}`)
        .then((response) => {
          if (response.data.access_token) {
            if (
              response.data.user.role !== 0 &&
              response.data.user.role !== 1
            ) {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.access_token)
              );
              localStorage.setItem("user", JSON.stringify(response.data.user));
            }
          }
          return response.data;
        });
    } catch (error) {
      return error.response;
    }
  },
  async rollBack() {
    try {
      return instance.get(`/admin/auth/login-as/rollback`).then((response) => {
        if (response.data.access_token) {
          if (response.data.user.role !== 0 && response.data.user.role !== 1) {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.access_token)
            );
            localStorage.setItem("user", JSON.stringify(response.data.user));
          }
        }
        return response.data;
      });
    } catch (error) {
      return error.response;
    }
  },
  async resetPassword(body) {
    try {
      return await instance.post(`/admin/auth/reset-password`, body);
    } catch (error) {
      return error.response.data.description;
    }
  },
};

export const checkinchService = {
  getCheckinchSelf() {
    try {
      return instance.get(`/admin/checkinch/info`);
    } catch (error) {
      return error.response;
    }
  },

  async getDashboard({ fromDate, toDate, country, airport }) {
    try {
      return instance.get(`admin/checkinch/dashboard`, {
        params: {
          fromDate: fromDate || null,
          toDate: toDate || null,
          country: country || null,
          airport: airport || null,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getCompanies() {
    try {
      return instance.get(`admin/checkinch/company-names`);
    } catch (error) {
      return error.response;
    }
  },
  getActiveAirlineCompanies() {
    try {
      return instance.get(`admin/checkinch/airline/active`);
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineCompanies() {
    try {
      return instance.get(`admin/company/all/active`);
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineManagerList() {
    try {
      return instance.get("admin/checkinch/airline-manager/all");
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineManagerId(id) {
    try {
      return instance.get(`admin/checkinch/airline-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createAirlineManager(body) {
    try {
      return await instance.post("admin/checkinch/airline-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateAirlineManager(body, id) {
    try {
      return await instance.patch("admin/checkinch/airline-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateCheckinch(body) {
    try {
      return await instance.patch("admin/checkinch/checkinch-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteAirlineManager(id) {
    try {
      return instance.delete(`admin/checkinch/airline-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async getHandlingList() {
    try {
      return instance.get("admin/checkinch/handling-manager/all");
    } catch (error) {
      return error.response;
    }
  },
  async getHandlingId(id) {
    try {
      return instance.get(`admin/checkinch/handling-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createHandling(body) {
    try {
      return await instance.post("admin/checkinch/handling-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateHandling(body) {
    try {
      return await instance.patch("admin/checkinch/handling-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteHandling(id) {
    try {
      return instance.delete(`admin/checkinch/handling-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineCompanyList() {
    try {
      return instance.get("admin/company");
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineCompanyId(id) {
    try {
      return instance.get(`admin/company/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async getAirlineLuggageRanges(id) {
    try {
      return instance.get(`admin/bag-weight-range/${id}/all`);
    } catch (error) {
      return error.response;
    }
  },
  async deleteAirlineLuggageRanges(id) {
    try {
      return instance.delete(`admin/bag-weight-range/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async updateAirlineLuggageRange(body) {
    try {
      return instance.patch("admin/bag-weight-range", body);
    } catch (error) {
      return error.response;
    }
  },
  async createAirlineCompany(body) {
    try {
      return await instance.post("admin/company", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateAirlineCompany(body, id) {
    try {
      return await instance.patch("admin/company", body, {
        params: { id: id },
      });
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteAirlineCompany(id) {
    try {
      return instance.delete(`admin/company/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  uploadLogo(body) {
    try {
      return instance.post("admin/company/logo/upload", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  updateLogo(body, id) {
    try {
      return instance.post(`admin/company/logo/${id}`, body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getCountriesCities(input, type) {
    try {
      return instance.get("admin/checkinch/autocomplete", {
        params: { input: input, type: type },
      });
    } catch (error) {
      return console.log(error);
    }
  },
  async getCompaniesReports({
    fromDate,
    toDate,
    airport,
    companyName,
    country,
    handlingCompanyName,
    flightNumber,
  }) {
    try {
      return axios.get(`${env.baseUrl}api/reports/v2/companies`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
        responseType: "blob",
        params: {
          fromDate: fromDate || null,
          toDate: toDate || null,
          airport: airport || null,
          companyName: companyName || null,
          country: country || null,
          handlingCompanyName: handlingCompanyName || null,
          flightNumber: flightNumber || null,
        },
      });
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getPeriodicReports({
    fromDate,
    toDate,
    airport,
    companyName,
    country,
    handlingCompanyName,
    flightNumber,
  }) {
    try {
      return axios.get(`${env.baseUrl}api/reports/v2/periodic`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
        responseType: "blob",
        params: {
          fromDate: fromDate || null,
          toDate: toDate || null,
          airport: airport || null,
          companyName: companyName || null,
          country: country || null,
          handlingCompanyName: handlingCompanyName || null,
          flightNumber: flightNumber || null,
        },
      });
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getReportsList({
    fromDate,
    toDate,
    airport,
    companyName,
    country,
    handlingCompanyName,
    flightNumber,
  }) {
    try {
      return instance.get(`api/reports/v2/checkinch`, {
        params: {
          fromDate: fromDate || null,
          toDate: toDate || null,
          airport: airport || null,
          companyName: companyName || null,
          country: country || null,
          handlingCompanyName: handlingCompanyName || null,
          flightNumber: flightNumber || null,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getReportsListAtPDF({ period, companyName, date }) {
    try {
      return instance.get(`api/reports/pdf`, {
        responseType: "blob",
        params: {
          date,
          period: period || null,
          companyName: companyName || null,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getCurrencies() {
    try {
      return instance.get(`admin/company/currencies`);
    } catch (error) {
      return error.response;
    }
  },
};

export const airlineManagerService = {
  getAirlineManagerSelf() {
    try {
      return instance.get("admin/airline/info");
    } catch (error) {
      return error.response;
    }
  },
  dashboard() {
    try {
      return instance.get("admin/airline/dashboard");
    } catch (error) {
      return error.response;
    }
  },
  async getCompany() {
    try {
      return instance.get("admin/airline/company");
    } catch (error) {
      return error.response;
    }
  },
  async updateCompany(body) {
    try {
      return await instance.patch("admin/airline/company", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getFlightList({ fromDate, toDate, airport }) {
    try {
      return instance.get("admin/airline/flight", {
        params: {
          fromDate: fromDate || null || undefined,
          toDate: toDate || null || undefined,
          airport: airport || null || undefined,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getFlightById(id) {
    try {
      return instance.get(`admin/airline/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createFlight(body) {
    try {
      return instance.post("admin/airline/flight", body);
    } catch (error) {
      console.log(error);
    }
  },
  async updateAirlineCompanyManagerSelf(body) {
    try {
      return await instance.patch("admin/airline/airline-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateFlight(body) {
    try {
      return await instance.patch("admin/airline/flight", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteFlight(id) {
    try {
      return instance.delete(`admin/airline/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async importFlight(body) {
    try {
      return await instance.post("admin/airline/flight/import", body);
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getCountriesCities(input, type) {
    try {
      return instance.get("admin/airline/autocomplete", {
        params: { input: input, type: type },
      });
    } catch (error) {
      return console.log(error);
    }
  },
  async sendRequestStickers(body) {
    try {
      return await instance.post(
        "admin/airline/mailing/stickers-request",
        body
      );
    } catch (error) {
      return error.response.data.message;
    }
  },
  async sendSupport(body) {
    try {
      return await instance.post("admin/airline/mailing/support-request", body);
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getReportsList({ fromDate, toDate, airport, country, flightNumber }) {
    try {
      return instance.get(`api/reports/v2/airline-manager`, {
        params: {
          fromDate: fromDate || null,
          toDate: toDate || null,
          airport: airport || null,
          country: country || null,
          flightNumber: flightNumber || null,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getPeriodicReports({
    fromDate,
    toDate,
    airport,
    country,
    flightNumber,
    withCategories,
  }) {
    try {
      return axios.get(
        `${env.baseUrl}api/reports/airline-manager/periodic-report`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
          responseType: "blob",
          params: {
            fromDate: fromDate || null,
            toDate: toDate || null,
            airport: airport || null,
            country: country || null,
            flightNumber: flightNumber || null,
            withCategories: withCategories || null,
          },
        }
      );
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getCategories(title) {
    try {
      return instance.get("admin/product/category/autocomplete", {
        params: { title },
      });
    } catch (error) {
      return console.log(error);
    }
  },
};

export const handlingManagerService = {
  getHandlingSelf() {
    try {
      return instance.get("admin/handling/info");
    } catch (error) {
      return error.response;
    }
  },
  dashboard() {
    try {
      return instance.get("admin/handling/dashboard");
    } catch (error) {
      return error.response;
    }
  },
  async getHandlingList() {
    try {
      return instance.get("admin/handling/handling-manager/all");
    } catch (error) {
      return error.response;
    }
  },
  getActiveHandlingManagers() {
    try {
      return instance.get("admin/airline/handling/active");
    } catch (error) {
      return error.response;
    }
  },
  async getHandlingId(id) {
    try {
      return instance.get(`admin/handling/handling-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createHandling(body) {
    try {
      return await instance.post("admin/handling/handling-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateHandling(body) {
    try {
      return await instance.patch("admin/handling/handling-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteHandling(id) {
    try {
      return instance.delete(`admin/handling/handling-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async updateHandlingSelf(body) {
    try {
      return await instance.patch("admin/handling/handling-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getShiftManagerList() {
    try {
      return instance.get("admin/handling/shift-manager/all");
    } catch (error) {
      return error.response;
    }
  },
  async getShiftManagerId(id) {
    try {
      return instance.get(`admin/handling/shift-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createShiftManager(body) {
    try {
      return await instance.post("admin/handling/shift-manager", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async updateShiftManager(body, id) {
    try {
      return await instance.patch("admin/handling/shift-manager", body, {
        params: { id: id },
      });
    } catch (error) {
      return error.response.data.description;
    }
  },
  deleteShiftManager(id) {
    try {
      return instance.delete(`admin/handling/shift-manager/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async createSteward(body) {
    try {
      return await instance.post("admin/handling/steward", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getSteward() {
    try {
      return instance.get("admin/handling/steward");
    } catch (error) {
      return error.response;
    }
  },
  async getCurrentSteward(id) {
    try {
      return instance.get(`admin/handling/steward/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  deleteSteward(id) {
    try {
      return instance.delete(`admin/handling/steward/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async updateSteward(body) {
    try {
      return await instance.patch("admin/handling/steward", body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async linkStewardsToFlight(body) {
    try {
      return await instance.patch(`admin/handling/steward/appoint`, body);
    } catch (error) {
      return error.response.data.description;
    }
  },
  async getFlightList({ fromDate, toDate, airport }) {
    try {
      return instance.get("admin/handling/flight", {
        params: {
          fromDate: fromDate || null || undefined,
          toDate: toDate || null || undefined,
          airport: airport || null || undefined,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getFlightId(id) {
    try {
      return instance.get(`admin/handling/flight/${id}`);
    } catch (error) {
      return error.response;
    }
  },
  async getLinkedCompanies() {
    try {
      return instance.get("admin/handling/companies/linked");
    } catch (error) {
      return error.response;
    }
  },
  async sendSupport(body) {
    try {
      return await instance.post(
        "admin/handling/mailing/support-request",
        body
      );
    } catch (error) {
      return error.response.data.message;
    }
  },
  async sendRequestStickers(body) {
    try {
      return await instance.post(
        "admin/handling/mailing/stickers-request",
        body
      );
    } catch (error) {
      return error.response.data.message;
    }
  },
};

export const flightsService = {
  async getFlights() {
    try {
      return instance.get("admin/flight/all");
    } catch (error) {
      return error.response;
    }
  },
};

export const productsService = {
  createProduct(body) {
    try {
      return instance.post("admin/product", body);
    } catch (error) {
      return error.response;
    }
  },
  getProducts(companyId) {
    try {
      return instance.get(`admin/product/${companyId}/all`);
    } catch (error) {
      return error.response;
    }
  },
  updateProduct(body) {
    try {
      return instance.patch(`admin/product`, body);
    } catch (error) {
      return error.response;
    }
  },
  deleteProduct(id) {
    try {
      return instance.delete(`admin/product/${id}`);
    } catch (error) {
      return error.response;
    }
  },
};

export const baggageSettingsService = {
  createBaggage(body) {
    try {
      return instance.post("admin/baggage-type", body);
    } catch (error) {
      return error.response;
    }
  },
  getBaggage(companyId) {
    try {
      return instance.get(`admin/baggage-type/${companyId}/all`);
    } catch (error) {
      return error.response;
    }
  },
  updateBaggage(body) {
    try {
      return instance.patch(`admin/baggage-type`, body);
    } catch (error) {
      return error.response;
    }
  },
  deleteBaggage(id) {
    try {
      return instance.delete(`admin/baggage-type/${id}`);
    } catch (error) {
      return error.response;
    }
  },
};
