import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';

import Loader from '../../../components/Loader/Loader';
import { checkinchService } from "../../../api/apiManager";
import { ICheckinchView } from "../../../models/checkinch.model"
import { schemaManagerEdit } from '../../../common/schemaValidation';
import { login } from "../../../redux/features/userSlice";
import { dataGridService } from "../../../services/dataGridService";
import { setView } from '../../../redux/features/isDeleteSlice';
import PromptCancel from '../../../components/PromptCancel/PromptCancel';

import TEXT from '../../../assets/text.json'
import styles from './CheckinchEditGrid.module.scss'

const CheckinchEditGrid = () => {
    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaManagerEdit),
    });
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const checkinch = useSelector((store: any) => store.checkinchPage.checkinch)
    const [loading, setLoading] = useState<boolean>(false);
    const [info] = useState<ICheckinchView>();
    const [user, setUser] = useState<any>();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const openPrompt = () => {
        dispatch(setView(true))
    }

    const getCheckinchSelf = async () => {
        let result = await checkinchService.getCheckinchSelf()
        setUser(result?.data)
        setLoading(false)
    }

    useEffect(() => {
        getCheckinchSelf()

    }, [info]);

    const onSubmit = async (data: any) => {

        const parsedData = {...data, ['id']: user.id}

        console.log(parsedData)
        dataGridService.removeEmptyFields(parsedData)

        setLoading(true)
        await checkinchService.updateCheckinch(parsedData).then((res) => {
            if (res?.status === 200) {
                toast.success(TEXT.NOTIFY_ACCOUNT_EDIT);
                dispatch(login(res.data))
                navigate(-1)
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })

    };

    if (!Object.keys(checkinch).length) {
        navigate(`/checkinch`)
    }

    if (loading) return <Loader/>

    return (
        <>
            <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                <h3>Edit Checkinch</h3>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Main info</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField
                                        label="Worker ID" {...field} className="materialUIInput"/>
                                </div>}
                                name="workerId"
                                control={control}
                                defaultValue={checkinch?.workerId}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField label="First name *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.firstname?.message}</small>
                                </div>}
                                name="firstname"
                                control={control}
                                defaultValue={checkinch?.firstname}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField label="Last name  *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.lastname?.message}</small>
                                </div>

                                }
                                name="lastname"
                                control={control}
                                defaultValue={checkinch?.lastname}
                            />
                            <Controller
                                render={({field}) => <TextField rows={4}
                                                                multiline label="Comments" {...field}
                                                                className="materialUIInput"/>

                                }
                                name="comments"
                                control={control}
                                defaultValue={checkinch?.comments}
                            />
                        </div>
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Contact details</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField
                                        onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                                        label="Phone number *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.phoneNumber?.message}</small>
                                </div>
                                }
                                name="phoneNumber"
                                control={control}
                                defaultValue={checkinch?.phoneNumber}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField onKeyDown={(event) => dataGridService.onlyNumberRegExp(event)}
                                               label="Email *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.username?.message}</small>
                                </div>
                                }
                                name="username"
                                control={control}
                                defaultValue={checkinch?.username}
                            />
                            <Controller
                                render={({field}) => <TextField label="Manager role" {...field}
                                                                className="materialUIInput"/>}
                                name="managerRoleName"
                                control={control}
                                defaultValue={checkinch?.managerRoleName}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Password *"
                                    placeholder="******" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.password?.message}</small></div>}
                                name="password"
                                control={control}
                                defaultValue={null}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Confirm password *" placeholder="******" {...field}
                                    className="materialUIInput"/>
                                    <small className={styles.error}>{errors.passwordConfirmation?.message}</small>
                                </div>}
                                name="passwordConfirmation"
                                control={control}
                                defaultValue={null}
                            />
                        </div>
                    </div>
                </Box>
                <Button
                    className={styles.button}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit">
                    Submit
                </Button>

                <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => openPrompt()}>
                    Cancel
                </Button>
            </form>
            {isView ? <PromptCancel/> : ''}
        </>
    );
}

export default CheckinchEditGrid;
