import {configureStore} from "@reduxjs/toolkit";

import userReducer from "../features/userSlice"
import checkinchReducer from "../features/checkinchSlice"
import handlingManagerReducer from "../features/handlingManagerSlice"
import airlineManagerReducer from "../features/airlineManagerSlice"
import isLoadingReducer from "../features/isLoadingSlice"
import isDeleteReducer from "../features/isDeleteSlice"
import isLinkedReducer from "../features/isLinkedSlice"
import isUpdateFlagReducer from "../features/isUpdateFlag"

export default configureStore({
    reducer: {
        user: userReducer,
        checkinchPage: checkinchReducer,
        handlingManagerPage: handlingManagerReducer,
        airlineManagerPage: airlineManagerReducer,
        isLoading: isLoadingReducer,
        isDelete: isDeleteReducer,
        isLinked: isLinkedReducer,
        isUpdateFlag: isUpdateFlagReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})
