import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Checkbox, Divider, FormLabel, MenuItem } from '@mui/material';

import Loader from '../Loader/Loader';
import { checkinchService } from "../../api/apiManager";
import { schemaManager } from '../../common/schemaValidation';
import { dataGridService } from "../../services/dataGridService";
import { setView } from '../../redux/features/isDeleteSlice';
import PromptCancel from '../PromptCancel/PromptCancel'

import TEXT from '../../assets/text.json'
import styles from './AirManagerCreateGrid.module.scss'

const AirManagerCreateGrid = () => {
    const dispatch = useDispatch()
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaManager),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any[]>([]);
    const navigate = useNavigate()
    const openPrompt = () => {
        dispatch(setView(true))
    }

    const onSubmit = async (data: any) => {
        const parsedData = {...data}
        dataGridService.removeEmptyFields(parsedData)

        setLoading(true)
        await checkinchService.createAirlineManager(parsedData).then((res) => {
            if (res.status === 201) {
                toast.success(TEXT.NOTIFY_AIRLINEMANAGER_CREATE);
                navigate('/airlinemanagers')
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })
    };


    useEffect(() => {
        const getCompanies = async () => {
            let result = await checkinchService.getAirlineCompanies()
            setCompanies(result?.data);
        }
        getCompanies()
    }, []);

    if (loading) return <Loader/>

    return (
        <>
            <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                <h3>Create Airline Company Manager</h3>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Main info</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Worker ID" {...field} className="materialUIInput"/>
                                    </div>}
                                name="workerId"
                                control={control}
                                defaultValue={""}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="First name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.firstname?.message}</small>
                                    </div>}
                                name="firstname"
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Last name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.lastname?.message}</small>
                                    </div>}
                                name="lastname"
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                render={({field}) => <TextField rows={4}
                                                                multiline label="Comments" {...field}
                                                                className="materialUIInput"/>}
                                name="comments"
                                control={control}
                                defaultValue=""
                            />
                            <div className={styles.checkBox}>
                                <FormLabel component="legend">Status</FormLabel>
                                <Controller
                                    control={control}
                                    name="isActive"
                                    render={({field: {onChange, value, ref}}) => (
                                        <Checkbox
                                            onChange={onChange}
                                            checked={value}
                                            inputRef={ref}
                                        />
                                    )}
                                    defaultValue={true}
                                />
                                <span>Active</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Company info</Divider>
                        <Controller
                            defaultValue={""}
                            render={({field}) => (
                                <div className={styles.wrap_witherror}>
                                    <TextField
                                        select
                                        label="Company *"
                                        defaultValue=""
                                        {...field}
                                    >
                                        {companies.map((company) => (
                                            <MenuItem key={company.id} value={company.id}>
                                                {company.companyName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <small className={styles.error}>{errors.company?.message}</small>
                                </div>
                            )}
                            name="company"
                            control={control}
                        />
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Contact details</Divider>
                        <div className={styles.wrap_section}>

                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                                    label="Mobile phone number *"
                                    {...field}
                                    className="materialUIInput"/><small
                                    className={styles.error}>{errors.phoneNumber?.message}</small></div>}
                                name="phoneNumber"
                                control={control}
                                defaultValue=""

                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    label="Email address *" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.username?.message}</small></div>}
                                name="username"
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Manager role" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.managerRoleName?.message}</small></div>}
                                name="managerRoleName"
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    label="Password *" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.password?.message}</small></div>}
                                name="password"
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    label="Confirm password *" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.passwordConfirmation?.message}</small></div>}
                                name="passwordConfirmation"
                                control={control}
                                defaultValue=""
                            />
                        </div>
                    </div>
                </Box>
                <Button
                    className={styles.button}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit">
                    Submit
                </Button>

                <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => openPrompt()}>
                    Cancel
                </Button>
            </form>
            {isView ? <PromptCancel/> : ''}
        </>
    );
}

export default AirManagerCreateGrid;
