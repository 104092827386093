import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel } from "@mui/material";

import { authAPI, checkinchService } from "../../api/apiManager";
import { login } from "../../redux/features/userSlice";

import styles from './CheckinchDropdown.module.scss'
import { setLoading } from "../../redux/features/isLoadingSlice";

export default function CheckinchDropdown() {

    const [manager, setManager] = React.useState("");
    const [managers, setManagers] = React.useState<any[]>([]);
    const airlineManagersList = useSelector((state: any) => state.checkinchPage.airlineCompanyManagers);
    const isCheckinch = useSelector((state: any) => state.user.isCheckinch);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const loginAsAirlineCompanyManager = async (id: number) => {
        dispatch(setLoading(true))
        await authAPI.loginAsAirlineManager(id)
        let user = localStorage.getItem('user')

        if (user) {
            dispatch(login(JSON.parse(user)))
            localStorage.setItem("isCheckinch", JSON.stringify(true))

        }
        dispatch(setLoading(false))
        navigate('/dashboard')


    }

    const handleChange = (event: SelectChangeEvent) => {
        setManager(event.target.value);
        console.log(manager, "manager")

        loginAsAirlineCompanyManager(Number(event.target.value))
        console.log(isCheckinch)
    };

    const getActiveAirlineCompanyManager = async () => {
        const result = await checkinchService.getActiveAirlineCompanies()
        setManagers(result?.data)
    }

    useEffect(() => {
        getActiveAirlineCompanyManager()

    }, [airlineManagersList])

    return (
        <div>
            <FormControl size="small" sx={{m: 1, minWidth: 270, fontSize: "14px"}}>
                <InputLabel id="demo-simple-select-helper-label">Airline company manager</InputLabel>

                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className={styles.select}
                    value={manager}
                    label="Select manager"
                    onChange={handleChange}
                    displayEmpty
                >

                    {managers?.map((elem) => {
                        return <MenuItem key={elem.id}
                                         value={elem.id}>{`${elem.firstname} ${elem.lastname} ( ${elem.companies[0]?.companyName || "No company"} )`}</MenuItem>
                    })}

                </Select>
            </FormControl>
        </div>
    );
}
