import React, { useCallback, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Checkbox, FormControlLabel, createFilterOptions } from "@mui/material";

import { airlineManagerService, productsService } from "../../../../../api/apiManager";
import { setUpdateFlag } from "../../../../../redux/features/isUpdateFlag";

import styles from "../../../ProductCard.module.scss";
import { debounce } from 'lodash';

const ModalForm = ({service, companyIdFromProps, close}: any) => {
    const [isCategories, setIsCategories] = useState([]);
    const [isCustomCategory, setCustomCategory] = useState<any | null>(service?.category?.title);

    const filter = createFilterOptions<any>();
    
    const {control, getValues} = useForm<any>({
        mode: 'onSubmit'
    });
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(service.isActive);
    const search = useLocation().search;
    const companyId = new URLSearchParams(search).get('id') || companyIdFromProps;
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleCategories = async (e: any) => {
        let inputText = e.target.value
        await airlineManagerService.getCategories(inputText).then((res) => {
            if(res?.data) {
                setIsCategories(res?.data)
            }
        })
    }

    const debounceHandleCategories = useCallback(debounce(handleCategories, 300), []);

    const handleClick = async (e: any) => {
        let dataArray: any = {
            id: parseInt("", 10),
            companyId: parseInt("", 10),
            name: '',
            price: parseInt("", 10),
            isActive: Boolean(),
            categoryTitle: isCustomCategory?.title || null
        }

        dataArray['companyId'] = +companyId! || companyIdFromProps;
        dataArray['id'] = +service.id!;
        dataArray['name'] = getValues("name");
        dataArray['price'] = +getValues("price");
        dataArray['isActive'] = checked;

        const dataJson = JSON.stringify(dataArray)

        try {
            let response = await productsService.updateProduct(dataJson)
            if (response?.status === 200) {
                toast.success("Product saved");
            } else {
                toast.error(response);

            }
        } catch (err) {
            console.error(err)
        } finally {
            dispatch(setUpdateFlag())
            close()
        }
    }

    return (
        <form className={styles.form_box}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& .MuiTextField-root': {m: 1, width: '200px'},
                }}
            >
                <div className={styles.product}>
                    <Controller
                        render={({field}) => <FormControlLabel control={<Checkbox checked={checked}
                                                                                  inputProps={{'aria-label': 'controlled'}} {...field}
                                                                                  onChange={handleCheckbox}/>}
                                                               label="Active"/>}
                        name="isActive"
                        control={control}
                    />
                    <Controller
                        render={({field}) => <TextField required label="Service Name" {...field}
                                                        className="materialUIInput"/>}
                        name="name"
                        control={control}
                        defaultValue={service?.name}
                    />
                     <Controller
                                render={({field: {ref, ...field}}) => <Autocomplete
                                    {...field}
                                    value={isCustomCategory}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setCustomCategory({title: newValue});
                                            field.onChange(newValue)
                                        } else if (newValue && newValue.inputValue) {
                                            setCustomCategory({title: newValue.inputValue});
                                            field.onChange(newValue?.inputValue)
                                        } else {
                                            setCustomCategory(newValue);
                                            field.onChange(newValue?.title)
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const {inputValue} = params;
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                title: `Add "${inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={isCategories}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.title;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                    sx={{display: 'inline-flex'}}
                                    freeSolo
                                    componentName='category'
                                    renderInput={(params) => (
                                        <div className={styles.wrap_witherror}>
                                            <TextField required inputRef={ref} {...params} label="Category tag"  onChange={debounceHandleCategories} />
                                        </div>
                                    )}
                                />}
                                name="category"
                                control={control}
                                defaultValue={isCustomCategory}
                            />
                    <Controller
                        render={({field}) => <TextField required label="Price" {...field}
                                                        type="number"
                                                        className="materialUIInput"/>}
                        name="price"
                        control={control}
                        defaultValue={service?.price}
                    />

                    <div className={styles.buttonWrapper}>
                        <Button
                            className={styles.button}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                handleClick(e)
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>

            </Box>
        </form>

    );
};

export default ModalForm;
