import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Formik } from "formik";
import Modal from "../modal/Modal";
import { authAPI } from "../../api/apiManager";
import { login } from "../../redux/features/userSlice";
import { setLoading } from "../../redux/features/isLoadingSlice";

import TEXT from '../../assets/text.json'
import styles from './LoginForm.module.scss'

const LoginForm = () => {
    let navigate = useNavigate();

    const [authError, setAuthError] = useState<boolean>(false);

    const dispatch = useDispatch()

    const onSubmit = async (values: any, {resetForm}: any) => {
        try {

            dispatch(setLoading(true))
            let response = await authAPI.login(values.username, values.password)

            if (response.user.role === 0 || response.user.role === 1) {
                dispatch(login(response.user))
                navigate('/accessdenied')
            } else {
                dispatch(login(response.user))
                navigate('/dashboard')
            }

        } catch (e) {
            setAuthError(true)
            resetForm({values: {username: values.username, password: ""}})
            console.log(e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <>

            <Formik
                initialValues={{username: "", password: "", authError}}
                onSubmit={onSubmit}

                validate={values => {

                    interface Error {
                        username?: string;
                        password?: string;
                    }

                    let errors: Error = {};

                    if (!values.username) {
                        errors.username = TEXT.LOGIN_USERNAME_REQUIRED;
                    }

                    if (!values.password) {
                        errors.password = TEXT.LOGIN_PASSWORD_REQUIRED;
                    }

                    return errors;
                }}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    } = props;

                    return (
                        <div className={styles.wrapper}>

                            <div className={styles.formWrapper}>

                                <form onSubmit={handleSubmit}>
                                    <>
                                        <div className={styles.label}>{TEXT.LOGIN_USERNAME}</div>
                                        <input
                                            id="email"
                                            name="username"
                                            type="text"
                                            placeholder={TEXT.LOGIN_EMAIL_PLACEHOLDER}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.username}
                                            className={(errors.username && touched.username) || authError ? styles.error : ""}
                                        />
                                        {errors.username && touched.username && !authError && (
                                            <div className={styles.inputFeedback}>{errors.username}</div>
                                        )}
                                        {(authError) && (
                                            <div className={styles.inputFeedback}>{TEXT.LOGIN_ERROR}</div>
                                        )}
                                    </>

                                    <div className={styles.label}>{TEXT.LOGIN_PASSWORD}</div>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter your password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        className={errors.password && touched.password ? styles.error : ""}
                                    />
                                    {errors.password && touched.password && (
                                        <div className={styles.inputFeedback}>{errors.password}</div>
                                    )}
                                    <div className={styles.forgotPassword}>
                                        <p>
                                            {TEXT.LOGIN_FORGOT_PASSWORD}&nbsp;
                                        </p>

                                        <Modal/>
                                    </div>

                                    <button className={styles.submit} type="submit">
                                        {TEXT.LOGIN}
                                    </button>

                                </form>

                            </div>
                        </div>
                    );
                }}
            </Formik>
        </>
    )
};

export default LoginForm;

