import * as React from 'react';
import { useState } from 'react';


import ColorToggleButton from "./ButtonGroup/ColorToggleButton";
import SelectFilter from "./SelectFilter/SelectFilter";
import { Button } from "@mui/material";

import addFilter from '../../../assets/filter-add.svg';
import triangle from '../../../assets/triangle.svg';
import styles from './FlightsFilterHandlingManager.module.scss';
import { handlingManagerService } from "../../../api/apiManager";
import { useDispatch, useSelector } from "react-redux";
import {
    setFlight,
    setFromDate,
    setFromDateDisabled,
    setToDate,
    setToDateDisabled
} from "../../../redux/features/handlingManagerSlice";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enAU } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


const FlightsFilterHandlingManager = () => {

    const dispatch = useDispatch()

    const [show, setShow] = useState<boolean>(false)
    const [value, setValue] = React.useState<Date | null>(null);

    let fromDate = useSelector((state: any) => state.handlingManagerPage.filter.fromDate)
    let toDate = useSelector((state: any) => state.handlingManagerPage.filter.toDate)
    let dataFromFilters = useSelector((state: any) => state.handlingManagerPage.filter)
    const handleSubmit = async () => {
        let result = await handlingManagerService.getFlightList(dataFromFilters);
        dispatch(setFlight(result?.data))
    }


    return (
        <div className={styles.filters}>

            <div onClick={() => setShow(!show)} className={styles.filterButton}>
                <div>
                    <img width={23} src={addFilter} alt="filters"/>
                </div>
                <div className={styles.filterText}>
                    <p>Common filters</p>
                </div>
                <div>
                    <img className={!show ? styles.rotate : ""} src={triangle} alt=""/>
                </div>
            </div>
            <div className={styles.filter + ' ' + (show ? styles.showFilter : styles.hideFilter)}>
                <ColorToggleButton setToDate={setToDate} setFromDate={setFromDate}/>
                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        mask="____-__-__"
                        label={"From Date"}
                        inputFormat={"yyyy-MM-dd"}
                        value={fromDate || null}
                        onError={(err) => {
                            if (err) {
                                dispatch(setFromDateDisabled(true))
                            } else {
                                dispatch(setFromDateDisabled(false))
                            }
                        }}

                        onChange={(newValue) => {
                            setValue(newValue);
                            dispatch(setFromDate(newValue?.toLocaleDateString('fr-CA')))

                        }}
                        renderInput={(params) => <TextField {...params} size="small" sx={{width: "200px"}}/>}
                    />
                    <DatePicker
                        mask="____-__-__"
                        label={"To Date"}
                        inputFormat={"yyyy-MM-dd"}
                        value={toDate || null}
                        clearable={true}

                        onError={(err) => {
                            if (err) {
                                dispatch(setToDateDisabled(true))
                            } else {
                                dispatch(setToDateDisabled(false))
                            }

                        }}

                        onChange={(newValue) => {

                            setValue(newValue);
                            console.log(newValue)
                            dispatch(setToDate(newValue?.toLocaleDateString('fr-CA')))

                        }}
                        renderInput={(params) => <TextField {...params} size="small" sx={{width: "200px"}}/>}
                    />
                </LocalizationProvider>

                <SelectFilter/>
                <Button disabled={dataFromFilters.fromDateDisabled || dataFromFilters.toDateDisabled}
                        onClick={handleSubmit} variant="contained">
                    Apply filters
                </Button>
            </div>

        </div>
    );
};

export default FlightsFilterHandlingManager;
