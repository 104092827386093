import FlightCreateGrid from "../../components/FlightCreateGrid/FlightCreateGrid";

import styles from "./Flights.module.scss";

const FlightCreate = () => {

    return (
        <div className={styles.data_grid}>
            <FlightCreateGrid/>
        </div>
    );
}

export default FlightCreate;
