import Popup from 'reactjs-popup';

import ModalForm from "./modalForm/ModalForm";

import styles from './Modal.module.scss'
import './overlay.css'

const Modal = () => {

    return (
        <Popup
            trigger={<a> Send Now </a>}
            modal
            nested
        >
            {(close: any) => (
                <div className={styles.modal}>
                    <button className={styles.close} onClick={close}>
                        &times;
                    </button>
                    <div>
                        <div className="content">
                            <ModalForm/>
                        </div>
                        <div className="actions">

                            <button
                                className={styles.button}
                                onClick={() => {
                                    close()
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default Modal;
