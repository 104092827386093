import {createSlice} from "@reduxjs/toolkit"

export const isUpdateFlagSlice = createSlice({
    name: "isUpdateFlag",
    initialState: {
        flag: false
    },
    reducers: {
        setUpdateFlag: (state) => {
            state.flag = !state.flag;
        },

    }
})

export const {setUpdateFlag} = isUpdateFlagSlice.actions;


export default isUpdateFlagSlice.reducer;
