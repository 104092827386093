import {createSlice} from "@reduxjs/toolkit"

export const isDeleteSlice = createSlice({
    name: "isDelete",
    initialState: {
        isDelete: false,
        isView: false,
        isCancel: false,
        isViewRequest: false,
    },
    reducers: {
        setDelete: (state, action) => {
            state.isDelete = action.payload;
        },
        setView: (state, action) => {
            state.isView = action.payload;
        },
        setViewRequest: (state, action) => {
            state.isViewRequest = action.payload;
        },
    }
})

export const {setDelete, setView, setViewRequest} = isDeleteSlice.actions;


export default isDeleteSlice.reducer;
