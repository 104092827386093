import FlightEditGrid from "../../components/FlightEditGrid/FlightEditGrid";

import styles from "./Flights.module.scss";

const FlightEdit = () => {

    return (
        <div className={styles.data_grid}>
            <FlightEditGrid/>
        </div>
    );
}

export default FlightEdit;
