import ShiftEditGrid from "../../../components/ShiftManagerEditGrid/ShiftEditGrid";

import styles from "./HandlingActions.module.scss";

const ShiftEdit = () => {

    return (
        <div className={styles.data_grid}>
            <ShiftEditGrid/>
        </div>
    );
}

export default ShiftEdit;
