import {createSlice} from "@reduxjs/toolkit"

export const isLoadingSlice = createSlice({
    name: "isLoading",
    initialState: {
        isLoading: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;

        },
    }
})

export const {setLoading} = isLoadingSlice.actions;


export default isLoadingSlice.reducer;
