import AirlineCompanyManagerEditGridSelf from "./AirlineCompanyManagerEditGridSelf";

import styles from "./AirlineCompanyManagerEditGridSelf.module.scss";

const AirlineCompanyManagerEditSelf = () => {

    return (
        <div className={styles.data_grid}>
            <AirlineCompanyManagerEditGridSelf/>
        </div>
    );
}

export default AirlineCompanyManagerEditSelf;
