import List from '@mui/material/List';

import NestedItem from "./NestedItem";
import NestedCollapseItem from "./NestedCollapseItem";

import TEXT from '../../assets/text.json'
import styles from './NestedList.module.scss'

function HandlingManagerList() {

    return (
        <List
            sx={{width: '100%', maxWidth: 340, flex: 8}}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <div className={styles.item}>
                <NestedItem text={TEXT.MENU_DASHBOARD} link="/dashboard"/>
            </div>

            <div className={styles.item}>
                <NestedItem text={TEXT.MENU_FLIGHTS} link="/flights"/>

            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text="Handling managers"
                    subText1="All handling managers"
                    subText2="Add new Handling manager"
                    linkMain="/handlings"
                    linkCreate="/handling-create"
                />
            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text={TEXT.MENU_SHIFT_MANAGERS}
                    subText1={TEXT.MENU_ALL_SHIFT_MANAGER}
                    subText2="Add new Shift manager"
                    linkMain="/shift-managers"
                    linkCreate="/shift-manager-create"
                />
            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text={TEXT.MENU_STEWARDS}
                    subText1={TEXT.MENU_ALL_STEWARDS}
                    subText2={TEXT.MENU_ADD_NEW}
                    linkMain="/stewards"
                    linkCreate="/steward-create"
                />
            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text={TEXT.MENU_SUPPORT}
                    subText1={TEXT.MENU_CONTACT}
                    subText2={TEXT.MENU_REQUEST_STICKERS}
                    linkMain="/support"
                    linkCreate="/sticker-request"
                />
            </div>
        </List>
    );
}

export default HandlingManagerList;
