import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormLabel,
  IconButton,
  ListItem,
  ListItemButton,
  MenuItem,
} from "@mui/material";

import Loader from "../Loader/Loader";
import { checkinchService } from "../../api/apiManager";
import { schemaAirlineCompany } from "../../common/schemaValidation";
import { timeZones } from "../../common/timeZones";
import { dataGridService } from "../../services/dataGridService";
import { setView } from "../../redux/features/isDeleteSlice";
import PromptCancel from "../PromptCancel/PromptCancel";

import styles from "./AirlineCreateGrid.module.scss";

const AirManagerCreateGrid = () => {
  const isView = useSelector((store: any) => store?.isDelete.isView);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<any>({
    mode: "onSubmit",
    resolver: yupResolver(schemaAirlineCompany),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [logoLink, setLogoLink] = useState<string>("");
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [valueCountry, setValueCountry] = useState<any | null>(null);
  const [valueCity, setValueCity] = useState<any | null>(null);
  const [disabledLegacy, setDisabledLegacy] = useState<boolean>(false);
  const [showRangedFields, setShowRangedFields] = useState(false);
  const [currencies, setCurrencies] = useState<[]>([]);

  const [showOverWeightFields, setShowOverWeightFields] = useState(false);

  const [baggageRanges, setBaggageRanges] = useState<any>([]);

  const [carryOnRanges, setCarryOnRanges] = useState<any>([]);

  const [luggageRanges, setLuggageRanges] = useState<any>([]);

  const checkOverlap = (start: number, end: number, intervals: any[]) => {
    for (let interval of intervals) {
      if (
        (start >= interval.minRangeWeight && start < interval.maxRangeWeight) ||
        (end > interval.minRangeWeight && end < interval.maxRangeWeight) ||
        (start <= interval.minRangeWeight && end >= interval.maxRangeWeight)
      ) {
        return true;
      }
    }
    return false;
  };

  const onAddLuggageRange = () => {
    const newMinRangeWeight = Number(watch("minRangeWeight"));
    const newMaxRangeWeight = Number(watch("maxRangeWeight")) || +Infinity;

    if (!watch("luggageType")) {
      toast.error("Choose luggage type");
      return;
    }

    if (!watch("minRangeWeight") || !watch("price")) {
      toast.error("You should add min/price");
      return;
    }

    if (newMinRangeWeight >= newMaxRangeWeight && newMaxRangeWeight) {
      toast.error("Min range must be less then max range");
      return;
    }

    if (watch("luggageType") === "Baggage") {
      const hasMaxRangeWeight = baggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, baggageRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setBaggageRanges((prev: any) => [
        ...prev,
        {
          type: "Baggage",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
        },
      ]);
    }

    if (watch("luggageType") === "CarryOnBag") {
      const hasMaxRangeWeight = carryOnRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, carryOnRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setCarryOnRanges((prev: any) => [
        ...prev,
        {
          type: "CarryOnBag",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
        },
      ]);
    }
    if (watch("luggageType") === "Luggage") {
      const hasMaxRangeWeight = luggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, luggageRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setLuggageRanges((prev: any) => [
        ...prev,
        {
          type: "Luggage",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
        },
      ]);
    }
  };

  const onDeleteRange = (indexToDelete: number, luggageType: string) => {
    if (luggageType === "CarryOnBag") {
      setCarryOnRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== indexToDelete
        );
        return updatedRanges;
      });
    }
    if (luggageType === "Baggage") {
      setBaggageRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== indexToDelete
        );
        return updatedRanges;
      });
    }
    if (luggageType === "Luggage") {
      setLuggageRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== indexToDelete
        );
        return updatedRanges;
      });
    }
  };

  const navigate = useNavigate();

  const openPrompt = () => {
    dispatch(setView(true));
  };

  const uploadLogo = async (event: any) => {
    const formData = new FormData();
    if (event.target.files[0]) {
      setFileName("Loading..");
      let file = event.target.files[0];
      formData.append("file", file);
      let result = await checkinchService.uploadLogo(formData);
      setLogoLink(result?.data.url);
      if (result?.status === 201) {
        toast.success("Logo was uploaded");
        setFileName(event.target.files[0].name);
      } else {
        toast.warn("Logo wasn't uploaded");
      }
    }
    event.target.value = "";
  };

  const handleCountries = async (e: any) => {
    let inputText = e.target.value;
    await checkinchService
      .getCountriesCities(inputText, "country")
      .then((res) => {
        if (res?.data) {
          setCountries(res?.data);
        }
      });
  };

  const handleCities = async (e: any) => {
    let inputText = e.target.value;
    await checkinchService
      .getCountriesCities(inputText, "(cities)")
      .then((res) => {
        if (res?.data) {
          setCities(res?.data);
        }
      });
  };

  const debounceHandleCountries = useCallback(
    debounce(handleCountries, 300),
    []
  );
  const debounceHandleCities = useCallback(debounce(handleCities, 300), []);

  const onSubmit = async (data: any) => {
    const parsedData = {
      ...data,
      floor: +data.floor || undefined,
      checkinTime: +data.checkinTime || undefined,
      maxDepth: +data.maxDepth || undefined,
      maxHandbagWeight: +data.maxHandbagWeight,
      maxLength: +data.maxLength || undefined,
      maxWidth: +data.maxWidth || undefined,
      notifySteward: +data.notifySteward || undefined,
      paymentForBaggageOverweight: +data.paymentForBaggageOverweight,
      paymentForOversize: +data.paymentForOversize || undefined,
      logo: logoLink,
      companyType: +data.companyType,
    };

    if (!showRangedFields) {
      if (disabledLegacy) {
        parsedData.maxHandbagWeight = null;
        parsedData.maxCarryOnBagWeight = null;
      } else {
        parsedData.maxCarryOnWeight = null;
      }
    }

    if (!showOverWeightFields) {
      delete parsedData.maxCarryOnBagWeightForRanges;
      delete parsedData.maxHandLuggageWeightForRanges;
    }

    if (showRangedFields) {
      parsedData.maxHandbagWeight = null;
      parsedData.maxCarryOnBagWeight = null;
      parsedData.maxCarryOnWeight = null;
      parsedData.bagWeightRanges = !![
        ...baggageRanges,
        ...carryOnRanges,
        ...carryOnRanges,
      ].length
        ? [...baggageRanges, ...carryOnRanges, ...carryOnRanges]
        : null;
    }

    dataGridService.removeEmptyFields(parsedData);

    setLoading(true);
    await checkinchService
      .createAirlineCompany(parsedData)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Airline company was created successfully");
          navigate("/aircompany");
        } else {
          toast.error(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showRangedFields) {
      setValue("maxHandbagWeight", 0);
      setValue("maxCarryOnBagWeight", 0);
      setValue("maxCarryOnWeight", 0);
      setValue("paymentForBaggageOverweight", 0);
    } else {
      setValue("paymentForBaggageOverweight", "");
    }
  }, [setValue, showRangedFields, disabledLegacy]);

  useEffect(() => {
    if (!showOverWeightFields) {
      setValue("maxHandLuggageWeightForRanges", 0);
      setValue("maxCarryOnBagWeightForRanges", 0);
    } else {
      setValue("maxHandLuggageWeightForRanges", "");
      setValue("maxCarryOnBagWeightForRanges", "");
    }
  }, [setValue, showOverWeightFields]);

  useEffect(() => {
    if (!showRangedFields) {
      if (disabledLegacy) {
        setValue("maxHandbagWeight", 0);
        setValue("maxCarryOnBagWeight", 0);
        setValue("maxCarryOnWeight", "");
      } else {
        setValue("maxHandbagWeight", "");
        setValue("maxCarryOnBagWeight", "");
        setValue("maxCarryOnWeight", 0);
      }
    }
  }, [disabledLegacy, setValue, showRangedFields]);

  useEffect(() => {
    checkinchService.getCurrencies().then((res) => {
      setCurrencies(res?.data);
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
        <h3>Create Airline Company</h3>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& .MuiTextField-root": { m: 1, width: "275px" },
          }}
        >
          <div className={styles.section_input}>
            <Divider textAlign="left">Main info</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.companyName?.message}
                    </small>
                  </div>
                )}
                name="companyName"
                control={control}
                defaultValue=""
              />

              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company short name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.shortCompanyName?.message}
                    </small>
                  </div>
                )}
                name="shortCompanyName"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="BRN *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.legalEntityId?.message}
                    </small>
                  </div>
                )}
                name="legalEntityId"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={countries}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValueCountry(newValue);
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="country"
                          {...params}
                          label="Enter a country *"
                          onChange={debounceHandleCountries}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.country?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="country"
                control={control}
                defaultValue={null}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-timeZone"
                      select
                      label="Time zone *"
                      {...field}
                    >
                      {timeZones.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <small className={styles.error}>
                      {errors.timeZone?.message}
                    </small>
                  </div>
                )}
                name="timeZone"
                control={control}
                defaultValue=""
              />
              <div className={styles.upload_logo_wrapper}>
                <Button
                  className={styles.upload_button}
                  variant="outlined"
                  component="label"
                >
                  Upload logo
                  <input
                    accept="image/*"
                    onChange={uploadLogo}
                    type="file"
                    hidden
                  />
                </Button>
                <p>{fileName}</p>
              </div>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                      label="Company phone *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.phone?.message}
                    </small>
                  </div>
                )}
                name="phone"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company email *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.email?.message}
                    </small>
                  </div>
                )}
                name="email"
                control={control}
                defaultValue=""
              />
              <div className={styles.checkBox}>
                <FormLabel component="legend">Status</FormLabel>
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                  defaultValue={true}
                />
                <span>Active</span>
              </div>
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Address</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={cities}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValueCity(newValue);
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="city"
                          {...params}
                          label="Enter a city *"
                          onChange={debounceHandleCities}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.city?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="city"
                control={control}
                defaultValue={null}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Street *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.street?.message}
                    </small>
                  </div>
                )}
                name="street"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Building"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.building?.message}
                    </small>
                  </div>
                )}
                name="building"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.onlyNumberRegExp(event)
                      }
                      label="Floor"
                      {...field}
                      type="number"
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.floor?.message}
                    </small>
                  </div>
                )}
                name="floor"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Office number"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.officeNumber?.message}
                    </small>
                  </div>
                )}
                name="officeNumber"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Zip code"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.zipCode?.message}
                    </small>
                  </div>
                )}
                name="zipCode"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Contact</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="First name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personFirstname?.message}
                    </small>
                  </div>
                )}
                name="personFirstname"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Last name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personLastname?.message}
                    </small>
                  </div>
                )}
                name="personLastname"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                      label="Phone number *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personPhone?.message}
                    </small>
                  </div>
                )}
                name="personPhone"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Email *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personEmail?.message}
                    </small>
                  </div>
                )}
                name="personEmail"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Role"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personRole?.message}
                    </small>
                  </div>
                )}
                name="personRole"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Admin settings</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-companyType"
                      select
                      label="Airline company type *"
                      {...field}
                      onChange={(e) => {
                        setValue("companyType", e.target.value);
                        setDisabledLegacy(!Boolean(e.target.value));
                        disabledLegacy
                          ? setValue("maxHandbagWeight", 0)
                          : setValue("maxHandbagWeight", "");
                      }}
                    >
                      <MenuItem value={0}>Legacy</MenuItem>
                      <MenuItem value={1}>Low cost</MenuItem>
                    </TextField>
                    <small className={styles.error}>
                      {errors.companyType?.message}
                    </small>
                  </div>
                )}
                name="companyType"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Operational settings</Divider>
            <div className={styles.wrap_section}>
              <p className={styles.section_p}>Size settings</p>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage height *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxLength?.message}
                    </small>
                  </div>
                )}
                name="maxLength"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage width *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxWidth?.message}
                    </small>
                  </div>
                )}
                name="maxWidth"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage depth *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxDepth?.message}
                    </small>
                  </div>
                )}
                name="maxDepth"
                control={control}
                defaultValue=""
              />
              <p className={styles.section_p}>Weight settings carry on</p>
              {!disabledLegacy && !showRangedFields ? (
                <>
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Max carry on bag weight *"
                          {...field}
                          className="materialUIInput"
                        />
                        <small className={styles.error}>
                          {errors.maxCarryOnBagWeight?.message}
                        </small>
                      </div>
                    )}
                    name="maxCarryOnBagWeight"
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Max hand luggage weight *"
                          {...field}
                          className="materialUIInput"
                        />
                        <small className={styles.error}>
                          {errors.maxHandbagWeight?.message}
                        </small>
                      </div>
                    )}
                    name="maxHandbagWeight"
                    control={control}
                    defaultValue={disabledLegacy ? "" : 0}
                  />
                </>
              ) : (
                <>
                  {!showRangedFields && (
                    <Controller
                      render={({ field }) => (
                        <div className={styles.wrap_witherror}>
                          <TextField
                            onKeyDown={(event) =>
                              dataGridService.operationalSettingsRegExp(event)
                            }
                            label="Max carry on weight *"
                            {...field}
                            className="materialUIInput"
                          />
                          <small className={styles.error}>
                            {errors.maxCarryOnWeight?.message}
                          </small>
                        </div>
                      )}
                      name="maxCarryOnWeight"
                      control={control}
                      defaultValue=""
                    />
                  )}
                </>
              )}

              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      type="number"
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Check in time *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.checkinTime?.message}
                    </small>
                  </div>
                )}
                name="checkinTime"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Notify steward *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.notifySteward?.message}
                    </small>
                  </div>
                )}
                name="notifySteward"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Weight settings</Divider>
            <div className={styles.wrap_section}>
              <p className={styles.section_p}>Size settings</p>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      select
                      label="Weight calculation *"
                      defaultValue="single"
                      {...field}
                      onChange={(e) => {
                        setValue(
                          "calculatingMethodOfOverweight",
                          e.target.value
                        );
                        if (
                          e.target.value === "single-range" ||
                          e.target.value === "set-range"
                        ) {
                          setShowRangedFields(true);
                        } else {
                          setShowRangedFields(false);
                        }
                      }}
                    >
                      <MenuItem value="single">Single</MenuItem>
                      <MenuItem value="single-range">Single range</MenuItem>
                      <MenuItem value="set">Set</MenuItem>
                      <MenuItem value="set-range">Set range</MenuItem>
                    </TextField>
                  </div>
                )}
                defaultValue="single"
                name="calculatingMethodOfOverweight"
                control={control}
              />
              {showRangedFields && (
                <>
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          select
                          label="Weight type *"
                          {...field}
                          onChange={(e) => {
                            setValue("weightType", e.target.value);
                            if (e.target.value === "overweight-only") {
                              setShowOverWeightFields(true);
                            } else {
                              setShowOverWeightFields(false);
                              setValue("maxHandLuggageWeightForRanges", 0);
                              setValue("maxCarryOnBagWeightForRanges", 0);
                            }
                          }}
                        >
                          <MenuItem value="actual-weight">
                            Actual weight
                          </MenuItem>
                          <MenuItem value="overweight-only">
                            Overweight
                          </MenuItem>
                        </TextField>
                      </div>
                    )}
                    defaultValue=""
                    name="weightType"
                    control={control}
                  />
                  {showOverWeightFields && (
                    <>
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Max hand luggage weight for ranges *"
                              {...field}
                              className="materialUIInput"
                            />
                            <small className={styles.error}>
                              {errors.maxHandLuggageWeightForRanges?.message}
                            </small>
                          </div>
                        )}
                        name="maxHandLuggageWeightForRanges"
                        control={control}
                        defaultValue=""
                      />
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Max carry on bag weight for ranges *"
                              {...field}
                              className="materialUIInput"
                            />
                            <small className={styles.error}>
                              {errors.maxCarryOnBagWeightForRanges?.message}
                            </small>
                          </div>
                        )}
                        name="maxCarryOnBagWeightForRanges"
                        control={control}
                        defaultValue=""
                      />
                    </>
                  )}
                </>
              )}
              <div className={styles.section_input}>
                <div className={styles.wrap_section}>
                  {showRangedFields && (
                    <Controller
                      render={({ field }) => (
                        <div className={styles.wrap_witherror}>
                          <TextField select label="Luggage type *" {...field}>
                            <MenuItem value="Baggage">Baggage</MenuItem>
                            <MenuItem value="Luggage">Luggage</MenuItem>
                            <MenuItem value="CarryOnBag">Carry on bag</MenuItem>
                          </TextField>
                        </div>
                      )}
                      defaultValue=""
                      name="luggageType"
                      control={control}
                    />
                  )}
                </div>
              </div>
            </div>
            {showRangedFields && (
              <div className={styles.wrap_section}>
                <Box
                  alignItems="center"
                  sx={{
                    display: "flex",
                    "& .MuiTextField-root": { m: 1, width: "240px" },
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          type="number"
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Min weight range"
                          {...field}
                          className="materialUIInput"
                        />
                      </div>
                    )}
                    name="minRangeWeight"
                    control={control}
                    defaultValue=""
                  />
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          type="number"
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Max weight range"
                          {...field}
                          className="materialUIInput"
                        />
                      </div>
                    )}
                    name="maxRangeWeight"
                    control={control}
                    defaultValue=""
                  />
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          type="number"
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Price"
                          {...field}
                          className="materialUIInput"
                        />
                      </div>
                    )}
                    name="price"
                    control={control}
                    defaultValue=""
                  />
                  <Button
                    className={styles.addButton}
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={onAddLuggageRange}
                  >
                    Add
                  </Button>
                </Box>
                {!!baggageRanges.length && (
                  <div className={styles.section_input}>
                    <Divider style={{ marginBottom: "10px" }}>
                      Baggages ranges
                    </Divider>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        padding: "0 16px",
                      }}
                    >
                      <div>Min weight</div>
                      <div>Max weight</div>
                      <div style={{ width: "60px" }}>Price</div>
                    </div>
                    {baggageRanges?.map((item: any, index: number) => {
                      return (
                        <ListItem disablePadding>
                          <ListItemButton
                            role={undefined}
                            dense
                            style={{
                              justifyContent: "space-between",
                              height: "52px",
                            }}
                          >
                            <div>{item.minRangeWeight}</div>
                            <div>{item.maxRangeWeight}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {item.price}
                              <IconButton
                                onClick={(e) => {
                                  onDeleteRange(index, "Baggage");
                                }}
                                edge="end"
                                aria-label="comments"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </div>
                )}

                {!!luggageRanges.length && (
                  <div className={styles.section_input}>
                    <Divider style={{ marginBottom: "10px" }}>
                      Luggage ranges
                    </Divider>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        padding: "0 16px",
                      }}
                    >
                      <div>Min weight</div>
                      <div>Max weight</div>
                      <div style={{ width: "60px" }}>Price</div>
                    </div>
                    {luggageRanges?.map((item: any, index: number) => {
                      return (
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            role={undefined}
                            dense
                            style={{
                              justifyContent: "space-between",
                              height: "52px",
                            }}
                          >
                            <div>{item.minRangeWeight}</div>
                            <div>{item.maxRangeWeight}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {item.price}
                              <IconButton
                                onClick={(e) => {
                                  onDeleteRange(index, "Luggage");
                                }}
                                edge="end"
                                aria-label="comments"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </div>
                )}

                {!!carryOnRanges.length && (
                  <div className={styles.section_input}>
                    <Divider style={{ marginBottom: "10px" }}>
                      Carry on ranges
                    </Divider>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        padding: "0 16px",
                      }}
                    >
                      <div>Min weight</div>
                      <div>Max weight</div>
                      <div style={{ width: "60px" }}>Price</div>
                    </div>
                    {carryOnRanges?.map((item: any, index: number) => {
                      return (
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            role={undefined}
                            dense
                            style={{
                              justifyContent: "space-between",
                              height: "52px",
                            }}
                          >
                            <div>{item.minRangeWeight}</div>
                            <div>{item.maxRangeWeight}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {item.price}
                              <IconButton
                                onClick={(e) => {
                                  onDeleteRange(index, "CarryOnBag");
                                }}
                                edge="end"
                                aria-label="comments"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={styles.section_input}>
            <Divider textAlign="left">Payment settings</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Hand luggage payment for oversize *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.paymentForOversize?.message}
                    </small>
                  </div>
                )}
                name="paymentForOversize"
                control={control}
                defaultValue=""
              />
              {!showRangedFields && (
                <Controller
                  render={({ field }) => (
                    <div className={styles.wrap_witherror}>
                      <TextField
                        onKeyDown={(event) =>
                          dataGridService.operationalSettingsRegExp(event)
                        }
                        label="Payment for overweight *"
                        {...field}
                        className="materialUIInput"
                      />
                      <small className={styles.error}>
                        {errors.paymentForBaggageOverweight?.message}
                      </small>
                    </div>
                  )}
                  name="paymentForBaggageOverweight"
                  control={control}
                  defaultValue=""
                />
              )}

              <Controller
                render={({ field: { onChange } }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-currency"
                      name="currency"
                      label="Currency"
                      select
                      onChange={onChange}
                      defaultValue=""
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <small className={styles.error}>
                      {errors.currency?.message}
                    </small>
                  </div>
                )}
                name="currency"
                control={control}
                defaultValue=""
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Business ID *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.business_id?.message}
                    </small>
                  </div>
                )}
                name="business_id"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
        </Box>
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          size="large"
          type="submit"
        >
          Submit
        </Button>

        <Button
          className={styles.button}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => openPrompt()}
        >
          Cancel
        </Button>
      </form>
      {isView ? <PromptCancel /> : ""}
    </>
  );
};

export default AirManagerCreateGrid;
