import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";
import styles from './FlightInfoGrid.module.scss'

const FlightInfoGrid = (flight: any) => {
    return (
        <div className={styles.app}>
            <Table>
                <tbody>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">ID</TableCell>
                    <TableCell>{flight.flight?.id}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Flight unique ID</TableCell>
                    <TableCell>{flight.flight?.flightUniqueId}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Flight number</TableCell>
                    <TableCell>{flight.flight?.flightNumber}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Departure country</TableCell>
                    <TableCell>{flight.flight?.departureCountry}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Departure city</TableCell>
                    <TableCell>{flight.flight?.departureCity}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Departure Iata</TableCell>
                    <TableCell>{flight.flight?.departureIata}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Departure date and time</TableCell>
                    <TableCell>{dateParseToDate(flight.flight?.departureDateTime)} / {dateParseToTime(flight.flight?.departureDateTime)}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Arrival country</TableCell>
                    <TableCell>{flight.flight?.arrivalCountry}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Arrival city</TableCell>
                    <TableCell>{flight.flight?.arrivalCity}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Arrival Iata</TableCell>
                    <TableCell>{flight.flight?.arrivalIata}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Arrival date and time</TableCell>
                    <TableCell>{dateParseToDate(flight.flight?.arrivalDateTime)} / {dateParseToTime(flight.flight?.arrivalDateTime)}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Linked Stewards</TableCell>
                    <TableCell>{flight.flight?.users.length ? flight.flight?.users.map((user: any) => {
                        return <p key={user.id}>{user.firstname} {user.lastname}</p>
                    }) : <p>No stewards</p>}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Creation origin</TableCell>
                    <TableCell>{flight.flight?.creationOrigin}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Created at</TableCell>
                    <TableCell>{dateParseToDate(flight.flight?.created_at)} / {dateParseToTime(flight.flight?.created_at)}</TableCell>
                </TableRow>
                <TableRow className={styles.row_style}>
                    <TableCell variant="head">Updated at</TableCell>
                    <TableCell>{dateParseToDate(flight.flight?.updated_at)} / {dateParseToTime(flight.flight?.updated_at)}</TableCell>
                </TableRow>
                </tbody>
            </Table>
        </div>
    );
}

export default FlightInfoGrid;
