import { useState } from "react";

import { Formik } from "formik";
import * as EmailValidator from "email-validator"; // used when validating with a self-implemented approach
import ModalDone from "./modalDone/ModalDone";
import TEXT from "../../../assets/text.json"
import styles from './ModalForm.module.scss'
import { authAPI } from "../../../api/apiManager";

const ModalForm = () => {

    const [isSend, setIsSend] = useState(false)
    const [isWrong, setIsWrong] = useState(false)
    const [description, setDescription] = useState("")


    const handleSubmit = async (values: object) => {
        let result = await authAPI.resetPassword(values)
        if (result?.status === 200) {
            setIsSend(true)
            setDescription(result?.data)
        } else {
            setIsSend(true)
            setIsWrong(true)
            setDescription(result)
        }

    }

    return (
        <Formik
            initialValues={{username: ""}}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(values)
            }}
            validate={values => {

                interface Error {
                    username?: string;

                }

                let errors: Error = {};
                if (!values.username) {
                    errors.username = TEXT.LOGIN_USERNAME_REQUIRED;
                } else if (!EmailValidator.validate(values.username)) {
                    errors.username = TEXT.LOGIN_INVALID;
                }

                return errors;
            }}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;

                return (<>{isSend ? <ModalDone isWrong={isWrong} description={description}/> :
                        <div className={styles.wrapper}>
                            <div className={styles.formWrapper}>
                                <form onSubmit={handleSubmit}>

                                    <label htmlFor="username">{TEXT.LOGIN_USERNAME}</label>
                                    <>
                                        <input
                                            id="username"
                                            name="username"
                                            type="text"
                                            placeholder={TEXT.LOGIN_EMAIL_PLACEHOLDER}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.username && touched.username ? styles.error : ""}
                                        />
                                        {errors.username && touched.username && (
                                            <div className={styles.inputFeedback}>{errors.username}</div>
                                        )}
                                    </>

                                    <button className={styles.submit} disabled={isSubmitting} type="submit">
                                        {TEXT.LOGIN_SEND}
                                    </button>
                                </form>
                            </div>
                        </div>}</>
                );
            }}
        </Formik>
    )
}

export default ModalForm;
