import {useCallback, useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Checkbox, Divider, FormControl, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';


import Loader from '../Loader/Loader';
import {checkinchService, handlingManagerService} from "../../api/apiManager";
import { schemaEditManagersByHandling } from '../../common/schemaValidation';
import { dataGridService } from "../../services/dataGridService";
import { setView } from '../../redux/features/isDeleteSlice';
import PromptCancel from '../PromptCancel/PromptCancel';

import TEXT from '../../assets/text.json'
import styles from './ShiftEditGrid.module.scss'
import Autocomplete from "@mui/material/Autocomplete";
import {debounce} from "lodash";

const ShiftEditGrid = () => {
    const dispatch = useDispatch()
    const isView = useSelector((store: any) => store?.isDelete?.isView)
    const shift = useSelector((store: any) => store?.handlingManagerPage?.shiftManager)
    const {control, handleSubmit, setValue, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaEditManagersByHandling),
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [airlineCompanies, setAirlineCompanies] = useState<any[]>([]);
    const [checked, setChecked] = useState(false);
    const [countries, setCountries] = useState<any>([]);
    const [valueCountry, setValueCountry] = useState<any | null>(null);

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');

    const navigate = useNavigate()

    const openPrompt = () => {
        dispatch(setView(true))
    }

    const getAirlineCompanies = async () => {
        setLoading(true)
        await handlingManagerService.getLinkedCompanies().then((res) => {
            setAirlineCompanies(res?.data);
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCountries = async (e: any) => {
        let inputText = e.target.value
        await checkinchService.getCountriesCities(inputText, "country").then((res) => {
            if(res?.data) {
                setCountries(res?.data)
            }
        })
    }


    const debounceHandleCountries = useCallback(debounce(handleCountries, 300), []);

    const getCompaniesId = () => {
        let companiesId: any[] = []
        shift.companies?.forEach((company: any) => {
            companiesId.push(company.id)
        })

        return companiesId
    }

    const getAllCompaniesId = () => {
        return airlineCompanies.map(company => company?.id);
    }

    useEffect(() => {
        getAirlineCompanies()
    }, []);

    const onSubmit = async (data: any) => {

        const parsedData = {
            ...data,
            ['id']: shift.id
        }

        setLoading(true)
        await handlingManagerService.updateShiftManager(parsedData).then((res) => {
            if (res.status === 200) {
                toast.success(TEXT.NOTIFY_SHIFT_EDIT);
                navigate("/shift-managers")
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    if (!Object.keys(shift).length) {
        navigate(`/shift-manager-view/${id}`)
    }

    if (loading) return <Loader/>

    return (
        <>
            <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                <h3>Edit Shift Manager</h3>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Main info</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Worker ID" {...field} className="materialUIInput"/>
                                    </div>}
                                name="workerId"
                                control={control}
                                defaultValue={shift.workerId}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="First name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.firstname?.message}</small>
                                    </div>}
                                name="firstname"
                                control={control}
                                defaultValue={shift.firstname}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Last name *" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.lastname?.message}</small>
                                    </div>}
                                name="lastname"
                                control={control}
                                defaultValue={shift.lastname}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField rows={4} multiline label="Comments" {...field}
                                                   className="materialUIInput"/>
                                        <small className={styles.error}>{errors.comments?.message}</small>
                                    </div>}
                                name="comments"
                                control={control}
                                defaultValue={shift.comments}
                            />
                            <div className={styles.checkBox}>
                                <FormLabel component="legend">Status</FormLabel>
                                <Controller
                                    control={control}
                                    name="isActive"
                                    render={({field: {onChange, value, ref}}) => (
                                        <Checkbox
                                            onChange={onChange}
                                            checked={value}
                                            inputRef={ref}
                                        />
                                    )}
                                    defaultValue={shift.isActive}
                                />
                                <span>Active</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Companies info</Divider>
                        <div className={styles.wrap_section}>
                            <div className={styles.wrap_witherror}>
                                <Controller
                                    render={({field: {ref, ...field}}) => <Autocomplete
                                        sx={{width: 292}}
                                        {...field}
                                        options={countries}
                                        autoHighlight
                                        onChange={(event, newValue) => {
                                            setValueCountry(newValue);
                                            field.onChange(newValue)
                                        }}
                                        getOptionLabel={(option: any) => option}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" {...props}>
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    name="country"
                                                    {...params}
                                                    label="Enter a country *"
                                                    onChange={debounceHandleCountries}
                                                    inputRef={ref}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                                <small className={styles.error}>{errors.country?.message}</small>
                                            </>
                                        )}
                                    />}
                                    name="country"
                                    control={control}
                                    defaultValue={shift.country}
                                />

                            </div>
                            <Controller
                                defaultValue={getCompaniesId()}
                                render={({field}) => (
                                    <div className={styles.wrap_witherror}>
                                        <FormControl sx={{m: 1, width: 275}}>
                                            <InputLabel id="selectLabel">Airline companies *</InputLabel>
                                            <Select
                                                disabled={checked}
                                                labelId="selectLabel"
                                                multiple
                                                label="Airline companies *"
                                                defaultValue={getCompaniesId()}
                                                {...field}
                                            >
                                                {airlineCompanies.map((company) => (
                                                    <MenuItem key={company.id} value={company.id}>
                                                        {company.companyName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <small className={styles.error}>{errors.companies?.message}</small>
                                    </div>
                                )}
                                name="companies"
                                control={control}
                            />
                            <div className={styles.checkBox}>
                                <FormLabel component="legend">Select all</FormLabel>
                                <Checkbox
                                    onChange={() => {
                                        if (!checked) {
                                            setValue('companies', getAllCompaniesId())
                                            setChecked(true)
                                        }
                                        if (checked) {
                                            setValue('companies', getCompaniesId())
                                            setChecked(false)
                                        }

                                    }}
                                />
                                <span>All</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section_input}>
                        <Divider textAlign="left">Contact details</Divider>
                        <div className={styles.wrap_section}>
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField
                                        onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                                        label="Phone number *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.phoneNumber?.message}</small>
                                </div>
                                }
                                name="phoneNumber"
                                control={control}
                                defaultValue={shift.phoneNumber}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}>
                                    <TextField label="Email *" {...field} className="materialUIInput"/>
                                    <small className={styles.error}>{errors.username?.message}</small>
                                </div>
                                }
                                name="username"
                                control={control}
                                defaultValue={shift.username}
                            />
                            <Controller
                                render={({field}) =>
                                    <div className={styles.wrap_witherror}>
                                        <TextField label="Manager role" {...field} className="materialUIInput"/>
                                        <small className={styles.error}>{errors.managerRoleName?.message}</small></div>}
                                name="managerRoleName"
                                control={control}
                                defaultValue={shift?.managerRoleName}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Password *"
                                    placeholder="******" {...field} className="materialUIInput"/><small
                                    className={styles.error}>{errors.password?.message}</small></div>}
                                name="password"
                                control={control}
                                defaultValue={undefined}
                            />
                            <Controller
                                render={({field}) => <div className={styles.wrap_witherror}><TextField
                                    helperText="Confirm password *" placeholder="******" {...field}
                                    className="materialUIInput"/><small
                                    className={styles.error}>{errors.passwordConfirmation?.message}</small></div>}
                                name="passwordConfirmation"
                                control={control}
                                defaultValue={undefined}
                            />
                        </div>
                    </div>
                </Box>
                <Button
                    className={styles.button}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit">
                    Submit
                </Button>

                <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => openPrompt()}>
                    Cancel
                </Button>
            </form>
            {isView ? <PromptCancel/> : ''}
        </>
    );
}

export default ShiftEditGrid;
