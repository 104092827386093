import { useNavigate } from "react-router-dom";
import styles from './NotFound.module.scss'

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.content}>
            <div className={styles.text_error}>
                404
            </div>
            <div className={styles.text_error}>
               <span>Page not found | </span>  <a onClick={() => {
                    navigate('/')
                }} className={styles.error_action}>Back to Home page </a>
            </div>
        </div>

    );
};

export default NotFound;
