import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    DataGrid,
    GridColDef,
    gridPageCountSelector,
    gridPageSelector,
    GridSelectionModel,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import { Button, Pagination, PaginationItem } from "@mui/material";

import { handlingManagerService } from "../../../api/apiManager";
import { dateParseToDate, dateParseToTime } from "../../../common/dateFormatter";
import PromptLinkSteward from "../../../components/PromptLinkSteward/PromptLinkSteward";

import TEXT from '../../../assets/text.json'
import styles from "../../StewardsActions/StewardActions.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        width: 65,
    },
    {
        field: 'workerId',
        headerName: 'Worker ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'firstname',
        headerName: 'First name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'lastname',
        headerName: 'Last name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'phoneNumber',
        headerName: 'Phone number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
    },
    {
        field: 'username',
        headerName: 'Email address',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: (params) => {
            return params.value ? params.value = "Active" : params.value = "Inactive"
        }
    },
    {
        field: 'companies',
        headerName: 'Airline company',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        valueFormatter: (params) => {
            let comps: any[] = []
            params.value.forEach((el: any) => {
                comps.push(el.companyName)
            })
            return comps
        }
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))

        },
    },
];

function CustomPagination() {

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            boundaryCount={2}
            siblingCount={0}
            page={page + 1}
            count={pageCount}
            renderItem={(props2: any) => <PaginationItem {...props2} />}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                apiRef.current.setPage(value - 1)}
            showFirstButton
            showLastButton
        />
    );
}

interface LocationState {
    flight: {
        users: []
        id: number
    }
}

export default function HandlingLinkStewards() {
    let location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false)
    const [stewards, setStewards] = useState([])
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const {flight} = location?.state as LocationState

    const assignedStewardIds = flight?.users?.map(({id}: any) => {
        return id
    }) || []

    const navigate = useNavigate()

    const openPrompt = () => {
        setShowPrompt(true)
    }

    const onLinkSteward = async () => {
        let body = {
            flightId: flight.id,
            ids: selectionModel
        }

        let response = await handlingManagerService.linkStewardsToFlight(body)
        if (response?.status === 200) {
            toast.success(TEXT.NOTIFY_STEWARD_LINK);
            navigate(-1)
        } else {
            toast.error("Something went wrong");
        }
    }

    useEffect(() => {
        if (!flight || Object.keys(flight).length === 0) {
            navigate('/flights')
        }
    }, [])

    useEffect(() => {
        const getStewards = async () => {
            const result = await handlingManagerService.getSteward()
            setStewards(result?.data)
            setSelectionModel(assignedStewardIds)
        };

        getStewards().catch((e) => {
            console.error(e)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleConfirmResult = async (confirmed?: boolean) => {
        setShowPrompt(false)
        if (confirmed) {
            await onLinkSteward()
        }
    }

    return (
        <>
            <div style={{width: "100%"}}>
                <DataGrid
                    pageSize={10}
                    components={{
                        Pagination: CustomPagination,
                    }}
                    checkboxSelection={true}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    columns={columns}
                    rows={stewards}
                    disableColumnMenu
                    autoHeight
                />
                <Button onClick={openPrompt} style={{marginTop: "15px"}} variant="contained">Submit</Button>
            </div>
            {showPrompt ? <PromptLinkSteward onResult={handleConfirmResult}/> : ''}
        </>
    );
}
