import AirManagerEditGrid from "../../components/AirManagerEditGrid/AirManagerEditGrid";

import styles from "./AirManagerActions.module.scss";

const AirManagerEdit = () => {

    return (
        <div className={styles.data_grid}>
            <AirManagerEditGrid/>
        </div>
    );
}

export default AirManagerEdit;
