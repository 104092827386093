import {createSlice} from "@reduxjs/toolkit"

export const isLinkedSlice = createSlice({
    name: "isLinked",
    initialState: {
        isLinked: false,
        isView: false,
    },
    reducers: {
        setLinked: (state, action) => {
            state.isLinked = action.payload;
        },
        setView: (state, action) => {
            state.isView = action.payload;
        },
    }
})

export const {setLinked, setView} = isLinkedSlice.actions;


export default isLinkedSlice.reducer;
