const environment = {
  baseUrl: "http://localhost:3000/",
};

if (process.env.REACT_APP_ENV === "sta") {
  environment.baseUrl = "http://52.204.183.116:3000/";
}

if (process.env.REACT_APP_ENV === "qa") {
  environment.baseUrl = "http://52.204.183.116:5000/";
}

if (process.env.REACT_APP_ENV === "prod") {
  environment.baseUrl = "https://www.checkinch.com/";
}

if (process.env.REACT_APP_ENV === "dev") {
  environment.baseUrl = "http://52.204.183.116:3000/";
}

export default environment;
