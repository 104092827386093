import AirManagerInfoGrid from "../../components/AirManagerInfoGrid/AirManagerInfoGrid";

import styles from "./AirManagerActions.module.scss";

const AirManagerSingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <AirManagerInfoGrid/>
            </div>
        </div>
    );
}

export default AirManagerSingleView;
