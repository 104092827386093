import { NavLink } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import styles from "./NestedList.module.scss";

const NestedItem = ({text, link}: any) => {

    return (
        <NavLink className={(navData) => (navData.isActive ? styles.activeLink : '')}

                 to={link}>
            <ListItemButton>

                <ListItemText primaryTypographyProps={{
                    fontWeight: 600,
                }} primary={text}/>

            </ListItemButton>
        </NavLink>
    )
}

export default NestedItem;
