import AirlineEditGrid from "../../components/AirlineEditGrid/AirlineEditGrid";

import styles from "./AirlineCompany.module.scss";

const AirlineCompanyEdit = () => {

    return (
        <div className={styles.data_grid}>
            <AirlineEditGrid/>
        </div>
    );
}

export default AirlineCompanyEdit;
