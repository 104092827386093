import { useSelector } from "react-redux";

import LoginForm from "../../components/LoginForm/LoginForm";

import logo from '../../assets/checkinch_logo.png'
import styles from './Login.module.sass'

const Login = () => {

    const isLoading = useSelector((store: any) => store.isLoading.isLoading)

    return (
        <div className={styles.container}>
            {/*{isLoading && <div className={styles.loaderWrapper}><Loader fixedLoader={true} /></div> }*/}
            <div className={styles.main}>
                <div className={styles.main_left}>

                    <LoginForm/>

                </div>
                <div className={styles.main_right}>
                    <img src={logo} alt="logo"/>
                </div>
            </div>
        </div>
    );
};

export default Login;
