import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from 'react-avatar';
import { logout } from "../../redux/features/userSlice";
import Header from "../../components/Header/Header";
import HandlingManagerList from "../../components/NestedLists/HandlingManagerList";
import PoweredBy from "../../common/PoweredBy/PoweredBy";
import { userService } from "../../services/userService";
import logoutImg from '../../assets/logout.png';
import styles from './HandlingManager.module.scss';

const HandlingManager = (props: any) => {
    const user = useSelector((state: any) => state.user)
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        userService.logout()
        dispatch(logout())
        navigate('/login')
    }

    return (
        <div>
            <Header></Header>
            <div className={styles.nestedList}>
                <div className={styles.nestedList_width}>
                    <div className={styles.profile}>
                        <div>

                            <NavLink to={"/handling-manager"}>
                                <div className={styles.avatar}>

                                    <Avatar
                                        name={user.user.firstname + " " + user.user.lastname}
                                        maxInitials={2}
                                        size="80"
                                        round={true}
                                        color={'#afafaf'}

                                    />
                                </div>
                            </NavLink>

                        </div>

                        <div>
                            <p>{user.user.firstname + " " + user.user.lastname}</p>
                            <p>Handling manager</p>
                        </div>
                        <div>
                            <a onClick={() => handleLogout()}>
                                <img src={logoutImg} alt="logout"/>
                            </a>
                        </div>
                    </div>
                    <HandlingManagerList/>
                    <div>
                        <PoweredBy/>
                    </div>
                </div>

                <div className={styles.content}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};

export default HandlingManager;
