import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import addFilter from '../../../assets/filter-add.svg';
import triangle from '../../../assets/triangle.svg';
import styles from './ReportsFilter.module.scss';
import { checkinchService } from "../../../api/apiManager";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enAU } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Controller, useForm } from "react-hook-form";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { addDays, endOfMonth, endOfYear, lastDayOfWeek, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaReports } from "../../schemaValidation";
import { debounce } from "lodash";
import { dataGridService } from "../../../services/dataGridService";
import {toast} from "react-toastify";

type DropdownItem = {
  title: string,
  value: string
}
const months: DropdownItem[] = [
    {
        title: "January",
        value: "01"
    },
    {
        title: "February",
        value: "02"
    },
    {
        title: "March",
        value: "03"
    },
    {
        title: "April",
        value: "04"
    },
    {
        title: "May",
        value: "05"
    },
    {
        title: "June",
        value: "06"
    },
    {
        title: "July",
        value: "07"
    },
    {
        title: "August",
        value: "08"
    },
    {
        title: "September",
        value: "09"
    },
    {
        title: "October",
        value: "10"
    },
    {
        title: "November",
        value: "11"
    },
    {
        title: "December",
        value: "12"
    },
]
const quarters: DropdownItem[] = [
    {
        title: "1st quarter",
        value: "01-01"
    },
    {
        title: "2nd quarter",
        value: "04-01"
    },
    {
        title: "3rd quarter",
        value: "07-01"
    },
    {
        title: "4th quarter",
        value: "10-01"
    },
]
const years: DropdownItem[] = [
    {
        title: "2022",
        value: "2022",
    },
    {
        title: "2023",
        value: "2023",
    },
    {
        title: "2024",
        value: "2024",
    },
    {
        title: "2025",
        value: "2025",
    },
    {
        title: "2026",
        value: "2026",
    }
]
const ReportsFilter = ({getReportsList}: any) => {

    const dispatch = useDispatch()
    const [countries, setCountries] = useState<any>([]);
    const [valueCountry, setValueCountry] = useState<any | null>(null);
    const [show, setShow] = useState<boolean>(false)
    const [сompanyNamePDF, setCompanyNamePDF] = useState<any>(null)
    const [companyPDFDate, setCompanyPDFDate] = useState<string>("2022-01-01");
    const {control, handleSubmit, setValue, getValues, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaReports),
    });
    const [data, setData] = useState<any>()
    const [toDate, setToDate] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [alignment, setAlignment] = useState<any>();
    const [reportName, setReportName] = useState<string>("Companies");
    const [period, setPeriod] = useState<string>("monthly");
    const [companies, setCompanies] = useState<any[]>([]);
    const [airlineCompanies, setAirlineCompanies] = useState<any[]>([]);

    const [error, setError] = useState<any>({
        fromDate: false,
        toDate: false
    });

    const downloadService = (response: any, name: string) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report-${name}-${new Date().toISOString().split('T')[0]}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    const downloadServiceAtPDF = (response: any, name: string) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report-${name}-${new Date().toISOString().split('T')[0]}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    const clearFilter = () => {
        setValue('airport', "")
        setValue('fromDate', null)
        setValue('toDate', null)
        setValue('country', "")
        setValue('companyName', "")
        setValue('handlingCompanyName', "")
        setAlignment('')
        setFromDate(null)
        setToDate(null)
        setData(null)

    }

    const handleCountries = async (e: any) => {
        let inputText = e.target.value
        await checkinchService.getCountriesCities(inputText, "country").then((res) => {
            if (res?.data) {
                setCountries(res?.data)
            }
        })
    }

    const renderDropdown = () => {
        return period === "monthly"
        ? <>
            <div className={styles.wrap_witherror} key="selectLabelM1">
                <FormControl sx={{ marginLeft: 1, marginRight: 0.5, width: 200 }} size="small">
                    <InputLabel id="selectLabelM1">Month *</InputLabel>
                    <Select
                        size='small'
                        labelId="selectLabelM1"
                        label="Month *"
                        defaultValue={"01"}
                        onChange={(event) => {
                            setCompanyPDFDate((prev) => {
                                const parts = prev.split("-");
                                parts[1] = event.target.value; 
                                return parts.join("-");
                            })
                        }}
                    >
                        {months.map((month) => (
                            <MenuItem key={month.value} value={month.value}>
                                {month.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={styles.wrap_witherror} key="selectLabelM2">
                <FormControl sx={{ marginLeft: 1, marginRight: 0.5, width: 200 }} size="small">
                    <InputLabel id="selectLabelM2">Year *</InputLabel>
                    <Select
                        size='small'
                        labelId="selectLabelM2"
                        label="Year *"
                        defaultValue={"2022"}
                        onChange={(event) => {
                            setCompanyPDFDate((prev) => {
                                const parts = prev.split("-");
                                parts[0] = event.target.value.toString(); 
                                return parts.join("-");
                            })
                        }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year.title} value={year.value}>
                                {year.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
        : period === "quarterly"
        ? <>
            <div className={styles.wrap_witherror} key="selectLabelQ1">
                <FormControl sx={{ marginLeft: 1, marginRight: 0.5, width: 200 }} size="small">
                    <InputLabel id="selectLabelQ1">Quarter *</InputLabel>
                    <Select
                        size='small'
                        labelId="selectLabelQ1"
                        label="Quarter *"
                        defaultValue={"01-01"}
                        onChange={(event) => {
                            setCompanyPDFDate((prev) => {
                                const parts = prev.split("-");
                                 return `${parts[0]}-${event.target.value}`;
                            })
                        }}
                    >
                        {quarters.map((quarter) => (
                            <MenuItem key={quarter.title} value={quarter.value}>
                                {quarter.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={styles.wrap_witherror} key="selectLabelQ2">
                <FormControl sx={{ marginLeft: 1, marginRight: 0.5, width: 200 }} size="small">
                    <InputLabel id="selectLabelQ2">Year *</InputLabel>
                    <Select
                        size='small'
                        labelId="selectLabelQ2"
                        label="Year *"
                        defaultValue={"2022"}
                        onChange={(event) => {
                            setCompanyPDFDate((prev) => {
                                const parts = prev.split("-");
                                parts[0] = event.target.value; 
                                return parts.join("-");
                            })
                        }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year.title} value={year.value}>
                                {year.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
        : <>
            <div className={styles.wrap_witherror} key="selectLabelY1">
                <FormControl sx={{ marginLeft: 1, marginRight: 0.5, width: 200 }} size="small">
                    <InputLabel id="selectLabelY1">Year *</InputLabel>
                    <Select
                        size='small'
                        labelId="selectLabelY1"
                        label="Year *"
                        defaultValue={"2022"}
                        onChange={(event) => {
                            setCompanyPDFDate(`${event.target.value}-01-01`);
                        }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year.title} value={year.value}>
                                {year.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
        
    }

    const debounceHandleCountries = useCallback(debounce(handleCountries, 300), []);


    const onSubmit = async (data: any) => {
        setData(data)
        dataGridService.removeEmptyFields(data)
        getReportsList(data)

    }


    const buttonStyle = {
        fontSize: "12px"
    }

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const getCompanies = async () => {
        await checkinchService.getCompanies().then((res) => {
            setCompanies(res?.data);
        })
    }

    const getAirlineCompanies = async () => {
        await checkinchService.getAirlineCompanies().then((res) => {
            setAirlineCompanies(res?.data);
        })
    }

    const getReportsListAtPDF = async () => {
        await checkinchService.getReportsListAtPDF({period, companyName: сompanyNamePDF, date: companyPDFDate}).then((response) => {
            downloadServiceAtPDF(response, period)
        }).catch((error) => {
            toast.error("Measurements not found");
        })
    }

    const handleClick = (today: string) => {
        let date = new Date()
        setError({...error, fromDate: false, toDate: false})
        switch (today) {
            case "today":
                setValue('fromDate', date.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(date, 1).toLocaleDateString('fr-CA'))
                setFromDate(date.toLocaleDateString('fr-CA'))
                setToDate(addDays(date, 1).toLocaleDateString('fr-CA'))
                break;
            case "week":
                const weekMonday = startOfWeek(date, {locale: enAU})
                const weekSunday = lastDayOfWeek(date, {locale: enAU})
                setValue('fromDate', weekMonday.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(weekSunday, 1).toLocaleDateString('fr-CA'))
                setFromDate(weekMonday.toLocaleDateString('fr-CA'))
                setToDate(addDays(weekSunday, 1).toLocaleDateString('fr-CA'))

                break;
            case "month":
                const monthStart = startOfMonth(date)
                const monthEnd = endOfMonth(date)
                setValue('fromDate', monthStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(monthEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(monthStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(monthEnd, 1).toLocaleDateString('fr-CA'))

                break;
            case "year":
                const yearStart = startOfYear(date)
                const yearEnd = endOfYear(date)
                setValue('fromDate', yearStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(yearEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(yearStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(yearEnd, 1).toLocaleDateString('fr-CA'))
                break;
        }

    };

    const onClickExportHandler = async (data: any) => {
        data = getValues() 
        if (!data?.fromDate && !data?.toDate) {
            let date = new Date()
            const monthStart = startOfMonth(date)
            const monthEnd = endOfMonth(date)
            data = {
                ...data,
                fromDate: monthStart.toLocaleDateString('fr-CA'),
                toDate: addDays(monthEnd, 1).toLocaleDateString('fr-CA')
            }
        }
        switch (reportName) {
            case "Companies":
                await checkinchService.getCompaniesReports(data).then((response) => {
                    downloadService(response, "Companies")
                });
                break;
            case "Periodic":
                await checkinchService.getPeriodicReports(data).then((response) => {
                    downloadService(response, "Periodic")
                });
                break;
        }
    }

    // const onClickPDFExportHandler = async (data: any) => {
    //     await checkinchService.getReportsListAtPDF(data).then((response) => {
    //         downloadServiceAtPDF(response.data)
    //     })
    // }

    useEffect(() => {
        getCompanies()
        getAirlineCompanies()
    }, []);


    return (
        <div className={styles.filters}>
            <div onClick={() => setShow(!show)} className={styles.filterButton}>
                <div>
                    <img width={23} src={addFilter} alt="filters"/>
                </div>
                <div className={styles.filterText}>
                    <p>Common filters</p>
                </div>
                <div>
                    <img className={!show ? styles.rotate : ""} src={triangle} alt=""/>
                </div>
            </div>
            <div className={styles.filter + ' ' + (show ? styles.showFilter : styles.hideFilter)}>

                <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& .MuiTextField-root': {m: 1, width: "200px"},
                        }}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            className={styles.buttonGroup}
                            onChange={handleAlignment}
                            size="small"
                        >
                            <ToggleButton style={buttonStyle} onClick={() => handleClick("today")}
                                          value="Today">Today</ToggleButton>
                            <ToggleButton style={buttonStyle} onClick={() => {
                                handleClick("week")
                            }} value="This Week">This Week</ToggleButton>
                            <ToggleButton style={buttonStyle} onClick={() => {
                                handleClick("month")
                            }} value="This month">This month</ToggleButton>
                            <ToggleButton style={buttonStyle}
                                          onClick={() => {
                                              handleClick("year")
                                          }} value="This Year">This Year</ToggleButton>
                        </ToggleButtonGroup>
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"From Date *"}
                                        inputFormat="yyyy-MM-dd"
                                        value={fromDate}
                                        onError={(err) => {

                                            if (err) {
                                                setError({...error, fromDate: true})
                                                console.log(error)
                                            } else {
                                                setError({...error, fromDate: false})
                                                console.log(error)
                                            }
                                        }}
                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                console.log(error)
                                                setFromDate("")
                                                field.onChange("")
                                            } else {
                                                console.log(error)
                                                setFromDate(newValue?.toLocaleDateString('fr-CA'))
                                                field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                            }
                                        }}
                                        renderInput={(params) => <div className={styles.wrap_witherror}>
                                            <TextField id="outlined-error" size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params}
                                                       error={error.fromDate || errors?.fromDate?.message}/>

                                        </div>}
                                    />
                                </LocalizationProvider>
                            }
                            name="fromDate"
                            control={control}
                            defaultValue={''}
                        />
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"To Date *"}
                                        inputFormat="yyyy-MM-dd"
                                        value={toDate}
                                        onError={(err) => {
                                            if (err) {
                                                setError({...error, toDate: true})
                                                console.log(error)
                                            } else {
                                                console.log(error)
                                                setError({...error, toDate: false})
                                            }
                                        }}

                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                setToDate("")
                                                field.onChange("")
                                            } else {
                                                setToDate(newValue?.toLocaleDateString('fr-CA'))
                                                field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                            }

                                        }}
                                        renderInput={(params) => <div className={styles.wrap_witherror}>
                                            <TextField size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params}
                                                       error={error.toDate || errors?.toDate?.message}/>

                                        </div>}
                                    />
                                </LocalizationProvider>
                            }
                            name="toDate"
                            control={control}
                            defaultValue={""}
                        />
                        <Controller
                            defaultValue=""
                            render={({field}) => (
                                <div className={styles.wrap_witherror}>
                                    <FormControl sx={{m: 1, width: 200}} size="small">
                                        <InputLabel id="selectLabel">Handling company *</InputLabel>
                                        <Select
                                            labelId="selectLabel"
                                            label="Handling company *"
                                            defaultValue=""
                                            {...field}
                                        >
                                            {companies.map((company, index) => (
                                                <MenuItem key={index} value={company}>
                                                    {company}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </div>
                            )}
                            name="handlingCompanyName"
                            control={control}
                        />


                        <Controller
                            defaultValue=""
                            render={({field}) => (
                                <div className={styles.wrap_witherror}>
                                    <FormControl sx={{m: 1, width: 200}} size="small">
                                        <InputLabel id="selectLabel">Airline company *</InputLabel>
                                        <Select
                                            labelId="selectLabel"
                                            label="Airline company *"
                                            defaultValue=""
                                            {...field}
                                        >
                                            {airlineCompanies.map((company) => (
                                                <MenuItem key={company.id} value={company.companyName}>
                                                    {company.companyName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </div>
                            )}
                            name="companyName"
                            control={control}
                        />
                        <Controller
                            render={({field: {ref, ...field}}) => <Autocomplete
                                {...field}
                                isOptionEqualToValue={(option, value) => option === value}
                                options={countries}
                                autoHighlight
                                onChange={(event, newValue) => {
                                    setValueCountry(newValue);
                                    field.onChange(newValue)
                                }}

                                getOptionLabel={(option: any) => option}
                                renderOption={(props, option: any) => (
                                    <Box component="li" {...props}>
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            name="country"
                                            {...params}
                                            size="small"
                                            label="Country"
                                            onChange={debounceHandleCountries}
                                            inputRef={ref}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    </>
                                )}
                            />}
                            name="country"
                            control={control}
                            defaultValue=""
                        />
                        <Controller
                            render={({field}) =>
                                <TextField {...field} size="small"
                                           id="outlined-basic" label="Airport"
                                           variant="outlined"/>
                            }
                            name="airport"
                            control={control}
                            defaultValue=""
                        />
                        <Controller
                            render={({field}) =>
                                <TextField {...field} size="small"
                                           id="outlined-basic" label="Flight number"
                                           variant="outlined"/>
                            }
                            name="flightNumber"
                            control={control}
                            defaultValue=""
                        />
                    </Box>
                </form>
                <div className={styles.buttonFormGroup}>
                    <Button style={{marginRight: "10px"}}
                            disabled={error.fromDate || error.toDate}
                            onClick={clearFilter} variant="contained">
                        Clear filters
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} disabled={error.fromDate || error.toDate}
                            variant="contained">
                        Apply filters
                    </Button>
                </div>
                <div className={styles.reportExport}>
                    <ToggleButtonGroup
                        color="primary"
                        value={reportName}
                        exclusive
                        size="small"
                    >
                        <ToggleButton style={buttonStyle} onClick={() => setReportName("Companies")}
                                      value="Companies">Companies</ToggleButton>
                        <ToggleButton style={buttonStyle} onClick={() => {
                            setReportName("Periodic")
                        }} value="Periodic">Periodic</ToggleButton>

                    </ToggleButtonGroup>

                    <Button style={{margin: "0px 5px 0px 10px"}} onClick={() => onClickExportHandler(data)}
                            disabled={error.fromDate || error.toDate}
                            variant="contained">
                        Download an Excel
                    </Button>

                </div>
            </div>

            <div className={styles.reportExport}>
                {
                    renderDropdown()
                }
                <ToggleButtonGroup
                    color="primary"
                    value={period}
                    exclusive
                    size="small"
                >
                    <ToggleButton style={buttonStyle} onClick={() => {
                        setCompanyPDFDate("2022-01-01");
                        setPeriod("monthly");
                    }}
                                  value="monthly">Monthly</ToggleButton>
                    <ToggleButton style={buttonStyle} onClick={() => {
                        setCompanyPDFDate("2022-01-01");
                        setPeriod("quarterly");
                    }} value="quarterly">Quarterly</ToggleButton>
                    <ToggleButton style={buttonStyle} onClick={() => {
                        setPeriod("yearly");
                        setCompanyPDFDate("2022-01-01");
                    }} value="yearly">Yearly</ToggleButton>

                </ToggleButtonGroup>

                        <div className={styles.wrap_witherror}>
                            <FormControl sx={{marginLeft: 1, marginRight: 0.5, width: 200}} size="small">
                                <InputLabel id="selectLabel">Airline company *</InputLabel>
                                <Select
                                    size='small'
                                    labelId="selectLabel"
                                    label="Airline company *"
                                    defaultValue={сompanyNamePDF}
                                    onChange={(event) => {
                                        setCompanyNamePDF(event.target.value)
                                    }}
                                >
                                    {airlineCompanies.map((company) => (
                                        <MenuItem key={company.id} value={company.companyName}>
                                            {company.companyName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>


                <Button disabled={сompanyNamePDF === null} style={{margin: "0px 0px 0px 5px"}} onClick={() => getReportsListAtPDF()}
                        variant="contained">
                    Download an PDF
                </Button>
            </div>


        </div>
    );
};

export default ReportsFilter;
