import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';

import { handlingManagerService } from "../../api/apiManager";
import { dateParseToDate, dateParseToTime } from '../../common/dateFormatter';
import { setDelete, setView } from '../../redux/features/isDeleteSlice';
import PromptDelete from '../PromptDelete/PromptDelete';
import { setSteward } from "../../redux/features/handlingManagerSlice";
import UserCard from "../UserCard/UserCard";
import { ISteward } from "../../models/steward.model";
import Loader from "../Loader/Loader";

import styles from './StewardInfoGrid.module.scss'

const StewardInfoGrid = () => {
    const navigate = useNavigate()
    const pathname = useLocation().pathname;
    const isDelete = useSelector((store: any) => store?.isDelete.isDelete)
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const steward = useSelector((store: any) => store?.handlingManagerPage?.steward)
    const dispatch = useDispatch()
    const [info, setInfo] = useState<ISteward>();
    const [loading, setLoading] = useState<boolean>(false);

    const id = pathname.split("/").pop();

    const getCurrentSteward = async (stewardId: any) => {
        setLoading(true)
        await handlingManagerService.getCurrentSteward(stewardId).then((res) => {
            setInfo(res?.data);
            dispatch(setSteward(res?.data))
        }).catch((err) => {
            if (err.response.status === 404) {
                //navigate('/404')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const openPrompt = () => {
        dispatch(setView(true))
    }

    const deleteSteward = async (userId: any) => {
        if (isDelete) {
            let response = await handlingManagerService.deleteSteward(userId)
            if (response?.status === 200) {
                toast.success("Steward was deleted successfully");
                navigate(-1)
                dispatch(setDelete(false))
            } else {
                toast.error("Something went wrong");
            }
        } else {
            dispatch(setView(false))
        }
    }

    useEffect(() => {
        getCurrentSteward(id)
    }, [])

    useEffect(() => {
        deleteSteward(id)
    }, [isDelete]);

    if (loading) return <Loader/>

    return (
        <>
            <div className={styles.app}>
                <Table>
                    <tbody>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">ID</TableCell>
                        <TableCell>{steward?.id}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Worker ID</TableCell>
                        <TableCell>{steward?.workerId}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">First name</TableCell>
                        <TableCell>{steward?.firstname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Last name</TableCell>
                        <TableCell>{steward?.lastname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Mobile phone number</TableCell>
                        <TableCell>{steward?.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Email address</TableCell>
                        <TableCell>{steward?.username}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Status</TableCell>
                        <TableCell>{steward?.isActive ? "Active" : "Inactive"}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Country</TableCell>
                        <TableCell>
                            {steward?.country}
                        </TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Airline companies</TableCell>
                        <TableCell>{steward?.companies?.length ? steward?.companies?.map((company: any) => {
                            return <p key={company.id}>{company.companyName}</p>
                        }) : <p>No company</p>}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Role</TableCell>
                        <TableCell>{(steward?.role === 0) ? "Steward" : ""}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Manager Role</TableCell>
                        <TableCell>{steward?.managerRoleName}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Comments</TableCell>
                        <TableCell>{steward?.comments}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Created at</TableCell>
                        <TableCell>{dateParseToDate(steward?.created_at)} / {dateParseToTime(steward?.created_at)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Updated at</TableCell>
                        <TableCell>{dateParseToDate(steward?.updated_at)} / {dateParseToTime(steward?.updated_at)}</TableCell>
                    </TableRow>
                    </tbody>
                </Table>
                <div className={styles.button}>
                    <NavLink to={`/steward-edit?id=${id}`}>
                        <Button variant="contained">Edit</Button>
                    </NavLink>
                    <Button
                        className={styles.delete}
                        color="error"
                        variant="outlined"
                        onClick={() => openPrompt()}>
                        Delete
                    </Button>
                </div>
            </div>
            <UserCard info={info}/>
            {isView ? <PromptDelete/> : ''}
        </>
    );
}

export default StewardInfoGrid;
