import * as yup from "yup";

const phoneNumberRegExp = /([\+ ]?)([\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{6,16}$/;
const emailRegExp =
  /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;

export const schemaAirlineCompany = yup.object().shape({
  companyName: yup.string().required(`Company name can't be blank`),

  shortCompanyName: yup.string().required(`Short company name can't be blank`),

  business_id: yup.string().required(`Business ID can't be blank`),

  legalEntityId: yup.string().required(`BRN can't be blank`),

  street: yup.string().required(`Street can't be blank`),

  personFirstname: yup.string().required(`Firstname can't be blank`),

  personLastname: yup.string().required(`Lastname can't be blank`),

  timeZone: yup.string().required(`Time zone can't be blank`),

  companyType: yup
    .number()
    .required(`Company type can't be blank`)
    .typeError(`Company type can't be blank`),

  country: yup.string().typeError(`Country can't be blank`),

  city: yup.string().typeError(`City can't be blank`),

  phone: yup
    .string()
    .required(`Phone can't be blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  email: yup
    .string()
    .required(`Company email can't be blank`)
    .matches(emailRegExp, "Invalid email format"),

  personPhone: yup
    .string()
    .required(`Phone can't be blank`)
    .matches(phoneNumberRegExp, "Invalid number format"),

  personEmail: yup
    .string()
    .required(`Email can't be blank`)
    .email("Invalid email format"),

  maxWidth: yup
    .number()
    .required(`Max hand luggage width can't be blank`)
    .typeError(`Max hand luggage width can't be blank`),

  maxDepth: yup
    .number()
    .required(`Max hand luggage depth can't be blank`)
    .typeError(`Max hand luggage depth can't be blank`),

  maxCarryOnBagWeightForRanges: yup
    .number()
    .required(`Max carry on bag weight can't be blank`)
    .typeError(`Max carry on bag weight can't be blank`),

  maxHandLuggageWeightForRanges: yup
    .number()
    .required(`Max hand luggage weight can't be blank`)
    .typeError(`Max hand luggage weight can't be blank`),

  maxHandbagWeight: yup
    .number()
    .required(`Max hand luggage weight can't be blank`)
    .typeError(`Max hand luggage weight can't be blank`),

  maxLength: yup
    .number()
    .required(`Max hand luggage height can't be blank`)
    .typeError(`Max hand luggage height can't be blank`),

  maxCarryOnWeight: yup
    .number()
    .required(`Max carry on weight can't be blank`)
    .typeError(`Max carry on weight can't be blank`),

  maxCarryOnBagWeight: yup
    .number()
    .required(`Max carry on bag weight can't be blank`)
    .typeError(`Max carry on bag weight can't be blank`),

  checkinTime: yup
    .number()
    .required(`Check in time can't be blank`)
    .typeError(`Check in time can't be blank`),

  notifySteward: yup
    .number()
    .required(`Notify Steward can't be blank`)
    .typeError(`Notify Steward can't be blank`),

  paymentForOversize: yup
    .number()
    .required(`Payment for oversize can't be blank`)
    .typeError(`Payment for oversize can't be blank`),

  paymentForBaggageOverweight: yup
    .number()
    .required(`Payment for overweight can't be blank`)
    .typeError(`Payment for overweight can't be blank`),

  currency: yup
    .string()
    .required(`Currency for overweight can't be blank`)
    .typeError(`Currency for overweight can't be blank`),
});

export const schemaAirlineManagerCompanyEdit = yup.object().shape({
  companyName: yup.string().required(`Company name can't be blank`),

  shortCompanyName: yup.string().required(`Short company name can't be blank`),

  business_id: yup.string().required(`Business ID can't be blank`),

  legalEntityId: yup.string().required(`BRN can't be blank`),

  street: yup.string().required(`Street can't be blank`),

  personFirstname: yup.string().required(`Firstname can't be blank`),

  personLastname: yup.string().required(`Lastname can't be blank`),

  timeZone: yup.string().required(`Time zone can't be blank`),

  country: yup.string().typeError(`Country can't be blank`),

  city: yup.string().typeError(`City can't be blank`),

  phone: yup
    .string()
    .required(`Phone can't be blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  email: yup
    .string()
    .required(`Company email can't be blank`)
    .matches(emailRegExp, "Invalid email format"),

  personPhone: yup
    .string()
    .required(`Phone can't be blank`)
    .matches(phoneNumberRegExp, "Invalid number format"),

  personEmail: yup
    .string()
    .required(`Email can't be blank`)
    .email("Invalid email format"),

  maxWidth: yup
    .number()
    .required(`Max hand luggage width can't be blank`)
    .typeError(`Max hand luggage width can't be blank`),

  maxDepth: yup
    .number()
    .required(`Max hand luggage depth can't be blank`)
    .typeError(`Max hand luggage depth can't be blank`),

  maxCarryOnBagWeightForRanges: yup
    .number()
    .required(`Max carry on bag weight can't be blank`)
    .typeError(`Max carry on bag weight can't be blank`),

  maxHandLuggageWeightForRanges: yup
    .number()
    .required(`Max hand luggage weight can't be blank`)
    .typeError(`Max hand luggage weight can't be blank`),

  maxHandbagWeight: yup
    .number()
    .required(`Max hand luggage weight can't be blank`)
    .typeError(`Max hand luggage weight can't be blank`),

  maxLength: yup
    .number()
    .required(`Max hand luggage height can't be blank`)
    .typeError(`Max hand luggage height can't be blank`),

  checkinTime: yup
    .number()
    .required(`Check in time can't be blank`)
    .typeError(`Check in time can't be blank`),

  notifySteward: yup
    .number()
    .required(`Notify Steward can't be blank`)
    .typeError(`Notify Steward can't be blank`),

  paymentForOversize: yup
    .number()
    .required(`Payment for oversize can't be blank`)
    .typeError(`Payment for oversize can't be blank`),

  paymentForBaggageOverweight: yup
    .number()
    .required(`Payment for overweight can't be blank`)
    .typeError(`Payment for overweight can't be blank`),

  maxCarryOnWeight: yup
    .number()
    .required(`Max carry on weight can't be blank`)
    .typeError(`Max carry on weight can't be blank`),

  maxCarryOnBagWeight: yup
    .number()
    .required(`Max carry on bag weight can't be blank`)
    .typeError(`Max carry on bag weight can't be blank`),
});

export const schemaManager = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  company: yup.string().required(`Company can't blank`),

  password: yup
    .string()
    .required(`Password can't blank`)
    .min(6, "Password should be 6 chars minimum.")
    .max(25, "Password should be 25 chars maximum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password"),

  username: yup
    .string()
    .required(`Username can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),
});

export const schemaManagerEdit = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Username can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),
});

export const schemaHandlingManagerByCheckinch = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),

  handlingCompanyName: yup
    .string()
    .required(`Company can't blank`)
    .typeError(`Company can't blank`),

  country: yup.string().typeError(`Country can't be blank`),
});

export const schemaEditHandlingManagerByCheckinch = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),

  handlingCompanyName: yup
    .string()
    .required(`Company can't blank`)
    .typeError(`Company can't blank`),

  country: yup
    .string()
    .required(`Country can't blank`)
    .typeError(`Country can't blank`),
});

export const schemaFlight = yup.object().shape({
  flightNumber: yup.string().required(`Flight number can't blank`),

  flightUniqueId: yup.string().required(`Flight unique ID can't blank`),

  departureCountry: yup
    .string()
    .required(`Departure country can't blank`)
    .typeError(`Departure country can't blank`),

  departureCity: yup
    .string()
    .required(`Departure city can't blank`)
    .typeError(`Departure city can't blank`),

  departureIata: yup.string().required(`Iata can't blank`),

  arrivalCountry: yup
    .string()
    .required(`Arrival country can't blank`)
    .typeError(`Arrival country can't blank`),

  arrivalCity: yup
    .string()
    .required(`Arrival city can't blank`)
    .typeError(`Arrival city can't blank`),

  arrivalIata: yup.string().required(`Iata can't blank`),

  departureDate: yup
    .string()
    .required(`Departure date can't blank`)
    .typeError(`Departure date can't blank`),

  arrivalDate: yup
    .string()
    .required(`Arrival date can't blank`)
    .typeError(`Arrival date can't blank`),

  arrivalTime: yup
    .string()
    .required(`Arrival date can't blank`)
    .typeError(`Arrival date can't blank`),

  departureTime: yup
    .string()
    .required(`Departure time can't blank`)
    .typeError(`Departure time can't blank`),
});

export const schemaManagersByHandling = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),
});

export const schemaEditManagersByHandling = yup.object().shape({
  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),

  country: yup
    .string()
    .required(`Country can't blank`)
    .typeError("Country can't blank"),
});

export const schemaSteward = yup.object().shape({
  workerId: yup
    .string()
    .required(`Worker Id can't blank`)
    .typeError(`Worker Id can't blank`),

  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),
});

export const schemaEditSteward = yup.object().shape({
  workerId: yup
    .string()
    .required(`Worker Id can't blank`)
    .typeError(`Worker Id can't blank`),

  firstname: yup.string().required(`First name can't blank`),

  lastname: yup.string().required(`Last name can't blank`),

  password: yup
    .string()
    .min(6, "Password should be 6 chars minimum.")
    .nullable()
    .max(25, "Password should be 25 chars maximum.")
    .nullable()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)/,
      "must be at least 6 character long including at least one letter and one digit"
    ),

  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must be equal to password")
    .nullable(true),

  username: yup
    .string()
    .required(`Email can't blank`)
    .matches(emailRegExp, "Invalid email format"),

  phoneNumber: yup
    .string()
    .required(`Phone number can't blank`)
    .typeError("Only numbers")
    .matches(phoneNumberRegExp, "Invalid number format"),

  companies: yup
    .array()
    .required(`Airline companies can't blank`)
    .min(1, `Airline companies can't blank`),

  country: yup
    .string()
    .required(`Country Id can't blank`)
    .typeError(`Country Id can't blank`),
});

export const schemaSubject = yup.object().shape({
  subject: yup.string().required(`Subject can't blank`),

  message: yup.string().required(`Message can't blank`),
});

export const schemaReports = yup.object().shape({
  fromDate: yup.string().required(true).min(1, true),

  toDate: yup.string().required(true).min(1, true),
});

export const schemaStickers = yup.object().shape({
  quantity: yup.string().required(`Quantity can't blank`),
});
