import {useEffect, useState} from "react";

import InfoCard from "../../../components/InfoCard/InfoCard";
import Loader from "../../../components/Loader/Loader";
import {airlineManagerService} from "../../../api/apiManager";
import FlightComponent from "../../../components/FlightComponent/FlightComponent";

import TEXT from "../../../assets/text.json"
import styles from "./Company.module.scss";

const CompanyDashboard = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [info, setInfo] = useState<any>();

    const getDashboard = async () => {
        let result = await airlineManagerService.dashboard()
        setInfo(result?.data)
        setLoading(false)
    }

    useEffect(() => {
        getDashboard()
    }, []);

    if (loading) return <Loader/>

    return (
        <>

            <div className={styles.flights_box}>
                <h3>{TEXT.DASHBOARD_FLIGHTS}</h3>
                <div className={styles.flights_cards}>
                    {(info.flights?.length)
                        ? info.flights.map((flight: any, index: number) => {
                            return <FlightComponent key={index} flight={flight}/>
                        })
                        : <p>No flights to today</p>}
                </div>
            </div>
            <div className={styles.dashboard_wrapper}>
                <div className={styles.dashboard_metrics}>
                    <InfoCard
                        title="Total Measurements"
                        metric1={String(info.measurement?.total || "0")}
                        metric1desc="Total"
                        metric2={String(info.measurement?.approved || "0")}
                        metric2desc="Approved"
                        metric3={String(info.measurement?.not_approved || "0")}
                        metric3desc="Not approved"
                    />
                    <div className={styles.info_card}>
                        <h3>Previous day</h3>
                        <div className={styles.metrics}>
                            <div className={styles.metrics_row}>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.measurement?.total || "0")}</h1>
                                    <p>Total</p>
                                </div>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.measurement?.approved || "0")}</h1>
                                    <p>Approved</p>
                                </div>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.measurement?.not_approved || "0")}</h1>
                                    <p>Not approved</p>
                                </div>
                            </div>
                            <div className={styles.metrics_row}>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.excessBaggage?.overWeightQuantity || "0")}</h1>
                                    <p>Overweight quantity</p>
                                </div>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.excessBaggage?.overWeightAmount || "0")}</h1>
                                    <p>Overweight amount</p>
                                </div>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.excessBaggage?.overSizeQuantity || "0")}</h1>
                                    <p>Oversize quantity</p>
                                </div>
                                <div className={styles.metric}>
                                    <h1>{String(info.yesterdayData?.excessBaggage?.overSizeAmount || "0")}</h1>
                                    <p>Oversize amount</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<InfoCard*/}
                    {/*    title="Previous day"*/}
                    {/*    metric1={String(info.yesterdayData?.measurement?.total || "0")}*/}
                    {/*    metric1desc="Total"*/}
                    {/*    metric2={String(info.yesterdayData?.measurement?.approved || "0")}*/}
                    {/*    metric2desc="Approved"*/}
                    {/*    metric3={String(info.yesterdayData?.measurement?.not_approved || "0")}*/}
                    {/*    metric3desc="Not approved"*/}
                    {/*    metric4={String(info.excessBaggage?.excessBaggage?.overWeightQuantity || "0")}*/}
                    {/*    metric4desc="Overweight quantity"*/}
                    {/*    metric5={String(info.excessBaggage?.excessBaggage?.overWeightAmount || "0")}*/}
                    {/*    metric5desc="Overweight amount"*/}
                    {/*    metric6={String(info.excessBaggage?.excessBaggage?.overSizeQuantity || "0")}*/}
                    {/*    metric6desc="Oversize quantity"*/}
                    {/*    metric7={String(info.excessBaggage?.excessBaggage?.overSizeAmount || "0")}*/}
                    {/*    metric7desc="Oversize amount"*/}
                    {/*/>*/}
                </div>
                <div>


                </div>
            </div>


        </>

    );
}

export default CompanyDashboard;
