import HandlingEditGrid from "../../../components/HandlingEditGrid/HandlingEditGrid";

import styles from "./HandlingActions.module.scss";

const HandlingEdit = () => {

    return (
        <div className={styles.data_grid}>
            <HandlingEditGrid/>
        </div>
    );
}

export default HandlingEdit;
