import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { auth, login, logout } from "./redux/features/userSlice";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Checkinch from "./pages/Checkinch/Checkinch";
import AirlineCompanyManager from "./pages/AirlineCompanyManager/AirlineCompanyManager";
import HandlingManager from "./pages/HandlingManager/HandlingManager";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import "./App.css";
import { base64service } from "./services/base64service";
import { userService } from "./services/userService";
import Loader from "./components/Loader/Loader";

import "react-toastify/dist/ReactToastify.min.css";
import NotFound from "./pages/NotFound/NotFound";
import CheckinchDashboard from "./components/CheckinchDashboard/CheckinchDashboard";
import AirlineCompanyList from "./pages/AirlineCompany/AirlineCompanyList";
import AirlineCompanySingleView from "./pages/AirlineCompany/AirlineCompanySingleView";
import AirlineCompanyCreate from "./pages/AirlineCompany/AirlineCompanyCreate";
import AirlineCompanyEdit from "./pages/AirlineCompany/AirlineCompanyEdit";
import HandlingListHM from "./pages/HandlingManager/Actions/HandlingList";
import HandlingListCM from "./pages/HandlingActions/HandlingList";
import ManagerSingleView from "./pages/HandlingActions/ManagerSingleView";
import UserEditGrid from "./components/UserEditGrid/UserEditGrid";
import UserCreateGrid from "./components/UserCreateGrid/UserCreateGrid";
import AirManagerList from "./pages/AirManagersActions/AirManagerList";
import AirManagerSingleView from "./pages/AirManagersActions/AirManagerSingleView";
import AirManagerCreate from "./pages/AirManagersActions/AirManagerCreate";
import AirManagerEdit from "./pages/AirManagersActions/AirManagerEdit";
import CheckinchSingleView from "./pages/Checkinch/Actions/CheckinchSingleView";
import CheckinchEdit from "./pages/Checkinch/Actions/CheckinchEdit";
import CheckinchReports from "./pages/Checkinch/Actions/CheckinchReports";
import CompanyDashboard from "./pages/AirlineCompanyManager/Actions/AirlineCompanyDashboard";
import CompanySingleView from "./pages/AirlineCompanyManager/Actions/CompanySingleView";
import CompanyEdit from "./pages/AirlineCompanyManager/Actions/CompanyEdit";
import CompanyFlightList from "./pages/AirlineCompanyManager/Actions/CompanyFlightList";
import FlightSingleViewAM from "../src/pages/Flights/FlightSingleView";
import FlightSingleViewHM from "../src/pages/HandlingManager/Actions/FlightSingleView";
import FlightEdit from "./pages/Flights/FlightEdit";
import FlightCreate from "./pages/Flights/FlightCreate";
import AirlineCompanyManagerSingleViewSelf from "./pages/AirlineCompanyManager/Actions/AirlineCompanyManagerSingleViewSelf";
import AirlineCompanyManagerEditSelf from "./pages/AirlineCompanyManager/Actions/AirlineCompanyManagerEditSelf";
import SupportPage from "./components/SupportPage/SupportPage";
import ReportsPage from "./pages/ReportsPage/ReportsPage";
import HandlingDashboard from "./pages/HandlingManager/Actions/HandlingDashboard";
import HandlingSingleView from "./pages/HandlingManager/Actions/HandlingSingleView";
import HandlingCreate from "./pages/HandlingManager/Actions/HandlingCreate";
import HandlingEdit from "./pages/HandlingManager/Actions/HandlingEdit";
import ShiftList from "./pages/HandlingManager/Actions/ShiftList";
import ShiftSingleView from "./pages/HandlingManager/Actions/ShiftSingleView";
import ShiftEdit from "./pages/HandlingManager/Actions/ShiftEdit";
import ShiftCreate from "./pages/HandlingManager/Actions/ShiftCreate";
import StewardSingleView from "./pages/StewardsActions/StewardSingleView";
import StewardList from "./pages/StewardsActions/StewardList";
import StewardEdit from "./pages/StewardsActions/StewardEdit";
import StewardCreateGrid from "./components/StewardCreateGrid/StewardCreateGrid";
import FlightList from "./pages/HandlingManager/Actions/FlightList";
import HandlingSingleViewSelf from "./pages/HandlingManager/Actions/HandlingSingleViewSelf";
import HandlingEditSelf from "./pages/HandlingManager/Actions/HandlingEditSelf";
import HandlingLinkStewards from "./pages/HandlingManager/Actions/HandlingLinkStewards";
import StickerRequestPage from "./components/StickerRequestPage/StickerRequestPage";

function App() {
  const userRole = useSelector((state: any) => state.user.user.role);
  const isAuth = useSelector((state: any) => state.user.isAuth);
  const isLoading = useSelector((state: any) => state.isLoading.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    let user = localStorage.getItem("user");
    if (token && user) {
      dispatch(auth());
      dispatch(login(JSON.parse(user)));
      let jwtPayload = base64service.jwtToBase64(token);
      console.log(jwtPayload);
      let expDataCompare = new Date() < new Date(jwtPayload.exp * 1000);
      console.log(1);
      if (isAuth) {
        navigate("/");
      }

      if (!expDataCompare) {
        dispatch(logout());
        userService.logout();
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [dispatch]);

  return (
    <div className="App">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgb(209 209 209 / 41%)",
            zIndex: "99999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader fixedLoader={true} />
        </div>
      )}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={"/login"} element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {(userRole === 0 || userRole === 1) && (
          <Route path="/accessdenied" element={<AccessDenied />} />
        )}
        {userRole === 4 && (
          <Route path="/" element={<Checkinch />}>
            <Route path="/" element={<CheckinchDashboard />} />
            <Route path="dashboard" element={<CheckinchDashboard />} />
            <Route path="aircompany" element={<AirlineCompanyList />} />
            <Route path="singleview/*" element={<AirlineCompanySingleView />} />
            <Route path="airline-create" element={<AirlineCompanyCreate />} />
            <Route path="airline-edit/" element={<AirlineCompanyEdit />} />
            <Route path="handlingmanagers" element={<HandlingListCM />} />
            <Route path="manager-view/:id" element={<ManagerSingleView />} />
            <Route path="manager-edit/*" element={<UserEditGrid />} />
            <Route path="manager-create" element={<UserCreateGrid />} />
            <Route path="airlinemanagers" element={<AirManagerList />} />
            <Route
              path="airmanager-view/:id"
              element={<AirManagerSingleView />}
            />
            <Route
              path="airlinemanager-create"
              element={<AirManagerCreate />}
            />
            <Route path="airlinemanager-edit/*" element={<AirManagerEdit />} />
            <Route path="checkinch" element={<CheckinchSingleView />} />
            <Route path="checkinch-edit" element={<CheckinchEdit />} />
            <Route path="reports" element={<CheckinchReports />} />
          </Route>
        )}
        {userRole === 3 && (
          <Route path="/" element={<AirlineCompanyManager />}>
            <Route path="/" element={<CompanyDashboard />} />
            <Route path="dashboard" element={<CompanyDashboard />} />
            <Route path="company" element={<CompanySingleView />} />
            <Route path="company-edit" element={<CompanyEdit />} />
            <Route path="flights" element={<CompanyFlightList />} />
            <Route path="flight-view/*" element={<FlightSingleViewAM />} />
            <Route path="flight-edit/*" element={<FlightEdit />} />
            <Route path="add-flight" element={<FlightCreate />} />
            <Route
              path="airline-company-manager"
              element={<AirlineCompanyManagerSingleViewSelf />}
            />
            <Route
              path="airline-company-manager-edit"
              element={<AirlineCompanyManagerEditSelf />}
            />
            <Route path="support" element={<SupportPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="sticker-request" element={<StickerRequestPage />} />
          </Route>
        )}
        {userRole === 2 && (
          <Route path="/" element={<HandlingManager />}>
            <Route path="/" element={<HandlingDashboard />} />
            <Route path="dashboard" element={<HandlingDashboard />} />
            <Route path="handlings" element={<HandlingListHM />} />
            <Route path="handling-view/*" element={<HandlingSingleView />} />
            <Route path="handling-create" element={<HandlingCreate />} />
            <Route path="handling-edit/*" element={<HandlingEdit />} />
            <Route path="shift-managers" element={<ShiftList />} />
            <Route path="shift-manager-view/*" element={<ShiftSingleView />} />
            <Route path="shift-manager-edit/*" element={<ShiftEdit />} />
            <Route path="shift-manager-create" element={<ShiftCreate />} />
            <Route path="steward-view/*" element={<StewardSingleView />} />
            <Route path="stewards" element={<StewardList />} />
            <Route path="steward-edit/*" element={<StewardEdit />} />
            <Route path="steward-create" element={<StewardCreateGrid />} />
            <Route path="flights" element={<FlightList />} />
            <Route path="flight-view/*" element={<FlightSingleViewHM />} />
            <Route
              path="handling-manager"
              element={<HandlingSingleViewSelf />}
            />
            <Route
              path="handling-manager-edit"
              element={<HandlingEditSelf />}
            />
            <Route
              path="link-stewards-to-flight/*"
              element={<HandlingLinkStewards />}
            />
            <Route path="support" element={<SupportPage />} />
            <Route path="sticker-request" element={<StickerRequestPage />} />
          </Route>
        )}
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
}

export default App;
