import { Link } from "react-router-dom";
import styles from './AccessDenied.module.scss'

const AccessDenied = () => {
    return (
        <div>
            <div className={styles.content}>
                <div className={styles.text_error}>
                    Access Denied
                </div>
                <div>
                    <Link className={styles.error_action} to={'/login'}>Return to Login</Link>
                </div>
            </div>

        </div>
    );
};

export default AccessDenied;
