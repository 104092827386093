import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";

import styles from "./WeightSettingsListView.module.scss";

const WeightSettingsView = ({service, value, currency}: any) => {
    return (
        <ListItem
            key={value}
            disablePadding
        >
            <ListItemButton role={undefined} dense>
                <div className={styles.baggage}>
                    <div>
                        <ListItemIcon>
                            <Checkbox checked={service?.isActive}
                                      inputProps={{'aria-label': 'controlled'}}
                            />
                        </ListItemIcon>
                    </div>
                    <div>
                        <p style={{overflowWrap: "anywhere", maxWidth: "520px"}}>{service?.baggageName}</p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                        <p style={{overflowWrap: "anywhere"}}>{service?.maxBaggageWeight + " Kg."}</p>
                    </div>
                </div>
            </ListItemButton>
        </ListItem>

    );
};

export default WeightSettingsView;
