import { getYear } from "date-fns";
import IPQ_LOGO from "../../assets/IPQ-logo.png";
import styles from "./PoweredBy.module.scss";

const PoweredBy = () => {
  return (
    <div className={styles.powered}>
      <div className={styles.poweredHeader}>
        <p>Powered by &nbsp;</p>
        <a target="_blank" href="https://www.ipqgroup.com/">
          <img className={styles.logo} src={IPQ_LOGO} alt="logo" />
        </a>
      </div>

      <div className={styles.poweredHeader}>
        <p>© All rights reserved Checkinch {getYear(new Date())}</p>
      </div>
    </div>
  );
};

export default PoweredBy;
