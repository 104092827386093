import { useDispatch } from 'react-redux';

import { Button } from '@mui/material';

import { setViewRequest } from '../../redux/features/isDeleteSlice';
import { toast } from 'react-toastify';
import { airlineManagerService } from '../../api/apiManager';

import TEXT from '../../assets/text.json'
import styles from './PromptRequest.module.scss'

const PromptRequest = () => {
    const dispatch = useDispatch()

    const handlerRequest = async () => {
        try {
            let response = await airlineManagerService.sendRequestStickers()
            if (response?.status === 200) {
                toast.success("Stickers request sent successfully");
                dispatch(setViewRequest(false))
            } else {
                toast.error(response);
            }
        } catch (err) {
            console.error(err)
        } finally {
            dispatch(setViewRequest(false))
        }
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>{TEXT.PROMPT_STICKERS}</h2>
                <p>{TEXT.PROMPT_STICKERS_QUSTEION}</p>
                <div className={styles.actions}>
                    <Button variant="contained" onClick={handlerRequest}>Submit</Button>
                    <Button
                        className={styles.delete}
                        variant="outlined"
                        onClick={() => dispatch(setViewRequest(false))}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PromptRequest;