import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';

import {airlineManagerService, handlingManagerService} from '../../api/apiManager';
import Loader from '../Loader/Loader';
import { dataGridService } from "../../services/dataGridService";
import { schemaStickers } from "../../common/schemaValidation";

import styles from './StickerRequestPage.module.scss'

const StickerRequestPage = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false);

    const role = useSelector((state: any) => state.user.user.role);

    const {control, handleSubmit, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaStickers),
    });

    const sendSupport = async (data: any) => {
        let request;
        const parsedData = {...data, ['quantity']: parseInt(data.quantity, 10)}
        dataGridService.removeEmptyFields(parsedData)

        role === 2
            ? request = handlingManagerService.sendRequestStickers(parsedData)
            : request = airlineManagerService.sendRequestStickers(parsedData)

        setLoading(true)
        await request.then((res: any) => {
            if (res.status === 201) {
                toast.success("Stickers request sent successfully");
                navigate("/")
            } else {
                toast.error(res);
            }
        }).finally(() => {
            setLoading(false)
        })

    }

    if (loading) return <Loader/>

    return (
        <div className={styles.app}>
            <form className={styles.form_box} onSubmit={handleSubmit(sendSupport)}>
                <h2>Stickers request</h2>
                <p>Please fill the required quantity of stickers.</p>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {marginBottom: '20px', width: '100%'},
                    }}
                >

                    <Controller
                        render={({field}) =>
                            <div style={{width: "100%", padding: "10px 0px"}} className={styles.wrap_witherror}>
                                <TextField onKeyDown={(event) => dataGridService.onlyNumberRegExp(event)}
                                fullWidth label="Requested amount *" {...field} className="materialUIInput"/>
                                <small className={styles.error}>{errors.subject?.quantity}</small>
                            </div>}
                        name="quantity"
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        render={({field}) =>
                            <div style={{width: "100%", padding: "0px 0px 10px 0px"}} className={styles.wrap_witherror}>
                                <TextField
                                    label="Message *"
                                    {...field}
                                    multiline
                                    rows={20}
                                    placeholder="Message.."
                                    fullWidth
                                />
                            </div>}
                        name="message"
                        control={control}
                        defaultValue=""
                    />

                </Box>
                <div className={styles.button}>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                    >
                        Send
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default StickerRequestPage;
