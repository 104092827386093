import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import { toast } from "react-toastify";

import FlightInfoGrid from "../../../components/FlightInfoGrid/FlightInfoGrid";

import styles from "./HandlingActions.module.scss";
import { handlingManagerService } from "../../../api/apiManager";
import Loader from "../../../components/Loader/Loader";

const FlightSingleView = () => {
    const pathname = useLocation().pathname;
    const id = pathname.split("/").pop();

    const [flight, setFlight] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    const getFlight = async (userId: any) => {
        setLoading(true)
        try {
            const res = await handlingManagerService.getFlightId(userId)
            setFlight(res?.data);
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong");
        }

        setLoading(false)
    }

    useEffect(() => {
        getFlight(id).catch((e) => {
        })
    }, []);

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            {loading ?
                <Loader/>
                :
                <>
                    <div className={styles.info_single}>
                        <FlightInfoGrid flight={flight}/>
                    </div>
                    <NavLink to={{
                        pathname: `/link-stewards-to-flight/${id}`,
                    }}
                             state={{
                                 flight: flight
                             }}
                    >
                        <Button className={styles.btn} variant="contained">Link Steward</Button>
                    </NavLink>
                </>
            }
        </div>
    );
}

export default FlightSingleView;
