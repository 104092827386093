import * as React from "react";
import { useState } from "react";
import FormControl from '@mui/material/FormControl';
import styles from './SelectFilter.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { clearFilters, setAirportName, } from "../../../../redux/features/airlineManagerSlice";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";


export default function SelectFilter() {


    const [airport, setAirport] = useState<any>('')
    let dataFromFilters = useSelector((state: any) => state.airlineManagerPage.filter)

    let dispatch = useDispatch()


    const handleChangeAirport = (event: any) => {
        setAirport(event.target.value)
        dispatch(setAirportName(event.target.value))
    };


    const clearFilter = () => {
        setAirport('')
        dispatch(clearFilters())
    }

    return (
        <div className={styles.selectFilters}>

            <FormControl sx={{
                minWidth: 200,
                display: "flex",
                flexDirection: "row",
            }}>
                <TextField value={airport} onChange={handleChangeAirport} size="small" sx={{width: 150, m: 1}}
                           id="outlined-basic" label="Airport" variant="outlined"/>

            </FormControl>
            <Button disabled={dataFromFilters.fromDateDisabled || dataFromFilters.toDateDisabled} onClick={clearFilter}
                    variant="contained">
                Clear filters
            </Button>

        </div>
    );
}

