import HandlingCreateGrid from "../../../components/HandlingCreateGrid/HandlingCreateGrid";

import styles from "./HandlingActions.module.scss";

const HandlingCreate = () => {

    return (
        <div className={styles.data_grid}>
            <HandlingCreateGrid/>
        </div>
    );
}

export default HandlingCreate;
