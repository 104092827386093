import { useDispatch } from "react-redux";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { productsService } from "../../../api/apiManager";
import ProductModal from "../ProductModal/modal/ProductModal";
import { setUpdateFlag } from "../../../redux/features/isUpdateFlag";

import styles from "../ProductCard.module.scss";

const Product = ({service, value, companyIdFromProps, currency}: any) => {

    const dispatch = useDispatch()

    const deleteProduct = async (id: number) => {
        try {
            await productsService.deleteProduct(id)
        } finally {
            dispatch(setUpdateFlag())
        }
    }

    return (<>
            <ListItem
                key={value}
                disablePadding
            >
                <ListItemButton role={undefined} dense>
                    <div className={styles.product}>
                        <div>
                            <ListItemIcon>
                                <Checkbox checked={service?.isActive}
                                          inputProps={{'aria-label': 'controlled'}}
                                />
                            </ListItemIcon>
                        </div>
                        <div>
                            <p style={{overflowWrap: "anywhere", maxWidth: "360px", minWidth: "360px"}}>{service?.name}</p>
                        </div>
                        <div>
                            <p style={{overflowWrap: "anywhere", maxWidth: "260px", minWidth: "260px", textAlign: "center"}}>{service?.category?.title || "N/A"}</p>
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <p style={{overflowWrap: "anywhere"}}>{service?.price + " " + currency}</p>
                        </div>
                    </div>
                    <ProductModal companyIdFromProps={companyIdFromProps}
                                  service={service}
                    />
                    <IconButton onClick={(e) => {
                        deleteProduct(service.id)
                        dispatch(setUpdateFlag())
                    }} edge="end" aria-label="comments">
                        <DeleteIcon/>
                    </IconButton>
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default Product;
