import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { enAU } from "date-fns/locale";
import { toast } from "react-toastify";
import { debounce } from "lodash";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, Checkbox, Divider, FormLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Loader from "../Loader/Loader";
import { airlineManagerService } from "../../api/apiManager";
import { setView } from "../../redux/features/isDeleteSlice";
import PromptCancel from "../PromptCancel/PromptCancel";
import { schemaFlight } from "../../common/schemaValidation";
import { dataGridService } from "../../services/dataGridService";
import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";

import TEXT from "../../assets/text.json";
import styles from "./FlightEditGrid.module.scss";

const FlightCreateGrid = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const isView = useSelector((store: any) => store?.isDelete?.isView);
  const flight = useSelector((store: any) => store?.airlineManagerPage?.flight);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<any>({
    mode: "onSubmit",
    resolver: yupResolver(schemaFlight),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [arrivalDateTime, setArrival] = useState<any>(flight?.arrivalDateTime);
  const [departDateTime, setDepart] = useState<any>(flight?.departureDateTime);

  const [arrivalCountries, setArrivalCountries] = useState<any>([]);
  const [arrivalCities, setArrivalCities] = useState<any>([]);

  const [departureCountries, setDepartureCountries] = useState<any>([]);
  const [departureCities, setDepartureCities] = useState<any>([]);

  const handleArrivalCountries = async (e: any) => {
    let inputText = e.target.value;
    let result = await airlineManagerService.getCountriesCities(
      inputText,
      "country"
    );
    setArrivalCountries(result?.data);
  };

  const handleArrivalCities = async (e: any) => {
    let inputText = e.target.value;
    let result = await airlineManagerService.getCountriesCities(
      inputText,
      "(cities)"
    );
    setArrivalCities(result?.data);
  };

  const debounceArrivalHandleCountries = useCallback(
    debounce(handleArrivalCountries, 300),
    []
  );
  const debounceArrivalHandleCities = useCallback(
    debounce(handleArrivalCities, 300),
    []
  );

  const handleDepartureCountries = async (e: any) => {
    let inputText = e.target.value;
    let result = await airlineManagerService.getCountriesCities(
      inputText,
      "country"
    );
    setDepartureCountries(result?.data);
  };

  const handleDepartureCities = async (e: any) => {
    let inputText = e.target.value;
    let result = await airlineManagerService.getCountriesCities(
      inputText,
      "(cities)"
    );
    setDepartureCities(result?.data);
  };

  const debounceDepartureHandleCountries = useCallback(
    debounce(handleDepartureCountries, 300),
    []
  );
  const debounceDepartureHandleCities = useCallback(
    debounce(handleDepartureCities, 300),
    []
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openPrompt = () => {
    dispatch(setView(true));
  };

  const onSubmit = async (data: any) => {
    const parsedData = {
      ...data,
      id: flight.id,
      departureDateTime: new Date(
        `${dateParseToDate(getValues("departureDate"))} ${getValues(
          "departureTime"
        )}`
      ),
      arrivalDateTime: new Date(
        `${dateParseToDate(getValues("arrivalDate"))} ${getValues(
          "arrivalTime"
        )}`
      ),
    };
    dataGridService.removeEmptyFields(parsedData);

    setLoading(true);
    await airlineManagerService
      .updateFlight(parsedData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(TEXT.NOTIFY_FLIGHT_EDIT);
          navigate("/flights");
        } else {
          toast.warn(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!Object.keys(flight).length) {
    navigate(`/flight-view/${id}`);
  }

  if (loading) return <Loader />;

  return (
    <>
      <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
        <h3>Edit Flight</h3>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& .MuiTextField-root": { m: 1, width: "275px" },
          }}
        >
          <div className={styles.section_input}>
            <Divider textAlign="left">Main info</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Flight number *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.flightNumber?.message}
                    </small>
                  </div>
                )}
                name="flightNumber"
                control={control}
                defaultValue={flight?.flightNumber}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Flight unique ID"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.flightUniqueId?.message}
                    </small>
                  </div>
                )}
                name="flightUniqueId"
                control={control}
                defaultValue={flight?.flightUniqueId}
              />
              <div className={styles.checkBox}>
                <FormLabel component="legend">Status</FormLabel>
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                  defaultValue={flight?.isActive}
                />
                <span>Active</span>
              </div>
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Departure</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={departureCountries}
                    autoHighlight
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="departureCountry"
                          {...params}
                          label="Departure country *"
                          onChange={debounceDepartureHandleCountries}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.departureCountry?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="departureCountry"
                control={control}
                defaultValue={flight?.departureCountry}
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={departureCities}
                    autoHighlight
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="departureCity"
                          {...params}
                          label="Departure city *"
                          onChange={debounceDepartureHandleCities}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.departureCity?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="departureCity"
                control={control}
                defaultValue={flight?.departureCity}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Iata *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.departureIata?.message}
                    </small>
                  </div>
                )}
                name="departureIata"
                control={control}
                defaultValue={flight?.arrivalIata}
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <LocalizationProvider
                    locale={enAU}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      {...field}
                      mask="____-__-__"
                      label="Departure date *"
                      inputFormat="yyyy-MM-dd"
                      value={dateParseToDate(departDateTime)}
                      onChange={(newValue) => {
                        setDepart(newValue);
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <>
                          <div className={styles.wrap_witherror}>
                            <TextField
                              inputProps={{
                                ...params.inputProps,
                              }}
                              inputRef={ref}
                              {...params}
                            />
                            <small className={styles.error}>
                              {errors.departureDate?.message}
                            </small>
                          </div>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                )}
                name="departureDate"
                control={control}
                defaultValue={dateParseToDate(departDateTime)}
              />

              <Controller
                render={({ field: { ref, ...field } }) => (
                  <div className={styles.wrap_witherror}>
                    <LocalizationProvider
                      locale={enAU}
                      dateAdapter={AdapterDateFns}
                    >
                      <TextField
                        {...field}
                        id="time"
                        name="departureTime"
                        label="Departure time *"
                        type="time"
                        defaultValue={dateParseToTime(departDateTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={ref}
                        sx={{ width: 150 }}
                      />
                    </LocalizationProvider>
                    <small className={styles.error}>
                      {errors.departureTime?.message}
                    </small>
                  </div>
                )}
                name="departureTime"
                control={control}
                defaultValue={dateParseToTime(departDateTime)}
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Arrival</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={arrivalCountries}
                    autoHighlight
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="arrivalCountry"
                          {...params}
                          label="Arrival country *"
                          onChange={debounceArrivalHandleCountries}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.arrivalCountry?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="arrivalCountry"
                control={control}
                defaultValue={flight?.arrivalCountry}
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={arrivalCities}
                    autoHighlight
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="arrivalCity"
                          {...params}
                          label="Arrival city *"
                          onChange={debounceArrivalHandleCities}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.arrivalCity?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="arrivalCity"
                control={control}
                defaultValue={flight?.arrivalCity}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Iata *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.arrivalIata?.message}
                    </small>
                  </div>
                )}
                name="arrivalIata"
                control={control}
                defaultValue={flight?.arrivalIata}
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <LocalizationProvider
                    locale={enAU}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      {...field}
                      mask="____-__-__"
                      label="Arrival date *"
                      inputFormat="yyyy-MM-dd"
                      value={dateParseToDate(arrivalDateTime)}
                      onChange={(newValue) => {
                        setArrival(newValue);
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <>
                          <div className={styles.wrap_witherror}>
                            <TextField
                              inputProps={{
                                ...params.inputProps,
                              }}
                              inputRef={ref}
                              {...params}
                            />
                            <small className={styles.error}>
                              {errors.arrivalDate?.message}
                            </small>
                          </div>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                )}
                name="arrivalDate"
                control={control}
                defaultValue={dateParseToDate(arrivalDateTime)}
              />

              <Controller
                render={({ field: { ref, ...field } }) => (
                  <div className={styles.wrap_witherror}>
                    <LocalizationProvider
                      locale={enAU}
                      dateAdapter={AdapterDateFns}
                    >
                      <TextField
                        {...field}
                        id="time"
                        name="arrivalTime"
                        label="Arrival time *"
                        type="time"
                        defaultValue={dateParseToTime(arrivalDateTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={ref}
                        sx={{ width: 150 }}
                      />

                      <small className={styles.error}>
                        {errors.arrivalTime?.message}
                      </small>
                    </LocalizationProvider>
                  </div>
                )}
                name="arrivalTime"
                control={control}
                defaultValue={dateParseToTime(flight?.arrivalDateTime)}
              />
            </div>
          </div>
        </Box>
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          size="large"
          type="submit"
        >
          Submit
        </Button>

        <Button
          className={styles.button}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => openPrompt()}
        >
          Cancel
        </Button>
      </form>
      {isView ? <PromptCancel /> : ""}
    </>
  );
};

export default FlightCreateGrid;
