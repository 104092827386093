import { useEffect, useState } from "react";

import { GridColDef } from "@mui/x-data-grid";

import Grid from "../../components/Grid/Grid";
import { checkinchService } from "../../api/apiManager";
import { IHandlingList } from "../../models/handling-list.model";

import styles from "./HandlingActions.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        width: 65
    },
    {
        field: 'workerId',
        headerName: 'Worker ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'firstname',
        headerName: 'First name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'lastname',
        headerName: 'Last name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'phoneNumber',
        headerName: 'Mobile phone number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
    },
    {
        field: 'username',
        headerName: 'Email address',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: (params) => {
            return params.value ? params.value = "Active" : params.value = "Inactive"
        }
    },
    {
        field: 'handlingCompanyName',
        headerName: 'Handling Company',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'companies',
        headerName: 'Airline companies',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: true,
        valueGetter: (params) => {
            let comps: any[] = []
            params.row.companies.forEach((el: any) => {
                comps.push(el.companyName)
            })
            return comps
        }
    },
    {
        field: 'companiesBRN',
        headerName: 'BRN',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: true,
        valueGetter: (params) => {
            let brns: any[] = []
            params.row.companies.forEach((el: any) => {
                brns.push(el?.legalEntityId)
            })
            return brns

        }
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            let date = new Date(params.value)
            let formattedDate = date.toLocaleDateString("fr-CA") + " / " + date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })
            return formattedDate;
        },
    },

];

const HandlingList = () => {

    const [info, setInfo] = useState<IHandlingList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getHandlings = async () => {
        setLoading(true)
        await checkinchService.getHandlingList().then((res) => {
            setInfo(res?.data);
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getHandlings()
    }, []);

    return (
        <div className={styles.data_grid}>
            <h3>Data</h3>
            <Grid
                columns={columns}
                rows={info}
                loading={loading}
                singleview="/manager-view"
            />
        </div>
    );
}

export default HandlingList;
