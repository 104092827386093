import { Formik } from "formik";
import styles from './ResetForm.module.scss'

const ResetForm = () => (
    <Formik
        initialValues={{username: "", password: "", confirmPassword: ""}}
        onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
                console.log("Logging in", values);
                setSubmitting(false);
            }, 500);
        }}
        validate={values => {

            interface Error {
                username?: string;
                password?: string;
                confirmPassword?: string
            }

            let errors: Error = {};

            if (!values.username) {
                errors.username = "Required";
            }

            const passwordRegex = /(?=.*[0-9])/;
            if (!values.password) {
                errors.password = "Required";
            } else if (values.password.length < 8) {
                errors.password = "Password must be 8 characters long.";
            } else if (!passwordRegex.test(values.password)) {
                errors.password = "Invalid password. Must contain one number.";
            }
            if (!values.confirmPassword) {
                errors.confirmPassword = "Required";
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Password don't match";
            }

            return errors;
        }}
    >
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;

            return (
                <div className={styles.wrapper}>
                    <div className={styles.formWrapper}>
                        <form onSubmit={handleSubmit}>

                            <label htmlFor="username">Username</label>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                placeholder="Enter your username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.username && touched.username ? styles.error : ""}
                            />
                            {errors.username && touched.username && (
                                <div className={styles.inputFeedback}>{errors.username}</div>
                            )}

                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password" placeholder="Enter your password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.password && touched.password ? styles.error : ""}
                            />
                            {errors.password && touched.password && (
                                <div className={styles.inputFeedback}>{errors.password}</div>
                            )}
                            <>

                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.confirmPassword && touched.confirmPassword ? styles.error : ""}
                                />
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <div className={styles.inputFeedback}>{errors.confirmPassword}</div>
                                )}

                            </>

                            <button className={styles.submit} disabled={isSubmitting} type="submit">
                                SEND
                            </button>


                        </form>
                    </div>
                </div>
            );

        }}
    </Formik>
);

export default ResetForm;

