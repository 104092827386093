import List from '@mui/material/List';

import NestedItem from "./NestedItem";
import NestedCollapseItem from "./NestedCollapseItem";

import TEXT from '../../assets/text.json'
import styles from './NestedList.module.scss'

function AirlineCompanyManagerList() {

    return (
        <List
            sx={{width: '100%', maxWidth: 340, flex: 8}}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <div className={styles.item}>
                <NestedItem text={TEXT.MENU_DASHBOARD} link="/dashboard"/>
            </div>
            <div className={styles.item}>
                <NestedItem text={TEXT.MENU_AIRLINE} link="/company"/>
            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text={TEXT.MENU_FLIGHTS}
                    subText1={TEXT.MENU_ALL_FLIGHTS}
                    subText2={TEXT.MENU_ADD_NEW}
                    linkMain="/flights"
                    linkCreate="/add-flight"
                />
            </div>
            <div className={styles.item}>
                <NestedItem text={TEXT.MENU_REPORTS} link="/reports"/>
            </div>
            <div className={styles.item}>
                <NestedCollapseItem
                    text={TEXT.MENU_SUPPORT}
                    subText1={TEXT.MENU_CONTACT}
                    subText2={TEXT.MENU_REQUEST_STICKERS}
                    linkMain="/support"
                    linkCreate="/sticker-request"
                />
            </div>
        </List>
    );
}

export default AirlineCompanyManagerList;
