import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import InfoCard from "../../InfoCard/InfoCard";
import { checkinchService } from "../../../api/apiManager";
import Loader from "../../Loader/Loader";
import { setDashboard } from "../../../redux/features/checkinchSlice";

import styles from "./CheckinchMetrics.module.scss"

const CheckinchMetrics = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false);

    let dataFromFilters = useSelector((state: any) => state.checkinchPage.filter)
    const info = useSelector((state: any) => state.checkinchPage.dashboard)

    const getDashboard = async () => {
        setLoading(true)
        await checkinchService.getDashboard(dataFromFilters).then((res) => {
            dispatch(setDashboard(res?.data))
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getDashboard()
    }, [])

    if (loading) {
        return <Loader/>
    }

    return (
        <div className={styles.dashboardWrapper}>
            <div>
                <InfoCard
                    title="Airline companies"
                    metric1={String(info?.companies.total)}
                    metric1desc="Total"
                    metric2={String(info?.companies.active)}
                    metric2desc="Active"
                    metric3={String(info?.companies.inactive)}
                    metric3desc="Inactive"
                    metric4={String(info?.companies.new)}
                    metric4desc="New"
                />
                <InfoCard
                    title="Stewards"
                    metric1={String(info?.users.active_stewards)}
                    metric1desc="Total number"

                />

            </div>
            <div>
                <InfoCard
                    title="Handling managers"
                    metric1={String(info?.users.active_handling_managers)}
                    metric1desc="Total number"
                />

                <InfoCard
                    title="Measurements"
                    // metric1={String(info?.measurement.total)}
                    metric1={String(info?.measurement.approved + info?.measurement.not_approved)}
                    metric1desc="Total"
                    metric2={String(info?.measurement.approved)}
                    metric2desc="Approved"
                    metric3={String(info?.measurement.not_approved)}
                    metric3desc="Not Approved"
                />

            </div>
        </div>

    );
};

export default CheckinchMetrics;
