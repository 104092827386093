import HandlingInfoGrid from "../../../components/HandlingInfoGrid/HandlingInfoGrid";

import styles from "./HandlingActions.module.scss";

const HandlingSingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <HandlingInfoGrid/>
            </div>
        </div>
    );
}

export default HandlingSingleView;
