import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';

import Loader from '../Loader/Loader';
import PromptDelete from '../PromptDelete/PromptDelete';
import { handlingManagerService } from '../../api/apiManager';
import { setShiftManager } from '../../redux/features/handlingManagerSlice';
import { setDelete, setView } from '../../redux/features/isDeleteSlice';

import { dateParseToDate, dateParseToTime } from '../../common/dateFormatter';
import styles from './ShiftInfoGrid.module.scss'

const ShiftInfoGrid = () => {
    const isDelete = useSelector((store: any) => store?.isDelete.isDelete)
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const dispatch = useDispatch()
    const pathname = useLocation().pathname;
    const id = pathname.split("/").pop();

    const navigate = useNavigate()

    const [info, setInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getHandling = async (userId: any) => {
        setLoading(true)
        await handlingManagerService.getShiftManagerId(userId)
            .then((res) => {
                setInfo(res?.data);
                dispatch(setShiftManager(res?.data))
            }).catch((err) => {
                if (err.response.status === 404) {
                    //navigate('/404')
                }
            }).finally(() => {
                setLoading(false)
            })


    }

    const openPrompt = () => {
        dispatch(setView(true))
    }

    const deleteShift = async (userId: any) => {
        if (isDelete) {
            let response = await handlingManagerService.deleteShiftManager(userId)
            if (response?.status === 200) {
                toast.success("Shift manager was deleted successfully");
                navigate(-1)
                dispatch(setDelete(false))
            } else {
                toast.error("Something went wrong");
            }
        } else {
            dispatch(setView(false))
        }
    }

    useEffect(() => {
        getHandling(id)
    }, []);

    useEffect(() => {
        deleteShift(id)
    }, [isDelete]);

    if (loading) return <Loader/>

    return (
        <>
            <div className={styles.app}>
                <Table>
                    <tbody>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">ID</TableCell>
                        <TableCell>{info?.id}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Worker ID</TableCell>
                        <TableCell>{info?.workerId}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">First name</TableCell>
                        <TableCell>{info?.firstname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Last name</TableCell>
                        <TableCell>{info?.lastname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Mobile phone number</TableCell>
                        <TableCell>{info?.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Email address</TableCell>
                        <TableCell>{info?.username}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Status</TableCell>
                        <TableCell>{info?.isActive ? "Active" : "Inactive"}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Country</TableCell>
                        <TableCell>
                            {info?.country}
                        </TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Handling company</TableCell>
                        <TableCell>{info?.handlingCompanyName}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Airline companies</TableCell>
                        <TableCell>{info?.companies.length ? info?.companies.map((company: any) => {
                            return <p key={company.id}>{company.companyName}</p>
                        }) : <p>No company</p>}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Manager Role</TableCell>
                        <TableCell>{info?.managerRoleName}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Comments</TableCell>
                        <TableCell>{info?.comments}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Created at</TableCell>
                        <TableCell>{dateParseToDate(info?.created_at)} / {dateParseToTime(info?.created_at)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Updated at</TableCell>
                        <TableCell>{dateParseToDate(info?.updated_at)} / {dateParseToTime(info?.updated_at)}</TableCell>
                    </TableRow>
                    </tbody>
                </Table>
                <div className={styles.button}>
                    <NavLink to={`/shift-manager-edit?id=${id}`}>
                        <Button variant="contained">Edit</Button>
                    </NavLink>
                    <Button
                        className={styles.delete}
                        color="error"
                        variant="outlined"
                        onClick={() => openPrompt()}>
                        Delete
                    </Button>
                </div>
            </div>
            <div className={styles.user_card}>
                <div className={styles.user_image}>
                    <Avatar
                        name={info?.firstname + ' ' + info?.lastname}
                        maxInitials={2}
                        size="180"
                        round={true}
                        color="#afafaf"
                        className={styles.initials}
                    />
                </div>
                <div className={styles.user_info}>
                    <h4 className={styles.user_name}>
                        {info?.firstname + ' ' + info?.lastname}
                    </h4>
                    <p className={styles.user_role}>
                        Shift Manager
                    </p>
                </div>
            </div>
            {isView ? <PromptDelete/> : ''}
        </>
    );
}

export default ShiftInfoGrid;
