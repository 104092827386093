import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { checkinchService } from "../../api/apiManager";
import Loader from "../Loader/Loader";
import PromptDelete from "../PromptDelete/PromptDelete";
import { setAirlineCompany } from "../../redux/features/checkinchSlice";
import { setDelete, setView } from "../../redux/features/isDeleteSlice";
import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";
import ProductListView from "../ProductListView/ProductListView";

import styles from "./AirlineInfoGrid.module.scss";
import WeightSettingsListView from "../WeightSettingsListView/WeightSettingsListView";
import { Divider, IconButton, ListItem, ListItemButton } from "@mui/material";

const AirlineInfoGrid = () => {
  const isDelete = useSelector((store: any) => store?.isDelete.isDelete);
  const isView = useSelector((store: any) => store?.isDelete.isView);
  const [baggageRanges, setBaggageRanges] = useState([]);
  const [carryOnRanges, setCarryOnRanges] = useState([]);
  const [luggageRanges, setLuggageRanges] = useState([]);
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const id = pathname.split("/").pop();

  const navigate = useNavigate();

  const [info, setInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAirlineCompany = async (companyId: any) => {
    setLoading(true);
    await checkinchService
      .getAirlineCompanyId(companyId)
      .then((res) => {
        setInfo(res?.data);
        dispatch(setAirlineCompany(res?.data));
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // //navigate('/404')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAirlineLuggageRanges = async (companyId: any) => {
    setLoading(true);
    await checkinchService
      .getAirlineLuggageRanges(companyId)
      .then((res) => {
        setBaggageRanges(
          res?.data?.baggageRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setCarryOnRanges(
          res?.data?.carryOnBagRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setLuggageRanges(
          res?.data?.luggageRange?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // //navigate('/404')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openPrompt = () => {
    dispatch(setView(true));
  };

  const deleteAirlineCompany = async (companyId: any) => {
    if (isDelete) {
      let response = await checkinchService.deleteAirlineCompany(companyId);
      if (response?.status === 200) {
        toast.success("Airline company was deleted successfully");
        navigate(-1);
        dispatch(setDelete(false));
      } else {
        toast.error("Something went wrong");
      }
    } else {
      dispatch(setView(false));
    }
  };

  useEffect(() => {
    getAirlineCompany(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAirlineLuggageRanges(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    deleteAirlineCompany(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelete]);

  if (loading) return <Loader />;

  return (
    <>
      <div className={styles.app}>
        <Table>
          <tbody>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">ID</TableCell>
              <TableCell>{info?.id}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Company name</TableCell>
              <TableCell>{info?.companyName}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Company short name</TableCell>
              <TableCell>{info?.shortCompanyName}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">BRN</TableCell>
              <TableCell>{info?.legalEntityId}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Country</TableCell>
              <TableCell>{info?.country}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Address</TableCell>
              <TableCell>
                {info?.address?.city}, {info?.address?.street},{" "}
                {info?.address?.building}, <br /> Floor: {info?.address?.floor},
                Office: {info?.address?.officeNumber}, Zip Code:{" "}
                {info?.address?.zipCode}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Contact details section</TableCell>
              <TableCell>
                {info?.phone}, {info?.email}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Contact person details</TableCell>
              <TableCell>
                {info?.contact?.personFirstname} {info?.contact?.personLastname}
                , {info?.contact?.personPhone}, {info?.contact?.personEmail},{" "}
                {info?.contact?.personRole}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Time zone</TableCell>
              <TableCell>{info?.timeZone}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Company type</TableCell>
              <TableCell>{info?.companyType ? "Low cost" : "Legacy"}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Operational settings section</TableCell>
              <TableCell>
                Max hand luggage height: {info?.settings?.maxLength}, <br />
                Max hand luggage width: {info?.settings?.maxWidth}, <br />
                Max hand luggage depth: {info?.settings?.maxDepth}, <br />
                {info?.companyType ? (
                  <>
                    Max hand luggage weight: {info?.settings?.maxHandbagWeight},{" "}
                    <br />
                    Max carry on bag weight:{" "}
                    {info?.settings?.maxCarryOnBagWeight}, <br />
                  </>
                ) : (
                  <>
                    Max carry on weight: {info?.settings?.maxCarryOnWeight},{" "}
                    <br />
                  </>
                )}
                Max hand luggage weight for ranges:{" "}
                {info?.settings?.maxHandLuggageWeightForRanges}, <br />
                Max carry on bag weight for ranges:{" "}
                {info?.settings?.maxCarryOnBagWeightForRanges || "-"}, <br />
                Check in time: {info?.settings?.checkinTime || "-"}, <br />
                Notify stewards: {info?.settings?.notifySteward},
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Payments section</TableCell>
              <TableCell>
                Hand luggage payment for oversize:{" "}
                {info?.settings?.paymentForOversize}, <br />
                Payment for overweight:{" "}
                {info?.settings?.paymentForBaggageOverweight}, <br />
                Calculating method:{" "}
                {info?.settings?.calculatingMethodOfOverweight}, <br />
                Currency: {info?.settings?.currency},<br />
                Business ID: {info?.settings?.business_id}
                <br />
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Status</TableCell>
              <TableCell>{info?.isActive ? "Active" : "Inactive"}</TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Created at</TableCell>
              <TableCell>
                {dateParseToDate(info?.created_at)} /{" "}
                {dateParseToTime(info?.created_at)}
              </TableCell>
            </TableRow>
            <TableRow className={styles.row_style}>
              <TableCell variant="head">Updated at</TableCell>
              <TableCell>
                {dateParseToDate(info?.updated_at)} /{" "}
                {dateParseToTime(info?.updated_at)}
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
        <div className={styles.button}>
          <NavLink to={`/airline-edit?id=${id}`}>
            <Button variant="contained">Edit</Button>
          </NavLink>
          <Button
            className={styles.delete}
            color="error"
            variant="outlined"
            onClick={() => openPrompt()}
          >
            Delete
          </Button>
        </div>
      </div>
      <div>
        <div className={styles.user_card}>
          <div className={styles.user_image}>
            <img src={info?.logo} alt="company" />
          </div>
        </div>
        <div className={styles.product_card}>
          <ProductListView currency={info?.settings?.currency} companyId={id} />
        </div>
        <div className={styles.product_card}>
          <WeightSettingsListView
            currency={info?.settings?.currency}
            companyId={id}
          />
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Baggages ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {baggageRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Luggage ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {luggageRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
        <div className={styles.product_card}>
          <div style={{ padding: "20px" }}>
            <Divider>Carry on ranges</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                padding: "0 16px",
              }}
            >
              <div>Min weight</div>
              <div>Max weight</div>
              <div style={{ width: "60px" }}>Price</div>
            </div>
            {carryOnRanges?.map((item: any, index: number) => {
              return (
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    style={{ justifyContent: "space-between", height: "52px" }}
                  >
                    <div>{item.minRangeWeight}</div>
                    <div>{item.maxRangeWeight}</div>
                    <div>{item.price}</div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </div>
        </div>
      </div>
      {isView ? <PromptDelete /> : ""}
    </>
  );
};

export default AirlineInfoGrid;
