import * as React from 'react';
import { useCallback, useState } from 'react';

import { Autocomplete, Box, Button } from "@mui/material";

import addFilter from '../../../assets/filter-add.svg';
import triangle from '../../../assets/triangle.svg';
import styles from './DashboardFilter.module.scss';
import { checkinchService } from "../../../api/apiManager";
import { useDispatch, useSelector } from "react-redux";
import { setDashboard, setFromDateDisabled, setToDateDisabled } from "../../../redux/features/checkinchSlice";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enAU } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Controller, useForm } from "react-hook-form";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { addDays, endOfMonth, endOfYear, lastDayOfWeek, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { debounce } from "lodash";
import { dataGridService } from "../../../services/dataGridService";


const DashboardFilter = () => {

    const dispatch = useDispatch()

    const [show, setShow] = useState<boolean>(false)

    const {control, handleSubmit, setValue} = useForm<any>({
        mode: 'onSubmit',
    });

    const [toDate, setToDate] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [countries, setCountries] = useState<any>([]);
    const [alignment, setAlignment] = useState<any>();
    const [valueCountry, setValueCountry] = useState<any | null>(null);

    let dataFromFilters = useSelector((state: any) => state.checkinchPage.filter)
    const onSubmit = async (data: any) => {
        dataGridService.removeEmptyFields(data)
       
        let result = await checkinchService.getDashboard(data);
        dispatch(setDashboard(result?.data))
    }

    const buttonStyle = {
        fontSize: "12px"
    }

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleClick = (today: string) => {
        let date = new Date()
        switch (today) {
            case "today":
                setValue('fromDate', date.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(date, 1).toLocaleDateString('fr-CA'))
                setFromDate(date.toLocaleDateString('fr-CA'))
                setToDate(addDays(date, 1).toLocaleDateString('fr-CA'))
                break;
            case "week":
                const weekMonday = startOfWeek(date, {locale: enAU})
                const weekSunday = lastDayOfWeek(date, {locale: enAU})
                setValue('fromDate', weekMonday.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(weekSunday, 1).toLocaleDateString('fr-CA'))
                setFromDate(weekMonday.toLocaleDateString('fr-CA'))
                setToDate(addDays(weekSunday, 1).toLocaleDateString('fr-CA'))

                break;
            case "month":
                const monthStart = startOfMonth(date)
                const monthEnd = endOfMonth(date)
                setValue('fromDate', monthStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(monthEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(monthStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(monthEnd, 1).toLocaleDateString('fr-CA'))

                break;
            case "year":
                const yearStart = startOfYear(date)
                const yearEnd = endOfYear(date)
                setValue('fromDate', yearStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(yearEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(yearStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(yearEnd, 1).toLocaleDateString('fr-CA'))

                break;
        }

    };
    const clearFilter = () => {
        setValue('airport', '')
        setValue('fromDate', '')
        setValue('toDate', null)
        setValue('country', null)
        setAlignment('')
        setFromDate(null)
        setToDate(null)
    }

    const handleCountries = async (e: any) => {
        let inputText = e.target.value
        let result = await checkinchService.getCountriesCities(inputText, "country")
        setCountries(result?.data)

    }

    const debounceHandleCountries = useCallback(debounce(handleCountries, 300), []);

    return (
        <div className={styles.filters}>
            <div onClick={() => setShow(!show)} className={styles.filterButton}>
                <div>
                    <img width={23} src={addFilter} alt="filters"/>
                </div>
                <div className={styles.filterText}>
                    <p>Common filters</p>
                </div>
                <div>
                    <img className={!show ? styles.rotate : ""} src={triangle} alt=""/>
                </div>
            </div>
            <div className={styles.filter + ' ' + (show ? styles.showFilter : styles.hideFilter)}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    size="small"
                >
                    <ToggleButton style={buttonStyle} onClick={() => handleClick("today")}
                                  value="Today">Today</ToggleButton>
                    <ToggleButton style={buttonStyle} onClick={() => {
                        handleClick("week")
                    }} value="This Week">This Week</ToggleButton>
                    <ToggleButton style={buttonStyle} onClick={() => {
                        handleClick("month")
                    }} value="This month">This month</ToggleButton>
                    <ToggleButton style={buttonStyle}
                                  onClick={() => {
                                      handleClick("year")
                                  }} value="This Year">This Year</ToggleButton>
                </ToggleButtonGroup>
                <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& .MuiTextField-root': {m: 1, width: '200px'},
                        }}
                    >
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"From Date"}
                                        inputFormat="yyyy-MM-dd"
                                        value={fromDate}

                                        clearable={true}
                                        onError={(err) => {
                                            if (err) {
                                                dispatch(setToDateDisabled(true))
                                            } else {
                                                dispatch(setToDateDisabled(false))
                                            }
                                        }}
                                        onChange={(newValue) => {
                                            setFromDate(newValue?.toLocaleDateString('fr-CA'))
                                            field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                        }}
                                        renderInput={(params) => <>
                                            <TextField size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params} />
                                        </>}
                                    />
                                </LocalizationProvider>
                            }
                            name="fromDate"
                            control={control}
                            defaultValue={''}
                        />
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"To Date"}
                                        inputFormat="yyyy-MM-dd"
                                        value={toDate}
                                        clearable={true}
                                        onError={(err) => {
                                            if (err) {
                                                dispatch(setFromDateDisabled(true))
                                            } else {
                                                dispatch(setFromDateDisabled(false))
                                            }
                                        }}
                                        onChange={(newValue) => {
                                            setToDate(newValue?.toLocaleDateString('fr-CA'))
                                            field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                        }}
                                        renderInput={(params) => <>
                                            <TextField size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params} />
                                        </>}
                                    />
                                </LocalizationProvider>
                            }
                            name="toDate"
                            control={control}
                            defaultValue={''}
                        />

                        <Controller
                            render={({field: {ref, ...field}}) => <Autocomplete
                                {...field}
                                options={countries}
                                autoHighlight
                                onChange={(event, newValue) => {
                                    setValueCountry(newValue);
                                    field.onChange(newValue)
                                }}
                                getOptionLabel={(option: any) => option}
                                renderOption={(props, option: any) => (
                                    <Box component="li" {...props}>
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            name="country"
                                            {...params}
                                            size="small"
                                            label="Country"
                                            onChange={debounceHandleCountries}
                                            inputRef={ref}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    </>
                                )}
                            />}
                            name="country"
                            control={control}
                            defaultValue=""
                        />
                        <Controller
                            render={({field}) =>
                                <TextField {...field} size="small"
                                           id="outlined-basic" label="Airport"
                                           variant="outlined"/>
                            }
                            name="airport"
                            control={control}
                            defaultValue=""
                        />

                    </Box>
                    <Button style={{marginRight: "10px"}}
                            disabled={dataFromFilters.fromDateDisabled || dataFromFilters.toDateDisabled}
                            onClick={clearFilter} variant="contained">
                        Clear filters
                    </Button>
                    <Button type="submit" disabled={dataFromFilters.fromDateDisabled || dataFromFilters.toDateDisabled}
                            variant="contained">
                        Apply filters
                    </Button>

                </form>
            </div>

        </div>
    );
};

export default DashboardFilter;
