import { useDispatch } from 'react-redux';

import { Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { setView } from '../../redux/features/isDeleteSlice';
import TEXT from '../../assets/text.json'

import styles from './Prompt.module.scss'

const PromptCancel = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>{TEXT.PROMPT_CANCEL}</h2>
                <p>{TEXT.PROMPT_CANCEL_QUSTEION}</p>
                <small>{TEXT.PROMPT_CANCEL_WARNING}</small>
                <div className={styles.actions}>
                    <Button variant="contained" onClick={() => {
                        navigate(-1)
                        dispatch(setView(false))
                    }}>Submit</Button>
                    <Button
                        className={styles.delete}
                        variant="outlined"
                        onClick={() => dispatch(setView(false))}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PromptCancel;
