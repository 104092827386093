import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";

import styles from "./ProductListView.module.scss";

const ProductView = ({service, value, currency}: any) => {
    return (
        <ListItem
            key={value}
            disablePadding
        >
            <ListItemButton role={undefined} dense>
                <div className={styles.product}>
                    <div>
                        <ListItemIcon>
                            <Checkbox checked={service?.isActive}
                                      inputProps={{'aria-label': 'controlled'}}
                            />
                        </ListItemIcon>
                    </div>
                    <div>
                        <p style={{overflowWrap: "anywhere", maxWidth: "175px", minWidth: "175px"}}>{service?.name}</p>
                    </div>
                    <div>
                        <p style={{overflowWrap: "anywhere", maxWidth: "120px", minWidth: "120px", textAlign: "center"}}>{service?.category?.title || "N/A"}</p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                        <p style={{overflowWrap: "anywhere"}}>{service?.price + " " + currency}</p>
                    </div>
                </div>
            </ListItemButton>
        </ListItem>

    );
};

export default ProductView;
