import {createSlice} from "@reduxjs/toolkit"

export const checkinchSlice = createSlice({
    name: "checkinchPage",
    initialState: {
        checkinch: {},
        filter: {
            fromDate: "",
            toDate: "",
            country: "",
            airport: "",
            fromDateDisabled: false,
            toDateDisabled: false,
            alignment: ""
        },
        dashboard: {
            companies: {
                total: 0,
                active: 0,
                inactive: 0,
                new: 0
            },
            users: {
                active_stewards: 0,
                active_handling_managers: 0
            },
            measurement: {
                total: 0,
                approved: 0,
                not_approved: 0
            }
        },
        airlineCompany: {},
        airlineCompanyManagers: {},
        airlineCompanyManager: {},
        handlingManager: {}
    },
    reducers: {
        setCheckinch: (state, action) => {
            state.checkinch = action.payload;
        },
        setFromDate: (state, action) => {
            state.filter.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.filter.toDate = action.payload
        },
        setCountryName: (state, action) => {
            state.filter.country = action.payload
        },
        setAirportName: (state, action) => {
            state.filter.airport = action.payload
        },
        setFromDateDisabled: (state, action) => {
            state.filter.fromDateDisabled = action.payload
        },
        setToDateDisabled: (state, action) => {
            state.filter.toDateDisabled = action.payload
        },
        setAlignment: (state, action) => {
            state.filter.alignment = action.payload
        },
        setDashboard: (state, action) => {
            state.dashboard = action.payload
        },
        setAirlineCompany: (state, action) => {
            state.airlineCompany = action.payload
        },
        setAirlineCompanyManagers: (state, action) => {
            state.airlineCompanyManagers = action.payload
        },
        setAirlineCompanyManager: (state, action) => {
            state.airlineCompanyManager = action.payload
        },
        setHandlingManager: (state, action) => {
            state.handlingManager = action.payload
        },
        clearFilters: (state) => {
            state.filter = {}
        },
    }
})


export const {
    setFromDate,
    setToDate,
    setCountryName,
    setAirportName,
    setFromDateDisabled,
    setToDateDisabled,
    setDashboard,
    setAirlineCompany,
    setAirlineCompanyManagers,
    setHandlingManager,
    setCheckinch,
    clearFilters,
    setAlignment,
    setAirlineCompanyManager
} = checkinchSlice.actions;

export const selectCheckinch = (state) => state.checkinchPage;

export default checkinchSlice.reducer;
