import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormLabel,
  IconButton,
  ListItem,
  ListItemButton,
  MenuItem,
} from "@mui/material";

import Loader from "../Loader/Loader";
import { checkinchService } from "../../api/apiManager";
import { schemaAirlineCompany } from "../../common/schemaValidation";
import { timeZones } from "../../common/timeZones";
import { dataGridService } from "../../services/dataGridService";
import { setView } from "../../redux/features/isDeleteSlice";
import PromptCancel from "../PromptCancel/PromptCancel";
import ProductCard from "../ProductCard/ProductCard";

import TEXT from "../../assets/text.json";
import styles from "./AirlineEditGrid.module.scss";
import BaggageSettingsCard from "../BaggageSettingsCard/BaggageSettingsCard";
import Popup from "reactjs-popup";

const AirlineEditGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const isView = useSelector((store: any) => store?.isDelete.isView);
  const company = useSelector(
    (store: any) => store?.checkinchPage.airlineCompany
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useForm<any>({
    mode: "onSubmit",
    resolver: yupResolver(schemaAirlineCompany),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState(company?.settings?.currency || "");
  const [fileName, setFileName] = useState<string>("");
  const [logoLink, setLogoLink] = useState<string | undefined>(undefined);
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [valueCountry, setValueCountry] = useState<any | null>(company.country);
  const [valueCity, setValueCity] = useState<any | null>(company.address?.city);

  const [showOverWeightFields, setShowOverWeightFields] = useState(
    company?.settings?.weightType === "overweight-only"
  );

  const [showRangedFields, setShowRangedFields] = useState(
    company?.settings?.calculatingMethodOfOverweight === "single-range" ||
      company?.settings?.calculatingMethodOfOverweight === "set-range"
  );
  const [disabledLegacy, setDisabledLegacy] = useState<boolean>(
    company?.companyType
  );
  const [currencies, setCurrencies] = useState<[]>([]);

  const openPrompt = () => {
    dispatch(setView(true));
  };

  const [baggageRanges, setBaggageRanges] = useState<any>([]);

  const [carryOnRanges, setCarryOnRanges] = useState<any>([]);

  const [luggageRanges, setLuggageRanges] = useState<any>([]);

  const checkOverlap = (
    start: number,
    end: number,
    intervals: any[],
    editedId: number | null = null
  ) => {
    for (let i = 0; i < intervals.length; i++) {
      const interval = intervals[i];

      if (
        interval.id !== editedId &&
        ((start >= interval.minRangeWeight &&
          start < interval.maxRangeWeight) ||
          (end > interval.minRangeWeight && end < interval.maxRangeWeight) ||
          (start <= interval.minRangeWeight && end >= interval.maxRangeWeight))
      ) {
        return true;
      }
    }
    return false;
  };

  const onEditLuggageRange = async (
    data: any,
    type: string,
    close: () => void,
    isAsync: boolean
  ) => {
    const newMinRangeWeight = parseFloat(watch("minRangeWeightNew"));
    const newMaxRangeWeight = parseFloat(watch("maxRangeWeightNew"));

    if (!watch("minRangeWeightNew") || !watch("priceNew")) {
      console.log(watch("minRangeWeightNew"));
      toast.error("You should add min/price");
      return;
    }

    if (newMinRangeWeight >= newMaxRangeWeight) {
      toast.error("Min range must be less then max range");
      return;
    }

    if (type === "CarryOnBag") {
      const hasMaxRangeWeight = carryOnRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !newMaxRangeWeight) {
        toast.error("We already have infinity range");
        return;
      }

      if (
        checkOverlap(
          newMinRangeWeight,
          newMaxRangeWeight,
          carryOnRanges,
          data.id
        )
      ) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      const updatedCarryOnRanges = carryOnRanges.map(
        (range: any, index: number) => {
          if (index === data.id || range.id === data.id) {
            return {
              id: data.id,
              type: "CarryOnBag",
              minRangeWeight: Number(watch("minRangeWeightNew")),
              maxRangeWeight: watch("maxRangeWeightNew")
                ? Number(watch("maxRangeWeightNew"))
                : +Infinity,
              price: Number(watch("priceNew")),
            };
          }
          return range;
        }
      );

      setCarryOnRanges(updatedCarryOnRanges);
    }

    if (type === "Baggage") {
      const hasMaxRangeWeight = baggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !newMaxRangeWeight) {
        toast.error("We already have infinity range");
        return;
      }

      if (
        checkOverlap(
          newMinRangeWeight,
          newMaxRangeWeight,
          baggageRanges,
          data.id
        )
      ) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      const updatedBaggageRanges = baggageRanges.map(
        (range: any, index: number) => {
          if (index === data.id || range.id === data.id) {
            return {
              id: data.id,
              type: "Baggage",
              minRangeWeight: Number(watch("minRangeWeightNew")),
              maxRangeWeight: watch("maxRangeWeightNew")
                ? Number(watch("maxRangeWeightNew"))
                : +Infinity,
              price: Number(watch("priceNew")),
            };
          }
          return range;
        }
      );

      setBaggageRanges(updatedBaggageRanges);

      if (isAsync) {
        await checkinchService.updateAirlineLuggageRange({
          id: data.id,
          type: type,
          minRangeWeight: Number(watch("minRangeWeightNew")),
          maxRangeWeight: Number(watch("maxRangeWeightNew")) || undefined,
          price: Number(watch("priceNew")),
        });
      }
    }

    if (type === "Luggage") {
      const hasMaxRangeWeight = luggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity;
      });

      if (hasMaxRangeWeight && !newMaxRangeWeight) {
        toast.error("We already have infinity range");
        return;
      }

      if (
        checkOverlap(
          newMinRangeWeight,
          newMaxRangeWeight,
          luggageRanges,
          data.id
        )
      ) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      const updatedLuggageRanges = luggageRanges.map(
        (range: any, index: number) => {
          if (index === data.id || range.id === data.id) {
            return {
              id: data.id,
              type: "Luggage",
              minRangeWeight: Number(watch("minRangeWeightNew")),
              maxRangeWeight: watch("maxRangeWeightNew")
                ? Number(watch("maxRangeWeightNew"))
                : +Infinity,
              price: Number(watch("priceNew")),
            };
          }
          return range;
        }
      );

      setLuggageRanges(updatedLuggageRanges);

      if (isAsync) {
        await checkinchService.updateAirlineLuggageRange({
          id: data.id,
          type: type,
          minRangeWeight: Number(watch("minRangeWeightNew")),
          maxRangeWeight: Number(watch("maxRangeWeightNew")) || undefined,
          price: Number(watch("priceNew")),
        });
      }
    }
    close();
  };

  const onAddLuggageRange = () => {
    const newMinRangeWeight = parseFloat(watch("minRangeWeight"));
    const newMaxRangeWeight = parseFloat(watch("maxRangeWeight"));

    if (!watch("luggageType")) {
      toast.error("Choose luggage type");
      return;
    }

    if (!watch("minRangeWeight") || !watch("price")) {
      toast.error("You should add min/price");
      return;
    }

    if (newMinRangeWeight >= newMaxRangeWeight) {
      toast.error("Min range must be less then max range");
      return;
    }

    if (watch("luggageType") === "Baggage") {
      const hasMaxRangeWeight = baggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity || obj.maxRangeWeight === null;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, baggageRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setBaggageRanges((prev: any) => [
        ...prev,
        {
          type: "Baggage",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
          newItem: true,
        },
      ]);
    }

    if (watch("luggageType") === "CarryOnBag") {
      const hasMaxRangeWeight = carryOnRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity || obj.maxRangeWeight === null;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, carryOnRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setCarryOnRanges((prev: any) => [
        ...prev,
        {
          type: "CarryOnBag",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
          newItem: true,
        },
      ]);
    }

    if (watch("luggageType") === "Luggage") {
      const hasMaxRangeWeight = luggageRanges.some((obj: any) => {
        return obj.maxRangeWeight === +Infinity || obj.maxRangeWeight === null;
      });

      if (hasMaxRangeWeight && !watch("maxRangeWeight")) {
        toast.error("We already have infinity range");
        return;
      }

      if (checkOverlap(newMinRangeWeight, newMaxRangeWeight, luggageRanges)) {
        toast.error("New ranges shouldn't be in current ranges");
        return;
      }

      setLuggageRanges((prev: any) => [
        ...prev,
        {
          type: "Luggage",
          minRangeWeight: Number(watch("minRangeWeight")),
          maxRangeWeight: watch("maxRangeWeight")
            ? Number(watch("maxRangeWeight"))
            : +Infinity,
          price: Number(watch("price")),
          newItem: true,
        },
      ]);
    }
  };

  const getAirlineLuggageRanges = async (companyId: any) => {
    setLoading(true);
    await checkinchService
      .getAirlineLuggageRanges(companyId)
      .then((res) => {
        setBaggageRanges(
          res?.data?.baggageRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setCarryOnRanges(
          res?.data?.carryOnBagRanges?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
        setLuggageRanges(
          res?.data?.luggageRange?.map(function (obj: any) {
            if (obj.maxRangeWeight === null) {
              return Object.assign({}, obj, { maxRangeWeight: Infinity });
            }

            return obj;
          }) || []
        );
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // //navigate('/404')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCountries = async (e: any) => {
    let inputText = e.target.value;
    let result = await checkinchService.getCountriesCities(
      inputText,
      "country"
    );
    setCountries(result?.data);
  };

  const handleCities = async (e: any) => {
    let inputText = e.target.value;
    let result = await checkinchService.getCountriesCities(
      inputText,
      "(cities)"
    );
    setCities(result?.data);
  };

  const debounceHandleCountries = useCallback(
    debounce(handleCountries, 300),
    []
  );
  const debounceHandleCities = useCallback(debounce(handleCities, 300), []);

  const uploadLogo = async (event: any) => {
    const formData = new FormData();
    if (event.target.files[0]) {
      setFileName("Loading..");
      let file = event.target.files[0];
      formData.append("file", file);
      let result = await checkinchService.updateLogo(formData, id);
      setLogoLink(result?.data.url);
      if (result?.status === 200) {
        setFileName(event.target.files[0].name);
        toast.success("Logo was uploaded");
      } else {
        toast.warn("Logo wasn't uploaded");
      }
    }
    event.target.value = "";
  };

  const onSubmit = async (data: any) => {
    const parsedData = {
      ...data,
      floor: +data.floor || undefined,
      id: parseInt(id!, 10),
      companyType: +data.companyType,
    };

    if (!disabledLegacy) {
      parsedData.maxHandbagWeight = null;
      parsedData.maxCarryOnBagWeight = null;
    } else {
      parsedData.maxCarryOnWeight = null;
    }

    if (!showOverWeightFields) {
      delete parsedData.maxCarryOnBagWeightForRanges;
      delete parsedData.maxHandLuggageWeightForRanges;
    }

    if (showRangedFields) {
      parsedData.maxHandbagWeight = null;
      parsedData.maxCarryOnBagWeight = null;
      parsedData.maxCarryOnWeight = null;
      parsedData.bagWeightRanges = !![
        ...baggageRanges,
        ...carryOnRanges,
        ...luggageRanges,
      ].length
        ? [...baggageRanges, ...carryOnRanges, ...luggageRanges]
        : null;
    }

    console.log(parsedData);
    dataGridService.removeEmptyFields(parsedData);
    setLoading(true);
    await checkinchService
      .updateAirlineCompany(parsedData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(TEXT.NOTIFY_AIRLINE_EDIT);
          navigate(-1);
        } else {
          toast.error(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!Object.keys(company).length) {
    navigate(`/singleview/${id}`);
  }

  const onDeleteRange = async (
    rangeId: number,
    rangeIndex: number,
    luggageType: string
  ) => {
    if (luggageType === "CarryOnBag") {
      setCarryOnRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== rangeIndex
        );
        return updatedRanges;
      });
    }
    if (luggageType === "Baggage") {
      setBaggageRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== rangeIndex
        );
        return updatedRanges;
      });
    }

    if (luggageType === "Luggage") {
      setLuggageRanges((prevRanges: any) => {
        const updatedRanges = prevRanges.filter(
          (_: any, index: number) => index !== rangeIndex
        );
        return updatedRanges;
      });
    }
    await checkinchService.deleteAirlineLuggageRanges(rangeId);
  };

  useEffect(() => {
    getAirlineLuggageRanges(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showOverWeightFields) {
      setValue("maxHandLuggageWeightForRanges", 0);
      setValue("maxCarryOnBagWeightForRanges", 0);
    } else {
      setValue(
        "maxHandLuggageWeightForRanges",
        company?.settings?.maxHandLuggageWeightForRanges || ""
      );
      setValue(
        "maxCarryOnBagWeightForRanges",
        company?.settings?.maxCarryOnBagWeightForRanges || ""
      );
    }
  }, [setValue, showOverWeightFields]);

  useEffect(() => {
    if (showRangedFields) {
      setValue("maxHandbagWeight", 0);
      setValue("maxCarryOnBagWeight", 0);
      setValue("maxCarryOnWeight", 0);
      setValue("paymentForBaggageOverweight", 0);
    } else {
      setValue("paymentForBaggageOverweight", "");
    }
  }, [setValue, showRangedFields]);

  useEffect(() => {
    if (!showRangedFields) {
      if (!disabledLegacy) {
        setValue("maxHandbagWeight", company.settings?.maxHandbagWeight || 0);
        setValue(
          "maxCarryOnBagWeight",
          company.settings?.maxCarryOnBagWeight || 0
        );
        setValue("maxCarryOnWeight", company.settings?.maxCarryOnWeight || "");
      } else {
        setValue("maxHandbagWeight", company.settings?.maxHandbagWeight || "");
        setValue(
          "maxCarryOnBagWeight",
          company.settings?.maxCarryOnBagWeight || ""
        );
        setValue("maxCarryOnWeight", company.settings?.maxCarryOnWeight || 0);
      }
    }
  }, [
    company.settings?.maxCarryOnBagWeight,
    company.settings?.maxCarryOnWeight,
    company.settings?.maxHandbagWeight,
    disabledLegacy,
    setValue,
    showRangedFields,
  ]);

  useEffect(() => {
    checkinchService.getCurrencies().then((res) => {
      setCurrencies(res?.data);
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <div className={styles.form_box}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Edit Airline Company</h3>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& .MuiTextField-root": { m: 1, width: "275px" },
          }}
        >
          <div className={styles.section_input}>
            <Divider textAlign="left">Main info</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.companyName?.message}
                    </small>
                  </div>
                )}
                name="companyName"
                control={control}
                defaultValue={company.companyName}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company short name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.shortCompanyName?.message}
                    </small>
                  </div>
                )}
                name="shortCompanyName"
                control={control}
                defaultValue={company.shortCompanyName}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="BRN *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.legalEntityId?.message}
                    </small>
                  </div>
                )}
                name="legalEntityId"
                control={control}
                defaultValue={company.legalEntityId}
              />
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={countries}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValueCountry(newValue);
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="country"
                          {...params}
                          label="Enter a country *"
                          onChange={debounceHandleCountries}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.country?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="country"
                control={control}
                defaultValue={valueCountry}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-timeZone"
                      select
                      label="Time zone *"
                      {...field}
                    >
                      {timeZones.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <small className={styles.error}>
                      {errors.timeZone?.message}
                    </small>
                  </div>
                )}
                name="timeZone"
                control={control}
                defaultValue={company.timeZone}
              />
              <div className={styles.upload_logo_wrapper}>
                <Button
                  className={styles.upload_button}
                  variant="outlined"
                  component="label"
                >
                  Update logo
                  <input
                    accept="image/*"
                    onChange={uploadLogo}
                    type="file"
                    hidden
                  />
                </Button>
                <p>{fileName}</p>
              </div>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                      label="Company phone *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.phone?.message}
                    </small>
                  </div>
                )}
                name="phone"
                control={control}
                defaultValue={company.phone}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Company email *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.email?.message}
                    </small>
                  </div>
                )}
                name="email"
                control={control}
                defaultValue={company.email}
              />
              <div className={styles.checkBox}>
                <FormLabel component="legend">Status</FormLabel>
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                  defaultValue={company.isActive}
                />
                <span>Active</span>
              </div>
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Address</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ width: 292 }}
                    {...field}
                    options={cities}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValueCity(newValue);
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="city"
                          {...params}
                          label="Enter a city *"
                          onChange={debounceHandleCities}
                          inputRef={ref}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                        <small className={styles.error}>
                          {errors.city?.message}
                        </small>
                      </>
                    )}
                  />
                )}
                name="city"
                control={control}
                defaultValue={valueCity}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Street *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.street?.message}
                    </small>
                  </div>
                )}
                name="street"
                control={control}
                defaultValue={company.address?.street}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Building"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.building?.message}
                    </small>
                  </div>
                )}
                name="building"
                control={control}
                defaultValue={company.address?.building}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Floor"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.floor?.message}
                    </small>
                  </div>
                )}
                name="floor"
                control={control}
                defaultValue={company.address?.floor}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                      label="Office number"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.officeNumber?.message}
                    </small>
                  </div>
                )}
                name="officeNumber"
                control={control}
                defaultValue={company.address?.officeNumber}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Zip code"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.zipCode?.message}
                    </small>
                  </div>
                )}
                name="zipCode"
                control={control}
                defaultValue={company.address?.zipCode}
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Contact</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="First name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personFirstname?.message}
                    </small>
                  </div>
                )}
                name="personFirstname"
                control={control}
                defaultValue={company.contact?.personFirstname}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Last name *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personLastname?.message}
                    </small>
                  </div>
                )}
                name="personLastname"
                control={control}
                defaultValue={company.contact?.personLastname}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) => dataGridService.phoneRegExp(event)}
                      label="Phone number *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personPhone?.message}
                    </small>
                  </div>
                )}
                name="personPhone"
                control={control}
                defaultValue={company.contact?.personPhone}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Email *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personEmail?.message}
                    </small>
                  </div>
                )}
                name="personEmail"
                control={control}
                defaultValue={company.contact?.personEmail}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Role"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.personRole?.message}
                    </small>
                  </div>
                )}
                name="personRole"
                control={control}
                defaultValue={company.contact?.personRole}
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Admin settings</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-companyType"
                      select
                      label="Airline company type *"
                      {...field}
                      onChange={(e) => {
                        setValue("companyType", e.target.value);
                        setValue("calculatingMethodOfOverweight", "Set");
                        setDisabledLegacy(Boolean(e.target.value));
                      }}
                    >
                      <MenuItem value={0}>Legacy</MenuItem>
                      <MenuItem value={1}>Low cost</MenuItem>
                    </TextField>
                    <small className={styles.error}>
                      {errors.companyType?.message}
                    </small>
                  </div>
                )}
                name="companyType"
                control={control}
                defaultValue={company?.companyType}
              />
            </div>
          </div>
          <div className={styles.section_input}>
            <Divider textAlign="left">Operational settings</Divider>

            <div className={styles.wrap_section}>
              <p className={styles.section_p}>Size settings</p>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage height *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxLength?.message}
                    </small>
                  </div>
                )}
                name="maxLength"
                control={control}
                defaultValue={company.settings?.maxLength}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage width *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxWidth?.message}
                    </small>
                  </div>
                )}
                name="maxWidth"
                control={control}
                defaultValue={company.settings?.maxWidth}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Max hand luggage depth *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.maxDepth?.message}
                    </small>
                  </div>
                )}
                name="maxDepth"
                control={control}
                defaultValue={company.settings?.maxDepth}
              />

              {disabledLegacy && !showRangedFields ? (
                <>
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Max carry on bag weight *"
                          {...field}
                          className="materialUIInput"
                        />
                        <small className={styles.error}>
                          {errors.maxCarryOnBagWeight?.message}
                        </small>
                      </div>
                    )}
                    name="maxCarryOnBagWeight"
                    control={control}
                    defaultValue={company.settings?.maxCarryOnBagWeight}
                  />
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          onKeyDown={(event) =>
                            dataGridService.operationalSettingsRegExp(event)
                          }
                          label="Max hand luggage weight *"
                          {...field}
                          className="materialUIInput"
                        />
                        <small className={styles.error}>
                          {errors.maxHandbagWeight?.message}
                        </small>
                      </div>
                    )}
                    name="maxHandbagWeight"
                    control={control}
                    defaultValue={company.settings?.maxHandbagWeight}
                  />
                </>
              ) : (
                <>
                  {!showRangedFields && (
                    <>
                      <p className={styles.section_p}>
                        Weight settings carry on
                      </p>
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Max carry on weight *"
                              {...field}
                              className="materialUIInput"
                            />
                            <small className={styles.error}>
                              {errors.maxCarryOnWeight?.message}
                            </small>
                          </div>
                        )}
                        name="maxCarryOnWeight"
                        control={control}
                        defaultValue={company.settings?.maxCarryOnWeight}
                      />
                    </>
                  )}
                </>
              )}
              <p className={styles.section_p}>Weight settings baggage</p>
              <BaggageSettingsCard
                currency={company.settings?.currency}
                companyIdFromProps={company.id}
              />

              <div className={styles.section_input}>
                <Divider textAlign="left">Weight settings</Divider>
                <div className={styles.wrap_section}>
                  <p className={styles.section_p}>Size settings</p>
                  <Controller
                    render={({ field }) => (
                      <div className={styles.wrap_witherror}>
                        <TextField
                          select
                          label="Weight calculation *"
                          defaultValue={company.settings?.calculatingMethodOfOverweight.toLowerCase()}
                          {...field}
                          onChange={(e) => {
                            setValue(
                              "calculatingMethodOfOverweight",
                              e.target.value
                            );
                            if (
                              e.target.value === "single-range" ||
                              e.target.value === "set-range"
                            ) {
                              setShowRangedFields(true);
                            } else {
                              setShowRangedFields(false);
                            }
                          }}
                        >
                          <MenuItem value="single">Single</MenuItem>
                          <MenuItem value="single-range">Single range</MenuItem>
                          <MenuItem value="set">Set</MenuItem>
                          <MenuItem value="set-range">Set range</MenuItem>
                        </TextField>
                      </div>
                    )}
                    defaultValue={company.settings?.calculatingMethodOfOverweight.toLowerCase()}
                    name="calculatingMethodOfOverweight"
                    control={control}
                  />
                  {showRangedFields && (
                    <>
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              select
                              label="Weight type *"
                              {...field}
                              onChange={(e) => {
                                setValue("weightType", e.target.value);
                                if (e.target.value === "overweight-only") {
                                  setShowOverWeightFields(true);
                                } else {
                                  setShowOverWeightFields(false);
                                  setValue("maxHandLuggageWeightForRanges", 0);
                                  setValue("maxCarryOnBagWeightForRanges", 0);
                                }
                              }}
                            >
                              <MenuItem value="actual-weight">
                                Actual weight
                              </MenuItem>
                              <MenuItem value="overweight-only">
                                Overweight
                              </MenuItem>
                            </TextField>
                          </div>
                        )}
                        defaultValue={company.settings?.weightType}
                        name="weightType"
                        control={control}
                      />
                      {showOverWeightFields && (
                        <>
                          <Controller
                            render={({ field }) => (
                              <div className={styles.wrap_witherror}>
                                <TextField
                                  onKeyDown={(event) =>
                                    dataGridService.operationalSettingsRegExp(
                                      event
                                    )
                                  }
                                  label="Max hand luggage weight for ranges *"
                                  {...field}
                                  className="materialUIInput"
                                />
                                <small className={styles.error}>
                                  {
                                    errors.maxHandLuggageWeightForRanges
                                      ?.message
                                  }
                                </small>
                              </div>
                            )}
                            name="maxHandLuggageWeightForRanges"
                            control={control}
                            defaultValue={
                              company?.settings?.maxHandLuggageWeightForRanges
                            }
                          />
                          <Controller
                            render={({ field }) => (
                              <div className={styles.wrap_witherror}>
                                <TextField
                                  onKeyDown={(event) =>
                                    dataGridService.operationalSettingsRegExp(
                                      event
                                    )
                                  }
                                  label="Max carry on bag weight for ranges *"
                                  {...field}
                                  className="materialUIInput"
                                />
                                <small className={styles.error}>
                                  {errors.maxCarryOnBagWeightForRanges?.message}
                                </small>
                              </div>
                            )}
                            name="maxCarryOnBagWeightForRanges"
                            control={control}
                            defaultValue={
                              company?.settings?.maxCarryOnBagWeightForRanges
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                  <div className={styles.section_input}>
                    <div className={styles.wrap_section}>
                      {showRangedFields && (
                        <Controller
                          render={({ field }) => (
                            <div className={styles.wrap_witherror}>
                              <TextField
                                select
                                label="Luggage type *"
                                {...field}
                              >
                                <MenuItem value="Baggage">Baggage</MenuItem>
                                <MenuItem value="Luggage">Luggage</MenuItem>
                                <MenuItem value="CarryOnBag">
                                  Carry on bag
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                          defaultValue=""
                          name="luggageType"
                          control={control}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {showRangedFields && (
                  <div className={styles.wrap_section}>
                    <Box
                      alignItems="center"
                      sx={{
                        display: "flex",
                        "& .MuiTextField-root": { m: 1, width: "240px" },
                      }}
                    >
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              type="number"
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Min weight range"
                              {...field}
                              className="materialUIInput"
                            />
                          </div>
                        )}
                        name="minRangeWeight"
                        control={control}
                        defaultValue=""
                      />
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              type="number"
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Max weight range"
                              {...field}
                              className="materialUIInput"
                            />
                          </div>
                        )}
                        name="maxRangeWeight"
                        control={control}
                        defaultValue=""
                      />
                      <Controller
                        render={({ field }) => (
                          <div className={styles.wrap_witherror}>
                            <TextField
                              type="number"
                              onKeyDown={(event) =>
                                dataGridService.operationalSettingsRegExp(event)
                              }
                              label="Price"
                              {...field}
                              className="materialUIInput"
                            />
                          </div>
                        )}
                        name="price"
                        control={control}
                        defaultValue=""
                      />
                      <Button
                        className={styles.addButton}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={onAddLuggageRange}
                      >
                        Add
                      </Button>
                    </Box>
                    {!!baggageRanges.length && (
                      <div className={styles.section_input}>
                        <Divider style={{ marginBottom: "10px" }}>
                          Baggages ranges
                        </Divider>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                            padding: "0 16px",
                          }}
                        >
                          <div>Min weight</div>
                          <div>Max weight</div>
                          <div style={{ width: "60px" }}>Price</div>
                        </div>
                        {baggageRanges?.map((item: any, index: number) => {
                          return (
                            <ListItem disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                style={{
                                  justifyContent: "space-between",
                                  height: "52px",
                                }}
                              >
                                <div>{item.minRangeWeight}</div>
                                <div>{item.maxRangeWeight}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  {item.price}
                                  {!item.newItem && (
                                    <Popup
                                      trigger={
                                        <IconButton
                                          onClick={(e) => {}}
                                          edge="end"
                                          aria-label="comments"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      }
                                      className={styles.popoverWrapper}
                                      onClose={() => {
                                        //   resetField("minRangeWeightNew");
                                        //   resetField("maxRangeWeightNew");
                                        //   resetField("priceNew");
                                      }}
                                      onOpen={() => {
                                        setValue(
                                          "minRangeWeightNew",
                                          item.minRangeWeight
                                        );
                                        setValue(
                                          "maxRangeWeightNew",
                                          item.maxRangeWeight
                                        );
                                        setValue("priceNew", item.price);
                                      }}
                                      modal
                                      nested
                                    >
                                      {(close: any) => (
                                        <div className={styles.modal}>
                                          <button
                                            className={styles.close}
                                            onClick={close}
                                          >
                                            &times;
                                          </button>
                                          <div>
                                            <div className={styles.content}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  "& .MuiTextField-root": {
                                                    m: 1,
                                                    width: "200px",
                                                  },
                                                }}
                                              >
                                                <div className={styles.product}>
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          defaultValue={
                                                            item?.minRangeWeight
                                                          }
                                                          label="Min weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                        />
                                                      </div>
                                                    )}
                                                    name="minRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.minRangeWeight
                                                    }
                                                  />
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          defaultValue={
                                                            item?.maxRangeWeight
                                                          }
                                                          label="Max weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                        />
                                                      </div>
                                                    )}
                                                    name="maxRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.maxRangeWeight
                                                    }
                                                  />

                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          defaultValue={
                                                            item?.price
                                                          }
                                                          label="Price"
                                                          {...field}
                                                          className="materialUIInput"
                                                        />
                                                      </div>
                                                    )}
                                                    name="priceNew"
                                                    control={control}
                                                    defaultValue={item?.price}
                                                  />

                                                  <div
                                                    className={
                                                      styles.buttonWrapper
                                                    }
                                                  >
                                                    <Button
                                                      className={styles.button}
                                                      color="primary"
                                                      variant="contained"
                                                      size="small"
                                                      onClick={(e) => {
                                                        onEditLuggageRange(
                                                          {
                                                            id: item?.id
                                                              ? item.id
                                                              : index,
                                                            minRangeWeight:
                                                              item.minRangeWeight,
                                                            maxRangeWeight:
                                                              item.maxRangeWeight,
                                                            price: item.price,
                                                          },
                                                          "Baggage",
                                                          () => close(),
                                                          item?.id
                                                            ? true
                                                            : false
                                                        );
                                                      }}
                                                    >
                                                      Save
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Box>
                                            </div>
                                            <div className={styles.button}>
                                              <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                type="submit"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                Close
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  )}
                                  <IconButton
                                    onClick={(e) => {
                                      onDeleteRange(item.id, index, "Baggage");
                                    }}
                                    edge="end"
                                    aria-label="comments"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </div>
                    )}
                    {!!luggageRanges.length && (
                      <div className={styles.section_input}>
                        <Divider style={{ marginBottom: "10px" }}>
                          Luggage ranges
                        </Divider>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                            padding: "0 16px",
                          }}
                        >
                          <div>Min weight</div>
                          <div>Max weight</div>
                          <div style={{ width: "60px" }}>Price</div>
                        </div>
                        {luggageRanges?.map((item: any, index: number) => {
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                style={{
                                  justifyContent: "space-between",
                                  height: "52px",
                                }}
                              >
                                <div>{item.minRangeWeight}</div>
                                <div>{item.maxRangeWeight}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  {item.price}
                                  {!item.newItem && (
                                    <Popup
                                      trigger={
                                        <IconButton
                                          onClick={(e) => {}}
                                          edge="end"
                                          aria-label="comments"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      }
                                      // onClose={() => {
                                      //   resetField("minRangeWeightNew");
                                      //   resetField("maxRangeWeightNew");
                                      //   resetField("priceNew");
                                      // }}
                                      onOpen={() => {
                                        setValue(
                                          "minRangeWeightNew",
                                          item.minRangeWeight
                                        );
                                        setValue(
                                          "maxRangeWeightNew",
                                          item.maxRangeWeight
                                        );
                                        setValue("priceNew", item.price);
                                      }}
                                      className={styles.popoverWrapper}
                                      modal
                                      nested
                                    >
                                      {(close: any) => (
                                        <div className={styles.modal}>
                                          <button
                                            className={styles.close}
                                            onClick={close}
                                          >
                                            &times;
                                          </button>
                                          <div>
                                            <div className={styles.content}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  "& .MuiTextField-root": {
                                                    m: 1,
                                                    width: "200px",
                                                  },
                                                }}
                                              >
                                                <div className={styles.product}>
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Min weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.minRangeWeight
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="minRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.minRangeWeight
                                                    }
                                                  />
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Max weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.maxRangeWeight
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="maxRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.maxRangeWeight
                                                    }
                                                  />

                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Price"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.price
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="priceNew"
                                                    control={control}
                                                    defaultValue={item?.price}
                                                  />

                                                  <div
                                                    className={
                                                      styles.buttonWrapper
                                                    }
                                                  >
                                                    <Button
                                                      className={styles.button}
                                                      color="primary"
                                                      variant="contained"
                                                      size="small"
                                                      onClick={(e) => {
                                                        onEditLuggageRange(
                                                          {
                                                            id: item?.id
                                                              ? item.id
                                                              : index,
                                                            minRangeWeight:
                                                              item.minRangeWeight,
                                                            maxRangeWeight:
                                                              item.maxRangeWeight,
                                                            price: item.price,
                                                          },
                                                          "Luggage",
                                                          () => close(),
                                                          item?.id
                                                            ? true
                                                            : false
                                                        );
                                                      }}
                                                    >
                                                      Save
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Box>
                                            </div>
                                            <div className={styles.button}>
                                              <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                type="submit"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                Close
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  )}
                                  <IconButton
                                    onClick={(e) => {
                                      onDeleteRange(item.id, index, "Luggage");
                                    }}
                                    edge="end"
                                    aria-label="comments"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </div>
                    )}
                    {!!carryOnRanges.length && (
                      <div className={styles.section_input}>
                        <Divider style={{ marginBottom: "10px" }}>
                          Carry on ranges
                        </Divider>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                            padding: "0 16px",
                          }}
                        >
                          <div>Min weight</div>
                          <div>Max weight</div>
                          <div style={{ width: "60px" }}>Price</div>
                        </div>
                        {carryOnRanges?.map((item: any, index: number) => {
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                style={{
                                  justifyContent: "space-between",
                                  height: "52px",
                                }}
                              >
                                <div>{item.minRangeWeight}</div>
                                <div>{item.maxRangeWeight}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  {item.price}
                                  {!item.newItem && (
                                    <Popup
                                      trigger={
                                        <IconButton
                                          onClick={(e) => {}}
                                          edge="end"
                                          aria-label="comments"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      }
                                      // onClose={() => {
                                      //   resetField("minRangeWeightNew");
                                      //   resetField("maxRangeWeightNew");
                                      //   resetField("priceNew");
                                      // }}
                                      onOpen={() => {
                                        setValue(
                                          "minRangeWeightNew",
                                          item.minRangeWeight
                                        );
                                        setValue(
                                          "maxRangeWeightNew",
                                          item.maxRangeWeight
                                        );
                                        setValue("priceNew", item.price);
                                      }}
                                      className={styles.popoverWrapper}
                                      modal
                                      nested
                                    >
                                      {(close: any) => (
                                        <div className={styles.modal}>
                                          <button
                                            className={styles.close}
                                            onClick={close}
                                          >
                                            &times;
                                          </button>
                                          <div>
                                            <div className={styles.content}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  "& .MuiTextField-root": {
                                                    m: 1,
                                                    width: "200px",
                                                  },
                                                }}
                                              >
                                                <div className={styles.product}>
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Min weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.minRangeWeight
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="minRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.minRangeWeight
                                                    }
                                                  />
                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Max weight range"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.maxRangeWeight
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="maxRangeWeightNew"
                                                    control={control}
                                                    defaultValue={
                                                      item?.maxRangeWeight
                                                    }
                                                  />

                                                  <Controller
                                                    render={({ field }) => (
                                                      <div
                                                        className={
                                                          styles.wrap_witherror
                                                        }
                                                      >
                                                        <TextField
                                                          type="number"
                                                          onKeyDown={(event) =>
                                                            dataGridService.operationalSettingsRegExp(
                                                              event
                                                            )
                                                          }
                                                          label="Price"
                                                          {...field}
                                                          className="materialUIInput"
                                                          defaultValue={
                                                            item?.price
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                    name="priceNew"
                                                    control={control}
                                                    defaultValue={item?.price}
                                                  />

                                                  <div
                                                    className={
                                                      styles.buttonWrapper
                                                    }
                                                  >
                                                    <Button
                                                      className={styles.button}
                                                      color="primary"
                                                      variant="contained"
                                                      size="small"
                                                      onClick={(e) => {
                                                        onEditLuggageRange(
                                                          {
                                                            id: item?.id
                                                              ? item.id
                                                              : index,
                                                            minRangeWeight:
                                                              item.minRangeWeight,
                                                            maxRangeWeight:
                                                              item.maxRangeWeight,
                                                            price: item.price,
                                                          },
                                                          "CarryOnBag",
                                                          () => close(),
                                                          item?.id
                                                            ? true
                                                            : false
                                                        );
                                                      }}
                                                    >
                                                      Save
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Box>
                                            </div>
                                            <div className={styles.button}>
                                              <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                type="submit"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                Close
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  )}
                                  <IconButton
                                    onClick={(e) => {
                                      onDeleteRange(
                                        item.id,
                                        index,
                                        "CarryOnBag"
                                      );
                                    }}
                                    edge="end"
                                    aria-label="comments"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Check in duration *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.checkinTime?.message}
                    </small>
                  </div>
                )}
                name="checkinTime"
                control={control}
                defaultValue={company?.settings?.checkinTime}
              />
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Notify steward *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.notifySteward?.message}
                    </small>
                  </div>
                )}
                name="notifySteward"
                control={control}
                defaultValue={company?.settings?.notifySteward}
              />
            </div>
          </div>

          <div style={{ marginTop: "10px" }} className={styles.section_input}>
            <Divider textAlign="left">Payment settings</Divider>
            <div className={styles.wrap_section}>
              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      onKeyDown={(event) =>
                        dataGridService.operationalSettingsRegExp(event)
                      }
                      label="Hand luggage payment for oversize *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.paymentForOversize?.message}
                    </small>
                  </div>
                )}
                name="paymentForOversize"
                control={control}
                defaultValue={company?.settings?.paymentForOversize}
              />
              {!showRangedFields && (
                <Controller
                  render={({ field }) => (
                    <div className={styles.wrap_witherror}>
                      <TextField
                        onKeyDown={(event) =>
                          dataGridService.operationalSettingsRegExp(event)
                        }
                        label="Payment for overweight *"
                        {...field}
                        className="materialUIInput"
                      />
                      <small className={styles.error}>
                        {errors.paymentForBaggageOverweight?.message}
                      </small>
                    </div>
                  )}
                  name="paymentForBaggageOverweight"
                  control={control}
                  defaultValue={company?.settings?.paymentForBaggageOverweight}
                />
              )}

              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Currency *"
                      {...field}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <small className={styles.error}>
                      {errors.currency?.message}
                    </small>
                  </div>
                )}
                name="currency"
                control={control}
                defaultValue={company?.settings?.currency}
              />

              <Controller
                render={({ field }) => (
                  <div className={styles.wrap_witherror}>
                    <TextField
                      label="Business ID *"
                      {...field}
                      className="materialUIInput"
                    />
                    <small className={styles.error}>
                      {errors.business_id?.message}
                    </small>
                  </div>
                )}
                name="business_id"
                control={control}
                defaultValue={company?.settings?.business_id}
              />
            </div>
          </div>
        </Box>
        <ProductCard
          currency={company?.settings?.currency}
          companyIdFromProps={company.id}
        />
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          size="large"
          type="submit"
        >
          Submit
        </Button>

        <Button
          className={styles.button}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => openPrompt()}
        >
          Cancel
        </Button>
      </form>

      {isView ? <PromptCancel /> : ""}
    </div>
  );
};

export default AirlineEditGrid;
