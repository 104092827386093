import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GridColDef } from "@mui/x-data-grid";

import Grid from "../../../components/Grid/Grid";
import { handlingManagerService } from "../../../api/apiManager";
import { dateParseToDate, dateParseToTime } from "../../../common/dateFormatter";
import FlightsFilterHandlingManager
    from "../../../common/Filters/FlightsFilterHandlingManager/FlightsFilterHandlingManager";

import styles from "./HandlingActions.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        width: 65,
    },
    {
        field: 'flightUniqueId',
        headerName: 'Flight unique ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,

    },
    {
        field: 'flightNumber',
        headerName: 'Flight number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'departureIata',
        headerName: 'Origin',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'arrivalIata',
        headerName: 'Destination',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'departureDateTime',
        headerName: 'Departure date',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))

        },
    },
    {
        field: 'arrivalDateTime',
        headerName: 'Arrival date',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))

        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'creationOrigin',
        headerName: 'Creation origin',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))

        },
    },

];

const FlightList = () => {

    const [info, setInfo] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    let dataFromFilters = useSelector((state: any) => state.handlingManagerPage.filter)
    let flights = useSelector((state: any) => state.handlingManagerPage.flight)

    const getHandlings = async () => {
        setLoading(true)
        await handlingManagerService.getFlightList(dataFromFilters).then((res) => {
            setInfo(res?.data);
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getHandlings()
    }, []);

    useEffect(() => {
        setInfo(flights);
    }, [flights]);

    return (
        <div className={styles.data_grid}>
            <FlightsFilterHandlingManager/>
            <h3>Data</h3>
            <Grid
                columns={columns}
                rows={info}
                loading={loading}
                singleview="/flight-view"
            />
        </div>
    );
}

export default FlightList;
