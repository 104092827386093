import { useEffect, useState } from 'react';
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@mui/material/Button";

import { checkinchService } from "../../../api/apiManager";
import Loader from "../../../components/Loader/Loader";
import { setCheckinch } from "../../../redux/features/checkinchSlice";
import { dateParseToDate, dateParseToTime } from '../../../common/dateFormatter';

import styles from "./CheckinchSingleView.module.scss";

const CheckinchSingleView = () => {

    let [user, setUser] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch()
    const getCheckinchSelf = async () => {
        let result = await checkinchService.getCheckinchSelf()
        setUser(result?.data)
        dispatch(setCheckinch(result?.data))
        setLoading(false)
    }

    useEffect(() => {
        getCheckinchSelf()
    }, [])


    if (loading) return <Loader/>

    return (
        <div className={styles.userView}>
            <div className={styles.app}>
                <Table>
                    <tbody>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">ID</TableCell>
                        <TableCell>{user?.id}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Worker ID</TableCell>
                        <TableCell>{user?.workerId}</TableCell>
                    </TableRow>

                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">First name</TableCell>
                        <TableCell>{user?.firstname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Last name</TableCell>
                        <TableCell>{user?.lastname}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Mobile phone number</TableCell>
                        <TableCell>{user?.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Email address</TableCell>
                        <TableCell>{user?.username}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Status</TableCell>
                        <TableCell>{user?.isActive ? "Active" : "Inactive"}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Manager role</TableCell>
                        <TableCell>{user?.managerRoleName}</TableCell>
                    </TableRow>

                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Comments</TableCell>
                        <TableCell>{user?.comments}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Created at</TableCell>
                        <TableCell>{dateParseToDate(user?.created_at)} / {dateParseToTime(user?.created_at)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Updated at</TableCell>
                        <TableCell>{dateParseToDate(user?.updated_at)} / {dateParseToTime(user?.updated_at)}</TableCell>
                    </TableRow>
                    </tbody>

                </Table>
                <div className={styles.button}>
                    <NavLink to={`/checkinch-edit`}>
                        <Button variant="contained">Edit</Button>
                    </NavLink>
                </div>
            </div>
            <div className={styles.user_card}>
                <div className={styles.user_image}>
                    <Avatar
                        name={user?.firstname + ' ' + user?.lastname}
                        maxInitials={2}
                        size="180"
                        round={true}
                        color="#afafaf"
                        className={styles.initials}
                    />
                </div>
                <div className={styles.user_info}>
                    <h4 className={styles.user_name}>
                        {user?.firstname + ' ' + user?.lastname}
                    </h4>
                    <p className={styles.user_role}>
                        Checkinch manager
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CheckinchSingleView;
