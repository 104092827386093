import React, { useEffect } from 'react';

import List from "@mui/material/List";
import { Divider } from "@mui/material";

import { baggageSettingsService } from "../../api/apiManager";
import WeightSettingsView from "./WeightSettingsView";

import styles from "./WeightSettingsListView.module.scss";


const WeightSettingsListView = ({companyId,currency}: any) => {
    const [baggageData, setBaggageData] = React.useState([]);

    const getBaggage = async (companyId: any) => {
        let result = await baggageSettingsService.getBaggage(companyId)
        setBaggageData(result?.data)
    }

    useEffect(() => {
        if(companyId) {
            getBaggage(companyId)
        }
    }, [])


    return (
        <div className={styles.baggageWrapper}>
            <Divider>Baggage weight settings</Divider>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>Status</div>
                <div style={{flex: 6}}>Name</div>
                <div style={{flex: 1}}>Weight</div>
            </div>
            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                <div className={styles.baggageList}>
                    {baggageData?.map((service: any, value) => {
                        return <WeightSettingsView key={service.id} currency={currency} service={service} value={value}/>
                    })}
                </div>

            </List>
        </div>
    );
};

export default WeightSettingsListView;
