import ShiftInfoGrid from "../../../components/ShiftManagerInfoGrid/ShiftInfoGrid";

import styles from "./HandlingActions.module.scss";

const ShiftSingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <ShiftInfoGrid/>
            </div>
        </div>
    );
}

export default ShiftSingleView;
