import HandlingEditSelfGrid from "./HandlingEditSelfGrid";

import styles from "./HandlingEditSelf.module.scss";

const HandlingEditSelf = () => {

    return (
        <div className={styles.data_grid}>
            <HandlingEditSelfGrid/>
        </div>
    );
}

export default HandlingEditSelf;
