import AirManagerCreateGrid from "../../components/AirManagerCreateGrid/AirManagerCreateGrid";

import styles from "./AirManagerActions.module.scss";

const AirManagerCreate = () => {

    return (
        <div className={styles.data_grid}>
            <AirManagerCreateGrid/>
        </div>
    );
}

export default AirManagerCreate;
