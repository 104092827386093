import DashboardFilter from "../../common/Filters/DashboardFilter/DashboardFilter";
import CheckinchMetrics from "./CheckinchMetrics/CheckinchMetrics";

import styles from "./CheckinchDashboard.module.scss"

const CheckinchDashboard = () => {

    return (
        <div>
            <DashboardFilter/>
            <div className={styles.dashboardWrapper}>
                <CheckinchMetrics/>
            </div>
        </div>
    );
};

export default CheckinchDashboard;
