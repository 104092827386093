import Popup from "reactjs-popup";

import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import ModalForm from "./modalForm/ModalForm";

import styles from "./Modal.module.scss";
import "./overlay.css";

const ProductModal = ({ service, companyIdFromProps }: any) => {
  return (
    <Popup
      trigger={
        <IconButton onClick={(e) => {}} edge="end" aria-label="comments">
          <EditIcon />
        </IconButton>
      }
      modal
      nested
    >
      {(close: any) => (
        <div className={styles.modal}>
          <button className={styles.close} onClick={close}>
            &times;
          </button>
          <div>
            <div className={styles.content}>
              <ModalForm
                companyIdFromProps={companyIdFromProps}
                close={close}
                service={service}
              />
            </div>
            <div className={styles.button}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                type="submit"
                onClick={() => {
                  close();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ProductModal;
