import React, { useEffect } from 'react';

import List from "@mui/material/List";
import { Divider } from "@mui/material";

import { productsService } from "../../api/apiManager";
import ProductView from "./ProductView";

import styles from "./ProductListView.module.scss";

const ProductListView = ({companyId,currency}: any) => {
    const [productData, setProductData] = React.useState([]);

    const getProducts = async (companyId: any) => {
        let result = await productsService.getProducts(companyId)
        setProductData(result?.data)
    }

    useEffect(() => {
        if(companyId) {
            getProducts(companyId)
        }
    }, [])

    return (
        <div className={styles.productWrapper}>
            <Divider>Products List</Divider>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>Status</div>
                <div style={{flex: 3}}>Name</div>
                <div style={{flex: 3}}>Category tag</div>
                <div style={{flex: 1}}>Price</div>
            </div>
            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                <div className={styles.productList}>
                    {productData?.map((service: any, value) => {
                        return <ProductView key={service.id} currency={currency} service={service} value={value}/>
                    })}
                </div>

            </List>
        </div>
    );
};

export default ProductListView;
