import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from 'react-avatar';

import { logout } from "../../redux/features/userSlice";
import Header from "../../components/Header/Header";
import AirlineCompanyManagerList from "../../components/NestedLists/AirlineCompanyManagerList";
import PoweredBy from "../../common/PoweredBy/PoweredBy";
import { userService } from "../../services/userService";
import PromptRequest from "../../components/PromptRequest/PromptRequest";

import logoutImg from '../../assets/logout.png'
import styles from './AirlineCompanyManager.module.scss';


const AirlineCompanyManager = (props: any) => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.user)
    const isView = useSelector((store: any) => store?.isDelete.isViewRequest)

    const handleLogout = () => {
        dispatch(logout())
        navigate("/login");
        userService.logout()
    }

    return (
        <div>
            <Header></Header>
            <div className={styles.nestedList}>

                <div className={styles.nestedList_width}>
                    <div className={styles.profile}>
                        <div>
                            <NavLink to={"/airline-company-manager"}>
                                <div className={styles.avatar}>
                                    <Avatar
                                        name={user.user.firstname + " " + user.user.lastname}
                                        maxInitials={2}
                                        size="80"
                                        round={true}
                                        color={'#afafaf'}

                                    />
                                </div>
                            </NavLink>
                        </div>

                        <div>
                            <p>{user.user.firstname + " " + user.user.lastname}</p>
                            <p>Airline company manager</p>
                        </div>
                        <div>
                            <a onClick={() => handleLogout()}>
                                <img src={logoutImg} alt="logout"/>
                            </a>

                        </div>

                    </div>
                    <AirlineCompanyManagerList/>
                    <div>
                        <PoweredBy/>
                    </div>
                </div>

                <div className={styles.content}>
                    <Outlet/>
                </div>
                {isView ? <PromptRequest/> : ''}
            </div>
        </div>
    );
};

export default AirlineCompanyManager;
