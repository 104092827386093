import StewardInfoGrid from "../../components/StewardInfoGrid/StewardInfoGrid";

import styles from "./StewardActions.module.scss";

const StewardSingleView = () => {
    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <StewardInfoGrid/>
            </div>
        </div>
    );
}

export default StewardSingleView;
