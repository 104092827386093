import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider } from "@mui/material";
import List from '@mui/material/List';

import { baggageSettingsService } from "../../api/apiManager";

import { setUpdateFlag } from "../../redux/features/isUpdateFlag";

import styles from './BaggageSettingsCard.module.scss'
import BaggageSetting from "./BaggageSetting/BaggageSetting";


export default function BaggageSettingsCard({companyIdFromProps, currency}: any) {
    const { control, getValues, setValue } = useForm<any>({
        mode: 'onSubmit'
    });

    const [settingsList, setSettingsList] = React.useState([]);
    const flag = useSelector((store: any) => store.isUpdateFlag.flag)

    const dispatch = useDispatch()

    const search = useLocation().search;
    const companyId = new URLSearchParams(search).get('id');

    const handleClick = async (e: any) => {
        e.preventDefault();
        let dataArray: any = {
            companyId: parseInt("", 10),
            baggageName: '',
            maxBaggageWeight: parseInt("", 10),
            isActive: true
        }

        dataArray['companyId'] = (+companyId! || companyIdFromProps);
        dataArray['baggageName'] = getValues("baggageName");
        dataArray['maxBaggageWeight'] = +getValues("maxBaggageWeight");

        const dataJson = JSON.stringify(dataArray)

        try {
            let response = await baggageSettingsService.createBaggage(dataJson)
            if (response?.status === 201) {
                toast.success("Setting created");
                setValue('baggageName', '')
                setValue('maxBaggageWeight', '')
            } else {
                toast.error(response);
            }
        } catch (err) {
            console.error(err)
        } finally {
            dispatch(setUpdateFlag())
        }


    }

    const getBaggage = async (companyId: any) => {
        let result = await baggageSettingsService.getBaggage(companyId || companyIdFromProps)
        setSettingsList(result?.data)
    }

    useEffect(() => {
        setTimeout(() => {
            getBaggage(companyId)
        }, 300)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flag])

    return (
        <div style={{width: "100%"}}>
            <form className={styles.form_box}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTextField-root': {m: 1, width: '275px'},
                    }}
                >

                    <div className={styles.payment}>

                        <Controller
                            render={({field}) => <TextField required  {...field}
                                                            label="Baggage name"
                                                            className="materialUIInput"/>}
                            name="baggageName"
                            control={control}
                            defaultValue=""

                        />
                        <Controller
                            render={({field}) => <TextField required  {...field}
                                                            type="number"
                                                            label="Weight"
                                                            className="materialUIInput"/>}
                            name="maxBaggageWeight"
                            control={control}
                            defaultValue=""
                        />

                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button
                            className={styles.button}
                            color="primary"
                            variant="contained"
                            size="small"
                            type="submit"
                            onClick={(e) => {
                                handleClick(e)

                            }}
                        >
                            Add
                        </Button>
                    </div>
                </Box>
            </form>
            <Divider>Settings</Divider>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>Status</div>
                <div style={{flex: 9.5}}>Name</div>
                <div style={{flex: 1.5}}>Weight</div>
            </div>

            <div className={styles.productList}>
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {settingsList?.map((setting: any, value) => {
                        return <BaggageSetting key={setting.id} companyIdFromProps={companyIdFromProps}
                                        setting={setting}
                                        value={value}/>
                    })}
                </List>
            </div>
        </div>
    );
}
