import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Divider, createFilterOptions } from "@mui/material";
import List from "@mui/material/List";
import { airlineManagerService, productsService } from "../../api/apiManager";
import Product from "./Product/Product";
import { setUpdateFlag } from "../../redux/features/isUpdateFlag";
import styles from "./ProductCard.module.scss";
import { debounce } from "lodash";

export default function ProductCard({ companyIdFromProps, currency }: any) {
  const [isCategories, setIsCategories] = useState([]);
  const [isCustomCategory, setCustomCategory] = useState<any | null>(null);
  const { control, getValues } = useForm<any>({
    mode: "onSubmit",
  });
  const [productData, setProductData] = React.useState([]);

  const filter = createFilterOptions<any>();

  const flag = useSelector((store: any) => store.isUpdateFlag.flag);

  const dispatch = useDispatch();

  const search = useLocation().search;
  const companyId = new URLSearchParams(search).get("id");

  const handleClick = async (e: any) => {
    e.preventDefault();
    let dataArray: any = {
      companyId: parseInt("", 10),
      name: "",
      price: parseInt("", 10),
      isActive: true,
      categoryTitle: "",
    };

    dataArray["companyId"] = +companyId! || companyIdFromProps;
    dataArray["name"] = getValues("name");
    dataArray["price"] = +getValues("price");
    dataArray["categoryTitle"] = getValues("category");

    const dataJson = JSON.stringify(dataArray);

    try {
      let response = await productsService.createProduct(dataJson);
      if (response?.status === 200) {
        toast.success("Product created");
      } else {
        toast.error(response);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUpdateFlag());
    }
  };

  const getProducts = async (companyId: any) => {
    let result = await productsService.getProducts(
      companyId || companyIdFromProps
    );
    setProductData(result?.data);
  };

  const handleCategories = async (e: any) => {
    let inputText = e.target.value;
    await airlineManagerService.getCategories(inputText).then((res) => {
      if (res?.data) {
        setIsCategories(res?.data);
      }
    });
  };

  const debounceHandleCategories = useCallback(
    debounce(handleCategories, 300),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      getProducts(companyId);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  return (
    <>
      <Divider style={{ margin: "20px 0px" }} textAlign="left">
        Add Products
      </Divider>
      <form className={styles.form_box}>
        <Box
          sx={{
            display: "flex",
            "& .MuiTextField-root": { m: 1, width: "240px" },
          }}
        >
          <div className={styles.payment}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  label="Service Name"
                  {...field}
                  className="materialUIInput"
                />
              )}
              name="name"
              control={control}
            />
            <Controller
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  value={isCustomCategory}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setCustomCategory(newValue);
                      field.onChange(newValue);
                    } else if (newValue && newValue.inputValue) {
                      setCustomCategory(newValue.inputValue);
                      field.onChange(newValue?.inputValue);
                    } else {
                      setCustomCategory(newValue);
                      field.onChange(newValue?.title);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={isCategories}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                  sx={{ display: "inline-flex" }}
                  freeSolo
                  componentName="category"
                  renderInput={(params) => (
                    <div className={styles.wrap_witherror}>
                      <TextField
                        required
                        inputRef={ref}
                        {...params}
                        label="Category tag"
                        onChange={debounceHandleCategories}
                      />
                    </div>
                  )}
                />
              )}
              name="category"
              control={control}
              defaultValue={isCustomCategory}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  label="Price"
                  {...field}
                  type="number"
                  className="materialUIInput"
                />
              )}
              name="price"
              control={control}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              size="small"
              type="submit"
              onClick={(e) => {
                handleClick(e);
              }}
            >
              Add
            </Button>
          </div>
        </Box>
        
      </form>
      <Divider>Products List</Divider>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>Status</div>
        <div style={{ flex: 6.2 }}>Name</div>
        <div style={{ flex: 4 }}>Category tag</div>
        <div style={{ flex: 1.5 }}>Price</div>
      </div>
      <div className={styles.productList}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {productData?.map((service: any, value) => (
            <Product
              key={service.id}
              currency={currency}
              companyIdFromProps={companyIdFromProps}
              service={service}
              value={value}
            />
          ))}
        </List>
      </div>
    </>
  );
}
