import AirlineCreateGrid from "../../components/AirlineCreateGrid/AirlineCreateGrid";

import styles from "./AirlineCompany.module.scss";

const AirlineCompanyCreate = () => {

    return (
        <div className={styles.data_grid}>
            <AirlineCreateGrid/>
        </div>
    );
}

export default AirlineCompanyCreate;
