import Avatar from 'react-avatar';

import styles from './UserCard.module.scss';

const UserCard = ({info}: any) => {

    return (
        <div className={styles.user_card}>
            <div className={styles.user_image}>
                <Avatar
                    name={info?.firstname + " " + info?.lastname}
                    maxInitials={2}
                    size="180"
                    round={true}
                    color="#afafaf"
                    className={styles.initials}
                />
            </div>
            <div className={styles.user_info}>
                <h4 className={styles.user_name}>
                    {info?.firstname + " " + info?.lastname}
                </h4>
                <p className={styles.user_role}>
                    {info?.role === 0 ? "Steward" : ""}
                </p>
            </div>

        </div>
    );
}

export default UserCard;
