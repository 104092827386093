import CompanyInfoGrid from "../../../components/CompanyInfoGrid/CompanyInfoGrid";
import styles from "./Company.module.scss";

const CompanySingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <CompanyInfoGrid/>
            </div>
        </div>
    );
}

export default CompanySingleView;
