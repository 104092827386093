import CompanyEditGrid from "../../../components/CompanyEditGrid/CompanyEditGrid";

import styles from "./Company.module.scss";

const CompanyEdit = () => {

    return (
        <div className={styles.data_grid}>
            <CompanyEditGrid/>
        </div>
    );
}

export default CompanyEdit;
