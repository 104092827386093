import { useDispatch } from 'react-redux';
import { setDelete, setView } from '../../redux/features/isDeleteSlice';

import { Button } from '@mui/material';

import TEXT from '../../assets/text.json'
import styles from './Prompt.module.scss'

const PromptDelete = () => {
    const dispatch = useDispatch()
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>{TEXT.PROMPT_DELETE}</h2>
                <p>{TEXT.PROMPT_DELETE_QUSTEION}</p>
                <small>{TEXT.PROMPT_DELETE_WARNING}</small>
                <div className={styles.actions}>
                    <Button variant="contained" onClick={() => dispatch(setDelete(true))}>Submit</Button>
                    <Button
                        className={styles.delete}
                        variant="outlined"
                        onClick={() => dispatch(setView(false))}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PromptDelete;