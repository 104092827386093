import { useEffect, useState } from "react";

import { GridColDef } from "@mui/x-data-grid";

import Grid from "../../components/Grid/Grid";
import { checkinchService } from "../../api/apiManager";

import { ICompanyList } from "../../models/company-list.model";
import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";

import styles from "./AirlineCompany.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        width: 65
    },
    {
        field: 'companyName',
        headerName: 'Company name',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'legalEntityId',
        headerName: 'BRN',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'country',
        headerName: 'Country',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'timeZone',
        headerName: 'Time zone',
        type: 'number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
    },
    {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: (params) => {
            return params.value ? params.value = "Active" : params.value = "Inactive"
        }
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))
        },
    },

];

const AirlineCompanyList = () => {

    const [info, setInfo] = useState<ICompanyList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getAirline = async () => {
        setLoading(true)
        await checkinchService.getAirlineCompanyList().then((res) => {
            setInfo(res?.data);
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getAirline()
    }, []);

    return (
        <div className={styles.data_grid}>
            <h3>Data</h3>
            <Grid
                columns={columns}
                rows={info}
                loading={loading}
                singleview="/singleview"
            />
        </div>
    );
}

export default AirlineCompanyList;
