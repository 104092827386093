import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import CheckinchDropdown from "../CheckinchDropdown/CheckinchDropdown";
import AirManagerDropdown from "../AirManagerDropdown/AirManagerDropdown";
import { authAPI } from "../../api/apiManager";
import { login } from "../../redux/features/userSlice";

import logo from '../../assets/checkinch_logo.png';
import styles from './Header.module.scss'
import { setLoading } from "../../redux/features/isLoadingSlice";

const Header = () => {
    const userRole = useSelector((state: any) => state.user.user.role);
    const isCheckinch = localStorage.getItem('isCheckinch');
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClick = async () => {
        dispatch(setLoading(true))
        await authAPI.rollBack().then(() => {
            localStorage.removeItem("isCheckinch")
            let user = localStorage.getItem('user')
            if (user) {
                dispatch(login(JSON.parse(user)))

            }
            navigate('/dashboard')
        }).finally(() => {
            dispatch(setLoading(false))
        })




    }

    return (
        <header className={styles.header}>
            <div className={styles.header_logo}>
                <img className={styles.logo} src={logo} alt="logo"/>
            </div>
            {(userRole === 3 && isCheckinch) &&
                <div className={styles.viewMode}>View mode Airline company manager(as Checkinch)</div>}
            {(userRole === 2 && isCheckinch) &&
                <div className={styles.viewMode}>View mode Handling manager(as Checkinch)</div>}
            <div className={styles.header_nav}>
                {userRole === 4 && <>Login as: <CheckinchDropdown/></>}
                {(userRole === 3 && isCheckinch) && <>Login as: <AirManagerDropdown/>
                    <div style={{padding: "15px"}}><Button onClick={() => handleClick()} variant="contained">Back to
                        Checkinch</Button></div>
                </>}
                {(userRole === 2 && isCheckinch) &&
                    <div style={{padding: "15px"}}><Button onClick={() => handleClick()} variant="contained">Back to
                        Checkinch</Button></div>}
            </div>
        </header>
    );
};

export default Header;
