import ShiftCreateGrid from "../../../components/ShiftManagerCreateGrid/ShiftCreateGrid";

import styles from "./HandlingActions.module.scss";

const ShiftCreate = () => {

    return (
        <div className={styles.data_grid}>
            <ShiftCreateGrid/>
        </div>
    );
}

export default ShiftCreate;
