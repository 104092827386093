import * as React from 'react';
import { useCallback, useState } from 'react';

import { Autocomplete, Box, Button, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enAU } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { toast } from 'react-toastify';
import { Controller, useForm } from "react-hook-form";
import { addDays, endOfMonth, endOfYear, lastDayOfWeek, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";

import { dataGridService } from "../../../services/dataGridService";
import { airlineManagerService, checkinchService } from "../../../api/apiManager";
import { schemaReports } from "../../schemaValidation";

import styles from './ReportsFilter.module.scss';

const ReportsFilterHandling = ({getReportsList, setInfo}: any) => {

    const [countries, setCountries] = useState<any>([]);
    const [valueCountry, setValueCountry] = useState<any | null>(null);
    const {control, handleSubmit, setValue, getValues, formState: {errors}} = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaReports),
    });
    const [data, setData] = useState<any>()
    const [toDate, setToDate] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [alignment, setAlignment] = useState<any>();

    const [error, setError] = useState<any>({
        fromDate: false,
        toDate: false
    });

    const downloadService = (response: any, name: string) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report-${name}-${new Date().toISOString().split('T')[0]}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    const clearFilter = () => {
        setValue('airport', "")
        setValue('fromDate', null)
        setValue('toDate', null)
        setValue('country', "")
        setValue('companyName', "")
        setValue('handlingCompanyName', "")
        setAlignment('')
        setFromDate(null)
        setToDate(null)
        setData(null)
    }

    const handleCountries = async (e: any) => {
        let inputText = e.target.value
        await checkinchService.getCountriesCities(inputText, "country").then((res) => {
            if (res?.data) {
                setCountries(res?.data)
            }
        })
    }

    const debounceHandleCountries = useCallback(debounce(handleCountries, 300), []);

    const onSubmit = async (data: any, event: any) => {
        event.preventDefault();      
        try {
            const res = await airlineManagerService.getReportsList(data);
            setData(res.data)
            let addIdToRow = res?.data.map((elem: any, index: number) => {
                return {...elem, ['id']: index}
            })
            setInfo(addIdToRow)
            
        } catch (error) {
            if (error instanceof Error) {
                console.error(error)
                setInfo([])
            }
        }
        dataGridService.removeEmptyFields(data)
    }

    const buttonStyle = {
        fontSize: "12px"
    }

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleClick = (today: string) => {
        let date = new Date()
        setError({...error, fromDate: false, toDate: false})
        switch (today) {
            case "today":
                setValue('fromDate', date.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(date, 1).toLocaleDateString('fr-CA'))
                setFromDate(date.toLocaleDateString('fr-CA'))
                setToDate(addDays(date, 1).toLocaleDateString('fr-CA'))
                break;
            case "week":
                const weekMonday = startOfWeek(date, {locale: enAU})
                const weekSunday = lastDayOfWeek(date, {locale: enAU})
                setValue('fromDate', weekMonday.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(weekSunday, 1).toLocaleDateString('fr-CA'))
                setFromDate(weekMonday.toLocaleDateString('fr-CA'))
                setToDate(addDays(weekSunday, 1).toLocaleDateString('fr-CA'))
                break;
            case "month":
                const monthStart = startOfMonth(date)
                const monthEnd = endOfMonth(date)
                setValue('fromDate', monthStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(monthEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(monthStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(monthEnd, 1).toLocaleDateString('fr-CA'))
                break;
            case "year":
                const yearStart = startOfYear(date)
                const yearEnd = endOfYear(date)
                setValue('fromDate', yearStart.toLocaleDateString('fr-CA'))
                setValue('toDate', addDays(yearEnd, 1).toLocaleDateString('fr-CA'))
                setFromDate(yearStart.toLocaleDateString('fr-CA'))
                setToDate(addDays(yearEnd, 1).toLocaleDateString('fr-CA'))
                break;
        }
    };

    const onClickExportHandler = async (data: any) => {
        data = getValues();
        
        if (!data?.fromDate && !data?.toDate) {
            let date = new Date()
            const monthStart = startOfMonth(date)
            const monthEnd = endOfMonth(date)
            data = {
                ...data,
                fromDate: monthStart.toLocaleDateString('fr-CA'),
                toDate: addDays(monthEnd, 1).toLocaleDateString('fr-CA')
            }
        }
        try {
            const res = await airlineManagerService.getPeriodicReports(data);
            downloadService(res, "Periodic")
        } catch (error) {
            if (error instanceof Error) {
                console.error(error)
            }
        }
    }

    return (
        <div className={styles.filters}>
            <div className={styles.filter}>
                <form className={styles.form_box} onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& .MuiTextField-root': {m: 1, width: "200px"},
                        }}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            className={styles.buttonGroup}
                            onChange={handleAlignment}
                            size="small"
                        >
                            <ToggleButton style={buttonStyle} onClick={() => handleClick("today")}
                                          value="Today">Today</ToggleButton>
                            <ToggleButton style={buttonStyle} onClick={() => {
                                handleClick("week")
                            }} value="This Week">This Week</ToggleButton>
                            <ToggleButton style={buttonStyle} onClick={() => {
                                handleClick("month")
                            }} value="This month">This month</ToggleButton>
                            <ToggleButton style={buttonStyle}
                                          onClick={() => {
                                              handleClick("year")
                                          }} value="This Year">This Year</ToggleButton>
                        </ToggleButtonGroup>
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"From Date *"}
                                        inputFormat="yyyy-MM-dd"
                                        value={fromDate}
                                        onError={(err) => {

                                            if (err) {
                                                setError({...error, fromDate: true})
                                                console.log(error)
                                            } else {
                                                setError({...error, fromDate: false})
                                                console.log(error)
                                            }
                                        }}
                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                console.log(error)
                                                setFromDate("")
                                                field.onChange("")
                                            } else {
                                                console.log(error)
                                                setFromDate(newValue?.toLocaleDateString('fr-CA'))
                                                field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                            }
                                        }}
                                        renderInput={(params) => <div className={styles.wrap_witherror}>
                                            <TextField id="outlined-error" size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params}
                                                       error={error.fromDate || errors?.fromDate?.message}/>

                                        </div>}
                                    />
                                </LocalizationProvider>
                            }
                            name="fromDate"
                            control={control}
                            defaultValue={''}
                        />
                        <Controller
                            render={({field: {ref, ...field}}) =>
                                <LocalizationProvider locale={enAU} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        mask="____-__-__"
                                        label={"To Date *"}
                                        inputFormat="yyyy-MM-dd"
                                        value={toDate}
                                        onError={(err) => {
                                            if (err) {
                                                setError({...error, toDate: true})
                                                console.log(error)
                                            } else {
                                                console.log(error)
                                                setError({...error, toDate: false})
                                            }
                                        }}
                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                setToDate("")
                                                field.onChange("")
                                            } else {
                                                setToDate(newValue?.toLocaleDateString('fr-CA'))
                                                field.onChange(newValue?.toLocaleDateString('fr-CA'))
                                            }

                                        }}
                                        renderInput={(params) => <div className={styles.wrap_witherror}>
                                            <TextField size="small" inputProps={{
                                                ...params.inputProps,
                                            }} inputRef={ref} {...params}
                                                       error={error.toDate || errors?.toDate?.message}/>

                                        </div>}
                                    />
                                </LocalizationProvider>
                            }
                            name="toDate"
                            control={control}
                            defaultValue={""}
                        />
                        <div className={styles.displayNone}>
                            <Controller
                                render={({field: {ref, ...field}}) => <Autocomplete
                                    {...field}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    options={countries}
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        setValueCountry(newValue);
                                        field.onChange(newValue)
                                    }}
                                    getOptionLabel={(option: any) => option}
                                    renderOption={(props, option: any) => (
                                        <Box component="li" {...props}>
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                name="country"
                                                {...params}
                                                size="small"
                                                label="Country"
                                                onChange={debounceHandleCountries}
                                                inputRef={ref}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        </>
                                    )}
                                />}
                                name="country"
                                control={control}
                                defaultValue=""
                            />
                        </div>
                        <Controller
                            render={({field}) =>
                                <TextField {...field} size="small"
                                           id="outlined-basic" label="Airport"
                                           variant="outlined"/>
                            }
                            name="airport"
                            control={control}
                            defaultValue=""
                        />
                        <Controller
                            render={({field}) =>
                                <TextField {...field} size="small"
                                           id="outlined-basic" label="Flight number"
                                           variant="outlined"/>
                            }
                            name="flightNumber"
                            control={control}
                            defaultValue=""
                        />
                        <div className={styles.checkBox}>
                            <Controller
                                control={control}
                                name="withCategories"
                                render={({field: {onChange, value, ref}}) => (
                                    <Checkbox
                                        onChange={onChange}
                                        checked={value}
                                        inputRef={ref}
                                    />
                                )}
                                defaultValue={true}
                            />
                            <span>With categories</span>
                        </div>
                    </Box>
                </form>
                <div className={styles.buttonFormGroup}>
                    <Button style={{marginRight: "10px"}}
                            disabled={error.fromDate || error.toDate}
                            onClick={clearFilter} variant="contained">
                        Clear filters
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} disabled={error.fromDate || error.toDate}
                            variant="contained">
                        Apply filters
                    </Button>
                </div>
                <div className={styles.reportExport}>
                    <Button style={{margin: "0px 5px 0px 10px"}} onClick={() => onClickExportHandler(data)}
                            disabled={error.fromDate || error.toDate}
                            variant="contained">
                        Download an Excel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReportsFilterHandling;
