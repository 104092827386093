import { useDispatch } from "react-redux";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { baggageSettingsService } from "../../../api/apiManager";

import { setUpdateFlag } from "../../../redux/features/isUpdateFlag";

import styles from "../BaggageSettingsCard.module.scss";
import BaggageSettingModal from "../BaggageSettingModal/modal/BaggageSettingModal";


const BaggageSetting = ({setting, value, companyIdFromProps}: any) => {

    const dispatch = useDispatch()

    const deleteProduct = async (id: number) => {
        try {
            await baggageSettingsService.deleteBaggage(id)
        } finally {
            dispatch(setUpdateFlag())
        }
    }

    return (<>
            <ListItem
                key={value}
                disablePadding
            >
                <ListItemButton role={undefined} dense>
                    <div className={styles.product}>
                        <div>
                            <ListItemIcon>
                                <Checkbox checked={setting?.isActive}
                                          inputProps={{'aria-label': 'controlled'}}

                                />
                            </ListItemIcon>
                        </div>

                        <div>
                            <p style={{overflowWrap: "anywhere", maxWidth: "520px"}}>{setting?.baggageName}</p>
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <p style={{overflowWrap: "anywhere"}}>{setting?.maxBaggageWeight +  " Kg."}</p>
                        </div>
                    </div>
                    <BaggageSettingModal companyIdFromProps={companyIdFromProps}
                                  service={setting}
                    />
                    <IconButton onClick={(e) => {
                        deleteProduct(setting.id)
                        dispatch(setUpdateFlag())
                    }} edge="end" aria-label="comments">
                        <DeleteIcon/>
                    </IconButton>

                </ListItemButton>
            </ListItem>

        </>

    );
};

export default BaggageSetting;
