import React, { useEffect, useState } from "react";
import {
  endOfMonth,
  endOfYear,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
  addDays,
} from "date-fns";
import Loader from "../../../components/Loader/Loader";
import styles from "./CheckinchReports.module.scss";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import { Pagination, PaginationItem, Stack } from "@mui/material";
import ReportsFilter from "../../../common/Filters/ReportsFilter/ReportsFilter";
import { checkinchService } from "../../../api/apiManager";
import {
  dateParseToDate,
  dateParseToTime,
} from "../../../common/dateFormatter";

const columns: GridColDef[] = [
  {
    field: "departureIata",
    headerName: "Airport",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 150,
  },
  {
    field: "companyName",
    headerName: "Airline company name",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },
  {
    field: "handlingCompanyName",
    headerName: "Handling company name",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },
  {
    field: "flightNumber",
    headerName: "Flight number",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: true,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "airlineCompanyType",
    headerName: "Company type",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },
  {
    field: "departureCountry",
    headerName: "Origin country",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    minWidth: 150,
  },
  {
    field: "departureDateTime",
    headerName: "Departure date & time",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 250,
    valueFormatter: (params) => {
      return (
        dateParseToDate(params.value) + " / " + dateParseToTime(params.value)
      );
    },
  },
  {
    field: "arrivalCountry",
    headerName: "Destination country",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },
  {
    field: "arrivalDateTime",
    headerName: "Arrival date & time",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 250,
    valueFormatter: (params) => {
      return (
        dateParseToDate(params.value) + " / " + dateParseToTime(params.value)
      );
    },
  },
  {
    field: "checkInTimeSlot",
    headerName: "Check In time slot",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "weightMeasurementMethod",
    headerName: "Weight measurement method",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },
  {
    field: "overWeightCalcMethod",
    headerName: "Overweight calc method",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 150,
  },

  {
    field: "maxCarryOnBagWeight",
    headerName: "Max carry on bag weight(kg.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => <>{params.value ? params.value : "N/A"}</>,
  },
  {
    field: "maxCarryOnWeight",
    headerName: "Max carry on weight(kg.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => <>{params.value ? params.value : "N/A"}</>,
  },
  {
    field: "maxHandbagWeight",
    headerName: "Allowed hand luggage weight(kg.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "maxBaggageWeight",
    headerName: "Allowed baggage weight(kg.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "maxLength",
    headerName: "Allowed height(cm.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "maxWidth",
    headerName: "Allowed width(cm.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "maxDepth",
    headerName: "Allowed depth(cm.)",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "measurementsCount",
    headerName: "Total number of measurements",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "approvedMeasurementsCount",
    headerName: "Approved measurements",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "notApprovedMeasurementsCount",
    headerName: "Not approved measurements",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "createdAt",
    headerName: "Date and time",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    minWidth: 250,
    valueFormatter: (params) => {
      return (
        dateParseToDate(params.value) + " / " + dateParseToTime(params.value)
      );
    },
  },
  {
    field: "setId",
    headerName: "Set ID",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "measurementId",
    headerName: "Measurement ID",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "firstname",
    headerName: "Operator first name",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "lastname",
    headerName: "Operator last name",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "workerId",
    headerName: "Operator worker ID",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "result",
    headerName: "Result",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "setResult",
    headerName: "Set Result",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "approximateWeight",
    headerName: "Actual detected weight",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "realWeight",
    headerName: "Actual inserted weight",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "length",
    headerName: "Actual height",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "width",
    headerName: "Actual width",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "depth",
    headerName: "Actual depth",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    valueFormatter: (params) => {
      if (!Number.isInteger(params.value)) {
        return params.value?.toFixed(2);
      } else {
        return params.value;
      }
    },
  },
  {
    field: "bagWeightImageUrl",
    headerName: "Link to weight image",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => (
      <>
        {params.value ? (
          <a
            className={styles.link}
            onClick={() => {
              let image = new Image();
              image.src = params?.value;
              let w = window.open("");
              w?.document.write(image.outerHTML);
            }}
          >
            Click to view
          </a>
        ) : (
          "N/A"
        )}
      </>
    ),
  },
  {
    field: "bagFrontImageUrl",
    headerName: "Link to front image",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => (
      <>
        {params.value ? (
          <a
            className={styles.link}
            onClick={() => {
              let image = new Image();
              image.src = params?.value;
              let w = window.open("");
              w?.document.write(image.outerHTML);
            }}
          >
            Click to view
          </a>
        ) : (
          "N/A"
        )}
      </>
    ),
  },
  {
    field: "bagSideImageUrl",
    headerName: "Link to side image",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => (
      <>
        {params.value ? (
          <a
            className={styles.link}
            onClick={() => {
              let image = new Image();
              image.src = params?.value;
              let w = window.open("");
              w?.document.write(image.outerHTML);
            }}
          >
            Click to view
          </a>
        ) : (
          "N/A"
        )}
      </>
    ),
  },
  {
    field: "overWeightAmount",
    headerName: "Overweight amount",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "overSizeAmount",
    headerName: "Oversize amount",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "overWeightQuantity",
    headerName: "Overweight quantity",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "overSizeQuantity",
    headerName: "Oversize quantity",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "paymentMethod",
    headerName: "Payment method",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },

  {
    field: "skipPaymentReason",
    headerName: "Skip payment reason",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "paymentStatus",
    headerName: "Payment status",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "paymentError",
    headerName: "Payment error",
    headerClassName: styles.header_row,
    headerAlign: "center",
    align: "center",
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      boundaryCount={2}
      siblingCount={0}
      page={page + 1}
      count={pageCount}
      renderItem={(props2: any) => <PaginationItem {...props2} />}
      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
      showFirstButton
      showLastButton
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

const ReportsPage = () => {
  const [info, setInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getReportsList = async (params: any) => {
    setLoading(true);
    await checkinchService
      .getReportsList(params)
      .then((res) => {
        let addIdToRow = res?.data?.map((elem: any, index: number) => {
          return { ...elem, ["id"]: index };
        });
        setInfo(addIdToRow);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const date = new Date();
  const fromDate = startOfMonth(date).toLocaleDateString("fr-CA");
  const toDate = addDays(endOfMonth(date), 1).toLocaleDateString("fr-CA");

  useEffect(() => {
    getReportsList({ fromDate, toDate });
  }, []);

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: "isActive",
        operatorValue: "contains",
        value: "",
      },
    ],
  });

  return (
    <div>
      <ReportsFilter getReportsList={getReportsList} />
      <div className={styles.data_grid}>
        <div className={styles.block_with_import}>
          <h3>Data</h3>
        </div>
        <div style={{ height: "700px", width: "100%" }}>
          <DataGrid
            rows={info || []}
            columns={columns}
            pageSize={10}
            initialState={{
              sorting: {
                sortModel: [{ field: "created_at", sort: "desc" }],
              },
            }}
            onFilterModelChange={(newFilterModel) => {
              if (newFilterModel.items?.length) {
                if (newFilterModel.items[0].columnField === "isActive") {
                  newFilterModel.items[0].operatorValue = "startsWith";
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                } else {
                  newFilterModel.items[0].operatorValue = "contains";
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }
              }
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Measurements not found
                </Stack>
              ),
            }}
            disableVirtualization
            disableDensitySelector
            disableSelectionOnClick
            loading={loading}
            getRowClassName={() => styles.grid_row}
            onRowClick={(e) => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
