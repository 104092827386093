import { IInfoCard } from '../../models/info-card.model';
import styles from './InfoCard.module.scss'

const InfoCard = ({
                      title,
                      metric1,
                      metric1desc,
                      metric2,
                      metric2desc,
                      metric3,
                      metric3desc,
                      metric4,
                      metric4desc,

                  }: IInfoCard) => {

    return (
        <div className={styles.info_card}>
            <h3>{title}</h3>
            <div className={`${styles.metrics} ${!metric2  ? styles.single : ""}`}>
                <div className={styles.metric}>
                    <h1>{metric1}</h1>
                    <p>{metric1desc}</p>
                </div>
                <div className={styles.metric}>
                    <h1>{metric2}</h1>
                    <p>{metric2desc}</p>
                </div>
                <div className={styles.metric}>
                    <h1>{metric3}</h1>
                    <p>{metric3desc}</p>
                </div>
                <div className={styles.metric}>
                    <h1>{metric4}</h1>
                    <p>{metric4desc}</p>
                </div>


            </div>
        </div>
    );
}

export default InfoCard;
