import styles from "./Loader.module.scss";

const Loader = ({fixedLoader}: any) => {

    return (
        <div className={fixedLoader ? styles.fixedLoader : styles.loader}></div>
    );
}

export default Loader;
