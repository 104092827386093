import { useNavigate } from "react-router-dom";

import {
    DataGrid,
    GridFilterModel,
    gridPageCountSelector,
    gridPageSelector, GridSortModel,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import { Pagination, PaginationItem } from '@mui/material';

import { IDataGrid } from "../../models/data-grid.model";
import styles from './Grid.module.scss'
import React, {useEffect} from "react";
import _ from 'lodash';
import {array} from "yup";

function CustomPagination() {

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            boundaryCount={2}
            siblingCount={0}
            page={page + 1}
            count={pageCount}
            renderItem={(props2: any) => <PaginationItem {...props2} />}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                apiRef.current.setPage(value - 1)}
            showFirstButton
            showLastButton
        />
    );
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton/>
        </GridToolbarContainer>
    );
}


const Grid = ({columns, rows, loading, singleview}: IDataGrid) => {

    let navigate = useNavigate();

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            {
                id: 1,
                columnField: 'isActive',
                operatorValue: 'contains',
                value: '',

            },
        ],
    });

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'departureDateTime',
            sort: 'asc',
        },
    ]);

    const sortModelOptions = (columns: Array<object>) => {
        if(columns.find((field: any) => field.field === "departureDateTime")) {
            setSortModel([{
                field: 'departureDateTime',
                sort: 'asc',
            }])
        } else {
            setSortModel([{
                field: 'created_at',
                sort: 'desc',
            }])
        }
    }


    useEffect(() => {
        console.log(1)
        sortModelOptions(columns)
    }, []);





    return (

        <div style={{height: '700px', width: '100%'}}>

            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                sortModel={sortModel}
                onSortModelChange={(model) => {

                }}

                onFilterModelChange={(newFilterModel) => {
                    if (newFilterModel.items.length) {
                        if (newFilterModel.items[0].columnField === "isActive") {
                            newFilterModel.items[0].operatorValue = "startsWith"
                            console.log(newFilterModel)
                            setFilterModel(newFilterModel)
                        } else {
                            newFilterModel.items[0].operatorValue = "contains"
                            console.log(newFilterModel)
                            setFilterModel(newFilterModel)
                        }
                    }

                }}
                components={{
                    Pagination: CustomPagination,
                    Toolbar: CustomToolbar
                }}
                disableColumnMenu
                disableVirtualization
                disableDensitySelector
                disableColumnSelector
                disableSelectionOnClick
                loading={loading}
                getRowClassName={() => styles.grid_row}
                onRowClick={(e) => {
                    navigate(`${singleview}/${e.id}`);
                }}
            />


        </div>


    );
}

export default Grid;
