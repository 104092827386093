import FlightsInfoGrid from "../../components/FlightsInfoGrid/FlightsInfoGrid";

import styles from "./Flights.module.scss";

const FlightSingleView = () => {

    return (
        <div className={styles.single_view}>
            <h3>Data</h3>
            <div className={styles.info_single}>
                <FlightsInfoGrid/>
            </div>
        </div>
    );
}

export default FlightSingleView;
