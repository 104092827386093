import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

import Loader from '../Loader/Loader';
import { airlineManagerService } from '../../api/apiManager';
import { setFlight } from '../../redux/features/airlineManagerSlice';
import { setDelete, setView } from '../../redux/features/isDeleteSlice';
import PromptDelete from '../PromptDelete/PromptDelete';

import { dateParseToDate, dateParseToTime } from "../../common/dateFormatter";
import styles from './FlightsInfoGrid.module.scss'

const FlightsInfoGrid = () => {
    const isDelete = useSelector((store: any) => store?.isDelete.isDelete)
    const isView = useSelector((store: any) => store?.isDelete.isView)
    const dispatch = useDispatch()
    const pathname = useLocation().pathname;
    const id = pathname.split("/").pop();

    const navigate = useNavigate()

    const [info, setInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getFlight = async (id: any) => {
        setLoading(true)
        await airlineManagerService.getFlightById(id).then((res) => {
            setInfo(res?.data);
            dispatch(setFlight(res?.data))
        }).catch((err) => {
            console.log(err.response)
            if (err.response.status === 404) {
                //navigate('/404')
            }
        }).finally(() => {
            setLoading(false)
        })

    }

    const openPrompt = () => {
        dispatch(setView(true))
    }

    useEffect(() => {
        getFlight(id)
    }, [])

    const deleteFlight = async (id: any) => {
        if (isDelete) {
            let response = await airlineManagerService.deleteFlight(id)
            if (response?.status === 200) {
                toast.success("Flight was deleted successfully");
                navigate(-1)
                dispatch(setDelete(false))
            } else {
                toast.error("Something went wrong");
            }
        } else {
            dispatch(setView(false))
        }
    }

    useEffect(() => {
        deleteFlight(id)
    }, [isDelete]);

    if (loading) return <Loader/>

    return (
        <>
            <div className={styles.app}>
                <Table>
                    <tbody>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">ID</TableCell>
                        <TableCell>{info?.id}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Flight unique ID</TableCell>
                        <TableCell>{info?.flightUniqueId}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Flight number</TableCell>
                        <TableCell>{info?.flightNumber}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Departure country</TableCell>
                        <TableCell>{info?.departureCountry}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Departure city</TableCell>
                        <TableCell>{info?.departureCity}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Departure Iata</TableCell>
                        <TableCell>{info?.departureIata}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Departure date and time</TableCell>
                        <TableCell>{dateParseToDate(info?.departureDateTime)} / {dateParseToTime(info?.departureDateTime)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Arrival country</TableCell>
                        <TableCell>{info?.arrivalCountry}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Arrival city</TableCell>
                        <TableCell>{info?.arrivalCity}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Arrival Iata</TableCell>
                        <TableCell>{info?.arrivalIata}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Arrival date and time</TableCell>
                        <TableCell>{dateParseToDate(info?.arrivalDateTime)} / {dateParseToTime(info?.arrivalDateTime)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Status</TableCell>
                        <TableCell>{info?.isActive ? "Active" : "Inactive"}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Creation origin</TableCell>
                        <TableCell>{info?.creationOrigin}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Created at</TableCell>
                        <TableCell>{dateParseToDate(info?.created_at)} / {dateParseToTime(info?.created_at)}</TableCell>
                    </TableRow>
                    <TableRow className={styles.row_style}>
                        <TableCell variant="head">Updated at</TableCell>
                        <TableCell>{dateParseToDate(info?.updated_at)} / {dateParseToTime(info?.updated_at)}</TableCell>
                    </TableRow>
                    </tbody>
                </Table>
                <div className={styles.button}>
                    <NavLink to={`/flight-edit?id=${id}`}>
                        <Button variant="contained">Edit</Button>
                    </NavLink>
                    <Button
                        className={styles.delete}
                        color="error"
                        variant="outlined"
                        onClick={() => openPrompt()}>
                        Delete
                    </Button>
                </div>
            </div>
            {isView ? <PromptDelete/> : ''}
        </>
    );
}

export default FlightsInfoGrid;
