import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GridColDef } from "@mui/x-data-grid";

import Grid from "../../../components/Grid/Grid";
import { airlineManagerService } from "../../../api/apiManager";
import { dateParseToDate, dateParseToTime } from "../../../common/dateFormatter";
import ImportFlight from "../../../components/ImportFligth/ImportFlight";
import FlightsFilterAirlineManager
    from "../../../common/Filters/FlightsFilterAirlineManager/FlightsFilterAirlineManager";

import styles from "./Company.module.scss";

const columns: GridColDef[] = [

    {
        field: 'id',
        headerName: 'ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,

    },
    {
        field: 'flightUniqueId',
        headerName: 'Flight unique ID',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,

    },
    {
        field: 'flightNumber',
        headerName: 'Flight number',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'departureIata',
        headerName: 'Origin',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'arrivalIata',
        headerName: 'Destination',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'departureDateTime',
        headerName: 'Departure date',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,

        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))
        },
    },

    {
        field: 'arrivalDateTime',
        headerName: 'Arrival date',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'creationOrigin',
        headerName: 'Creation origin',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
    },
    {
        field: 'created_at',
        headerName: 'Created at',
        flex: 1,
        headerClassName: styles.header_row,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: true,
        valueFormatter: (params) => {
            return (dateParseToDate(params.value) + " / " + dateParseToTime(params.value))
        },
    },

];

const CompanyFlightList = () => {

    const [info, setInfo] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [importFlightResponse, setImportFlightResponse] = useState<any>();
    let dataFromFilters = useSelector((state: any) => state.airlineManagerPage.filter)
    let flights = useSelector((state: any) => state.airlineManagerPage.flight)

    const getFlights = async () => {
        setLoading(true)
        await airlineManagerService.getFlightList(dataFromFilters).then((res) => {
            setInfo(res?.data);
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getFlights()
    }, [importFlightResponse]);

    useEffect(() => {
        setInfo(flights);
    }, [flights]);

    return (
        <div className={styles.data_grid}>
            <FlightsFilterAirlineManager/>
            <div className={styles.block_with_import}>
                <h3>Data</h3>
                <ImportFlight setImportFlightResponse={setImportFlightResponse}/>

            </div>
            <Grid
                columns={columns}
                rows={info}
                loading={loading}
                singleview="/flight-view"
            />
        </div>
    );
}

export default CompanyFlightList;
